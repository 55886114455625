import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import WavingHand1x from "./waving-hand-1x.png";
import WavingHand2x from "./waving-hand-2x.png";

export const WavingHandImage = ({
  width,
  height,
  highResolution,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <div style={{ height, width }}>
      <ImageTemplate
        {...props}
        imgSrc={highResolution ? WavingHand1x : WavingHand2x}
      />
    </div>
  );
};
