import { Button } from "@atoms";
import { Bar } from "@organisms";

export type DashboardLayoutTemplateProps = {
  children: JSX.Element;
  header: string;
  subheader: JSX.Element;
};

export const DashboardLayoutTemplate = ({
  children,
  header,
  subheader,
}: DashboardLayoutTemplateProps): JSX.Element => {
  return (
    <div
      // style={{ gridTemplateColumns: "240px 1fr" }}
      // className="grid w-screen h-screen min-h-screen bg-neutral-lightest pr-4"
      className="flex flex-row w-screen h-screen min-h-screen overflow-x-hidden bg-neutral-lightest pr-4"
    >
      <div className="h-screen min-h-screen w-60 fixed">
        <Bar variant="side" />
      </div>
      <div
        // style={{ gridTemplateRows: "96px 1fr" }}
        // className="flex w-full bg-white grid py-12 mt-4 border rounded-t-2xl"
        className="flex w-screen h-screen min-h-screen bg-white grid py-12 relative left-60 mr-60 border border-neutral-light rounded-t-2xl"
      >
        {/* ... to return the top margin, add mt-4 above, but is still messing up the bottom scroll... */}
        <div className="pl-6 h-full bg-white overflow-y-auto">
          <div>
            <h3 className="color-black text-3xl">{header}</h3>
          </div>
          <div>{subheader}</div>
          <div className="border-b border-neutral-light w-full mt-8"></div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};
