import { SignInPage } from "./root";
import { ForgotPasswordPage } from "./forgot-password";
import { ResetPasswordPage } from "./reset-password";
import { ForceResetPasswordPage } from "./force-reset-password";
import { PendingPage } from "./pending";

export type PageProps = {
  variant: "root" | "forgot-password" | "reset-password" | "force-reset-password" | "pending";
};

export const Page = (props: PageProps): JSX.Element => {
  switch (props.variant) {
    case "root":
      return <SignInPage />;
    case "forgot-password":
      return <ForgotPasswordPage />;
    case "reset-password":
      return <ResetPasswordPage />;
    case "force-reset-password":
      return <ForceResetPasswordPage />;      
    case "pending":
      return <PendingPage />
    default:
      throw new Error(`[SIGN IN ERROR] Invalid variant provided`);
  }
};
