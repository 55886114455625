import { FormEvent } from "react";
import { Button, Image, Input } from "@atoms";
import { Partner } from "@api";
import { Oval } from "react-loader-spinner";

export interface FormValues {
  description: string;
  organization: string;
  phoneNumber: string;
  website: string;
}

export interface FormErrorValues {
  description: string | undefined;
  organization: string | undefined;
  phoneNumber: string | undefined;
  website: string | undefined;
}

export type WelcomePageTemplateProps = {
  values: FormValues;
  errors: FormErrorValues;
  loading: boolean;
  handleBackButtonClick: () => Promise<void>;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  partner: Partner | null;
  pageIsLoading: boolean;
};

export const WelcomePageTemplate = ({
  values,
  errors,
  loading,
  handleBackButtonClick,
  handleInputChange,
  handleSubmit,
  partner,
  pageIsLoading
}: WelcomePageTemplateProps): JSX.Element => {

  const handleSendEmailClick = () => {
    window.open("mailto:affiliate@coinmiles.io", "_blank");
  };

  return (
    <div>
      {pageIsLoading ? <div className="min-h-screen flex">
      <div className="relative hidden lg:block min-h-screen w-6/10">
        <div className="absolute top-6 left-6">
          <Image variant="logoWhite" />
        </div>
        <div style={{ backgroundColor: "#ed2828" }} className="w-full h-full">
          <Image variant="backgroundPhone" width={827} height={775} />
        </div>
      </div>
      <div className="flex-col relative w-full min-h-screen lg:w-4/10 lg:flex justify-center items-center px-10">
        <div className="mb-7">
        <Oval color="#FF0000" height={90} width={90} secondaryColor="#000000"/>
        </div>
        <div className="mb-6">
          <h3 className="text-3xl font-black">Checking Your Account Details</h3>
        </div>
        <h3 className="text-lg text-gray-500 text-center w-3/4"> 
          Give us a minute to review the details of your account!
        </h3>
        {/* <div className="flex mt-2">
          <h3 className="text-lg text-gray-500 mr-2">Questions?</h3>
          <Button
            variant="link"
            label="Send us an email!"
            fontWeight="normal"
            color="red"
            brightness={500}
            fontSize="lg"
            onClick={handleSendEmailClick}
            underline
          />
        </div> */}
        {/* <div className="mt-16">
          <Button
            variant="primary"
            label="Back to home"
            color="#ff3333"
            brightness={500}
            onClick={handleBackButtonClick}
            size="sm"
          />
        </div> */}
      </div>
    </div> : (null)}
      
      
      {partner?.organization ? <div className="min-h-screen flex">
      <div className="relative hidden lg:block min-h-screen w-6/10">
        <div className="absolute top-6 left-6">
          <Image variant="logoWhite" />
        </div>
        <div style={{ backgroundColor: "#ed2828" }} className="w-full h-full">
          <Image variant="backgroundPhone" width={827} height={775} />
        </div>
      </div>
      <div className="flex-col relative w-full min-h-screen lg:w-4/10 lg:flex justify-center items-center px-10">
        <div className="mb-7">
          <Image variant="horns" />
        </div>
        <div className="mb-6">
          <h3 className="text-3xl font-black">Request Pending</h3>
        </div>
        <h3 className="text-lg text-gray-500 text-center w-3/4">
          Your request to join Coinmiles Partners was sent to our Partnership
          Team. We will notify you by email once it has been approved.
        </h3>
        <div className="flex mt-2">
          <h3 className="text-lg text-gray-500 mr-2">Questions?</h3>
          <Button
            variant="link"
            label="Send us an email!"
            fontWeight="normal"
            // color={"attention-medium"}
            color="red"
            brightness={400}
            fontSize="lg"
            onClick={handleSendEmailClick}
            underline
          />
        </div>
        <div className="mt-16">
          <Button
            variant="primary"
            label="Back to home"
            color="red"
            brightness={400}
            onClick={handleBackButtonClick}
            size="sm"
          />
        </div>
      </div>
    </div> : (
        <div className="min-h-screen flex">
          <div className="relative hidden lg:block min-h-screen w-6/10">
            <div className="absolute top-6 left-6">
              <Image variant="logoWhite" />
            </div>
            <div
              style={{ backgroundColor: "#ed2828" }}
              className="w-full h-full"
            >
              <Image variant="backgroundPhone" width={827} height={775} />
            </div>
          </div>
          <div className="relative w-full min-h-screen lg:w-4/10 lg:flex justify-center items-center">
            <div className="lg:hidden px-6 pt-6 pb-12 sm:pb-24">
              <Image variant="logoColour" />
            </div>
            <form
              className="text-center flex flex-col items-center max-w-xl mx-auto px-4"
              onSubmit={handleSubmit}
            >
              <div className="mb-6">
                <h3 className="text-xl sm:text-3xl font-black text-center px-8">
                  Nice to meet you, let's introduce your business.
                </h3>
              </div>
              <div className="w-full px-6">
                <div className="mb-2">
                  <Input
                    variant="text"
                    name="organization"
                    onChange={handleInputChange}
                    placeholder="Company name"
                    value={values.organization}
                    errorMessage={errors.organization}
                  />
                </div>
                <div className="mb-2">
                  <Input
                    variant="text"
                    name="website"
                    onChange={handleInputChange}
                    placeholder="Your website"
                    value={values.website}
                    errorMessage={errors.website}
                  />
                </div>
                <div className="mb-2">
                  <Input
                    variant="text"
                    name="phoneNumber"
                    onChange={handleInputChange}
                    placeholder="Phone number"
                    value={values.phoneNumber}
                    errorMessage={errors.phoneNumber}
                  />
                </div>

                <div className="mb-2">
                  <Input
                    variant="textArea"
                    name="description"
                    onChange={handleInputChange}
                    placeholder="Describe your business"
                    value={values.description}
                    errorMessage={errors.description}
                    height={128}
                  />
                </div>
                <div className="flex flex-col justify-center items-center">
                  <div className="mt-8 w-2/3">
                    <Button
                      variant="primary"
                      label="Continue"
                      color="secondary-medium"
                      hoverColor="secondary-dark"
                      textColor="white"
                      stretch
                      onClick={handleSubmit}
                      size="sm"
                      loading={loading}
                    />
                  </div>
                  <div className="mt-4 mb-8 w-2/3">
                    <Button
                      variant="secondary"
                      label="Back to Sign In"
                      color="#ffffff"
                      stretch
                      brightness={500}
                      onClick={handleBackButtonClick}
                      size="sm"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
