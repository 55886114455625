export type SidebarItemTemplateProps = {
  label: string;
  icon: JSX.Element;
  onClick: () => void;
  selected: boolean;
  isSectionIncomplete: boolean;
};

export const SidebarItemTemplate = ({
  label,
  icon,
  onClick,
  selected,
  isSectionIncomplete
}: SidebarItemTemplateProps): JSX.Element => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${selected ? "bg-neutral-light" : "bg-transparent"} 
        w-full block px-4 py-3 rounded-md transition ease-in-out delay-0 hover:bg-neutral-light duration-150`}
    >
      <div className="flex justify-start">
        <div>{icon}</div>
        <div className="flex flex-row w-full ml-3 justify-between items-center">
          <div
            className={`text-taupe-600 text-base ${
              selected ? "font-bold" : "font-normal"
            }`}
          >
            {label}
          </div>
          {isSectionIncomplete && 
            <div className="ml-2 rounded-full w-2 h-2 bg-attention-dark"></div>
          }
        </div>
      </div>
    </button>
  );
};
