import { Layout } from "@layouts";
import { Card } from "@molecules";
import { Image, ImageVariantType } from "@atoms";
import { Partner } from "@api";
import { DashboardWidgets, PagePaths } from "../../../types";
import { Users as UsersIcon, DollarSign as DollarSignIcon, Crosshair as CrosshairIcon, UserCheck as UserCheckIcon, BarChart3 as BarChartIcon } from "lucide-react";
import { BlogSection } from "@organisms";

export type HomePageTemplateProps = {
  todos: {
    variant: ImageVariantType;
    title: string;
    description: string;
    navigationString: string;
  }[];
  blogData: any;
  partner: Partner | null;
  navigateToPage: (index: number, to: string) => void;
  isSetupComplete: boolean | undefined;
  isLoading: boolean;
  getWidgetTitle: (widgetType: DashboardWidgets) => string;
  getWidgetValue: (widgetType: DashboardWidgets) => string;
  getPopularBrands: () => any;
};

export const HomePageTemplate = ({
  todos,
  blogData,
  partner,
  navigateToPage,
  isSetupComplete,
  isLoading,
  getWidgetTitle,
  getWidgetValue,
  getPopularBrands,
}: HomePageTemplateProps): JSX.Element => {

  const rowstyle = ""; // "w-1/4";

  return (
    <Layout
      variant="dashboard"
      header={`Welcome ${partner?.firstName}!`}
      subheader={
        <div className="text-neutral-dark text-base font-normal mt-2">
          Here's everything you need to manage your Coinmiles Partners account
        </div>
      }
    >
      {isSetupComplete === undefined ?
        <div></div>
      : isSetupComplete === false ?
      <div>
        <div className=" mt-8 mb-4">
          <h2 className="color-black text-xl">What's next: </h2>
        </div>
        <div className="flex flex-wrap my-5">
          {todos.map(({ variant, title, description, navigationString }, index) => (
            <div key={index} className="mr-5 mb-5 max-w-min">
              <Card
                onClick={() => navigateToPage(index + 1, "/dashboard/" + navigationString)}
                variant="onboard"
                icon={<Image variant={variant} width={204} height={152} />}
                title={title}
                description={description}
                index={index}
                isSectionIncomplete={true}
              />
            </div>
          ))}
        </div>
      </div>
      :
        <div>
            <div className="flex flex-row items-center h-4 mt-6 ml-0">
              <BarChartIcon size={20} />
              <div className="text-neutral-darkest text-xl font-normal ml-2">
                Key metrics
              </div>
            </div>
          <div className="flex flex-row flex-wrap w-full mt-4 pr-4 justify-start max-w-full w-full">
            {/* <div>
              <Card
                variant="dashboard"
                icon={<DollarSignIcon size={20} />}
                title={getWidgetTitle(DashboardWidgets.totalRewards)}
                description={getWidgetValue(DashboardWidgets.totalRewards)}
              />
            </div> */}
            <div>
              <Card
                variant="dashboard"
                icon={<UsersIcon size={20} />}
                title={getWidgetTitle(DashboardWidgets.teamMembers)}
                description={getWidgetValue(DashboardWidgets.teamMembers)}
              />
            </div>
            <div>
              <Card
                variant="dashboard"
                icon={<UserCheckIcon size={20} />}
                title={getWidgetTitle(DashboardWidgets.activeMembers)}
                description={getWidgetValue(DashboardWidgets.activeMembers)}
              />
            </div>
            <div>
              <Card
                variant="dashboard"
                icon={<CrosshairIcon size={20}/>}
                title={getWidgetTitle(DashboardWidgets.perksPartners)}
                description={getWidgetValue(DashboardWidgets.perksPartners)}
              />
            </div>
          </div>
          <div className="pb-12">
            <BlogSection page={PagePaths.home} />
          </div>
        </div>
      }
    </Layout>
  );
};
