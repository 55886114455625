import { useCallback, useContext, useEffect, useState } from "react";
import { Auth } from "@aws-amplify/auth";
import { Partner } from "@api";
import { SocialProvider } from "@auth";
import { useGetPartner, useGetClientSecret, useGetPartnerPaymentMethods, useGetPartnerInvoices, useGetBillingDetails } from "@queries";
import { loadStripe } from "@stripe/stripe-js";

import { BillingDetails, GiftCardOffer, PartnerStatsAll, PartnerAirdropTransaction } from "../types";
import { SignInInput, SignUpInput, ResetPasswordInput } from "@auth";
import { AuthContext, AuthState } from "./auth-context";
import { Env } from "@config";

interface SignUpUsername {
  user: { username: string };
}

const STRIPE_API_KEY: string = Env.STRIPE_API_KEY_CA || "";
if (STRIPE_API_KEY === "") console.log("ERROR: empty API key");
const stripePromise = loadStripe(STRIPE_API_KEY);

export function useAuth(): AuthState {
  const { getPartner, partner: partnerData } = useGetPartner();
  const { getPartnerPaymentMethods } = useGetPartnerPaymentMethods();
  const { getPartnerInvoices } = useGetPartnerInvoices();
  const { getBillingDetails } = useGetBillingDetails();
  const { getClientSecret, customerId, clientSecretData } = useGetClientSecret();
  // Note: we are not currently using the customerId on the frontend, and if required, the code may need to be updated to match how we handle/update the clientSecret

  const [selectedPageIndex, setSelectedPageIndex] = useState<number | undefined>(undefined);
  const [isPerksPageVisited, setIsPerksPageVisited] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string>("");
  const [partner, setPartner] = useState<Partner | null>(null);
  const [billingDetails, setBillingDetails] = useState<BillingDetails>
  ({
    invoices: null,
    creditCards: null,
    planAmount: null
  });
  const [cognitoUser, setCognitoUser] = useState<any>(null);
  const [newPartnerInfo, setNewPartnerInfo] = useState<Object>({});
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isSetupComplete, setIsSetupComplete] = useState<boolean | undefined>(undefined);
  const [giftCardOffers, setGiftCardOffers] = useState<Array<GiftCardOffer> | null>(null);
  const [partnerAirdropTransactions, setPartnerAirdropTransactions] = useState<Array<PartnerAirdropTransaction> | null>(null);
  const [partnerStatistics, setPartnerStatistics] = useState<PartnerStatsAll | undefined>({
    current: {
      date: "",
      numTeamMembers: 0,
      numActiveMembers: 0,
      totalRewardFiatValue: 0,
      numAirdrops: 0,
      popularBrands: []
    }
  });


  // save the client secret to the Context state var
  useEffect(() => {
    console.log("set clientSecretData");
    setClientSecret(clientSecretData);
  }, [clientSecretData]);

  useEffect(() => {
    let active = true;
    console.log("set partnerData");
    if (active && partnerData) {
      setPartner(partnerData);
    }

    return () => {
      active = false;
    };
  }, [partnerData]);

  useEffect(() => {
    let active = true;
    const checkIfSignedIn = async () => {
      console.log("loading everything...")
      try {
        if (active) {
          await Auth.currentAuthenticatedUser();
          if (partner === null) getPartner();
          getClientSecret();
          getPartnerPaymentMethods();
          getPartnerInvoices();
          getBillingDetails();
          console.log("... done loading everything");
        }
      } catch (error) {
        if (active) {
          setPartner(null);
        }
      }
    };
    checkIfSignedIn();

    return () => {
      active = false;
    };
  }, []);

  function updatePartnerData(newPartner: Partner) {
    console.log("updating partner data... ", newPartner);
    setPartner(newPartner);
  }

  const updateClientSecret = (newSecret: string): void => {
    setClientSecret(newSecret);
  };

  // TODO: why was this method a callback? (see the original below) Is it necessary?
  async function signIn  ({ emailAddress, password }: SignInInput): Promise<string>  {
    console.log("sign in and load ...");
    const cognitoUserResult = await Auth.signIn(emailAddress, password);

    if (cognitoUserResult.challengeName === "NEW_PASSWORD_REQUIRED") {
      setCognitoUser(cognitoUserResult);
      return "NEW_PASSWORD_REQUIRED";
    } else {
      if (partner === null) getPartner();
      getClientSecret();
      getPartnerPaymentMethods();
      console.log("... done sign in and load");
      return "";
    }
  }

  // const signIn = useCallback(
  //   async ({ emailAddress, password }: SignInInput) => {
  //     /* try { */

  //     console.log("sign in and load ...")
  //     const cognitoUser = await Auth.signIn(emailAddress, password);

  //     if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
  //       console.log("RESET required");
  //       return false;
  //     } else {
  //       // console.log(cognitoUser);
  //       if (partner === null) getPartner();
  //       getClientSecret();
  //       getPartnerPaymentMethods();
  //       console.log("... done sign in and load");
  //       /* } catch (e) {
  //         console.log("Error: " + e);
  //       } */
  //      return true;
  //     }
  //   }, [],
  // );

  const socialSignIn = useCallback(async (provider: SocialProvider) => {
    console.log(`Starting federated sign in with provider ${provider}...`);
    await Auth.federatedSignIn({ provider });
    console.log("Done social signin");
    const credentials = await Auth.currentAuthenticatedUser();
    // console.log(
    //   `Completed federated sign in with provider ${provider}, credentials: ${JSON.stringify(
    //     credentials,
    //   )}...`,
    // );
    // console.info("Starting fetching partner information...");
    // getPartner();
    // console.info("Completed fetching partner information...");
  }, []);

  const signOut = useCallback(async () => {
    await Auth.signOut();
    setPartner(null);
    location.replace("/");
  }, []);

  const syncPartner = useCallback(async (partner: Partner) => {
    setPartner(partner);
  }, []);

  // tests all of the setup conditions, and return true if all of them are true
  const calculateIsSetupComplete = () => {
    setIsSetupComplete(partner?.premiumSettings[0]?.users === undefined ? false : true);
  }

  return {
    partner,
    updatePartnerData,
    cognitoUser,
    setCognitoUser,
    signIn,
    signOut,
    socialSignIn,
    syncPartner,
    newPartnerInfo,
    setNewPartnerInfo,
    customerId,
    clientSecret,
    stripePromise,
    updateClientSecret,
    isLoadingData,
    setIsLoadingData,
    selectedPageIndex,
    setSelectedPageIndex,
    isPerksPageVisited,
    setIsPerksPageVisited,
    billingDetails,
    setBillingDetails,
    giftCardOffers,
    setGiftCardOffers,
    partnerStatistics,
    setPartnerStatistics,
    isSetupComplete,
    calculateIsSetupComplete,
    partnerAirdropTransactions,
    setPartnerAirdropTransactions
  };
}

export function useUser(): Partner | null {
  const { partner } = useContext(AuthContext);
  if (!partner) {
    return null;
  } else {
    return partner;
  }
}

export function useSignIn(): (input: SignInInput) => Promise<string> {
  return useContext(AuthContext).signIn;
}

export function useSignOut(): () => Promise<void> {
  return useContext(AuthContext).signOut;
}

export function useSocialSignIn(): (provider: SocialProvider) => Promise<void> {
  return useContext(AuthContext).socialSignIn;
}

// export function useSyncPartner(): (partner: Partner) => <Promise<void>> {
export function useSyncPartner(): (partner: any) => void {
  // return useContext(AuthContext).syncPartner;
  return useContext(AuthContext).updatePartnerData;
}

export function useSignUp() {
  return async function signUp({
    emailAddress,
    firstName,
    lastName,
    password,
    customDescription,
    customOrganization,
    customPhoneNumber,
    customWebsite,
  }: SignUpInput): Promise<string> {
    const signUpResult = await Auth.signUp({
      username: emailAddress.trim(),
      password: password.trim(),
      attributes: {
        family_name: lastName.trim(),
        given_name: firstName.trim(),
        phone_number: customPhoneNumber,
        "custom:description": customDescription,
        "custom:organization": customOrganization,
        "custom:website": customWebsite,
      },
    });
    const {
      user: { username },
    } = signUpResult as any as SignUpUsername;
    return username;
  };
}

export function useResendSignUp() {
  return async function resendSignUp(email: string): Promise<void> {
    await Auth.resendSignUp(email);
  };
}

export function useForgotPassword() {
  return async function forgotPassword(email: string): Promise<void> {
    await Auth.forgotPassword(email);
  };
}

export function useForceResetPassword() {
  return async function forceResetPassword(cognitoUser: any, newPassword: string): Promise<void> {
    await Auth.completeNewPassword(cognitoUser, newPassword);
  };
}

export function useResetPassword() {
  return async function resetPassword({
    email,
    code,
    password,
  }: ResetPasswordInput): Promise<void> {
    await Auth.forgotPasswordSubmit(email, code, password);
  };
}
