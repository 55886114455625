import { Button, Image } from "@atoms";

export type ConfirmPageTemplateProps = {
  handleBackButtonClick: () => void;
  handleResendEmailClick: () => void;
};

export const ConfirmPageTemplate = ({
  handleBackButtonClick,
  handleResendEmailClick,
}: ConfirmPageTemplateProps): JSX.Element => {
  return (
    <div className="min-h-screen flex">
      <div className="relative hidden lg:block min-h-screen w-6/10">
        <div className="absolute top-6 left-6">
          <Image variant="logoWhite" />
        </div>
        <div style={{ backgroundColor: "#ed2828" }} className="w-full h-full">
          <Image variant="backgroundPhone" width={827} height={775} />
        </div>
      </div>
      <div className="flex-col relative w-full min-h-screen lg:w-4/10 lg:flex justify-center items-center">
        <div className="mb-6">
          <h3 className="text-3xl font-black">Check your email</h3>
        </div>
        <h3 className="text-lg text-gray-500 text-center p-6 w-10/12">
          We have sent you an email to confirm your request to join Coinmiles
          Partners. Please click the link in the email to confirm your account.
        </h3>
        <div className="flex flex-row justify-center mt-2 w-10/12">
          <h3 className="text-lg text-gray-500 mr-2">
            Didn't receive an email?
          </h3>
          <Button
            variant="link"
            label="Resend Confirmation"
            fontWeight="normal"
            // color={"attention-medium"}
            color={"red"}
            brightness={400}
            fontSize="lg"
            onClick={handleResendEmailClick}
            underline
          />
        </div>
        <div className="mt-16">
          <Button
            variant="primary"
            label="Back to home"
            color="secondary-medium"
            hoverColor="secondary-dark"
            textColor="white"
            // brightness={500}
            onClick={handleBackButtonClick}
            size="sm"
          />
        </div>
      </div>
    </div>
  );
};
