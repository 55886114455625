import { Button, Icon, Image, TextChip, ChipTypes } from "@atoms";
import { SubscriptionPlansTypes } from "../../../../types";
const colours = require('../../../../theme/colors');


export type SubscriptionCardTemplateProps = {
  planType: SubscriptionPlansTypes;
  planAmount: number;
  subscriptionPlanData: any;
  onClick: () => void;
  onSendEmail: () => void;
};

export const SubscriptionCardTemplate = ({
  planType,
  planAmount,
  subscriptionPlanData,
  onClick,
  onSendEmail
}: SubscriptionCardTemplateProps): JSX.Element => {
  const { title, subtitle, points } = subscriptionPlanData;
  
  let planAmountText = "";
  if (planAmount !== undefined && planAmount !== null && planAmount !== 0) {
    planAmountText = `$${(planAmount / 100).toFixed(2)}`;
  }
 
  return (
    <div>
      <div className="flex flex-col h-full w-full border border-neutral-light rounded-lg p-5">
        <div className="w-20 self-end">
          <TextChip type={ChipTypes.planBasic} />
        </div>
        <div className="pb-3 pt-1">
          <div className="text-neutral-dark text-xl font-semibold">
            {`Your Plan`}
          </div>
          <div className="text-neutral-dark text-base font-normal">
            {`${title} Plan`}
          </div>
        </div>
        <div className="flex flex-row items-end h-8">
          {/* TODO: must improve how/when the plan amount gets updated, since currently the real price won't appear until a team member has been added */}
          <div className="font-medium text-3xl text-neutral-darkest leading-8">{planAmountText !== "" ? planAmountText : "$5.00"}</div>
          <div className="font-normal text-base text-neutral-darkest pl-1">/month per user</div>
        </div>
        {/* <div className="w-52 mt-8 mb-6">
          <Button 
            onClick={onSendEmail}
            variant="primary"
            label="Upgrade"
            stretch
            textWeight={"semibold"}
            color="neutral-lightest"
            hoverColor="neutral-light"
            textColor="neutral-darkest"
          />
        </div> */}
        <div className="border-b border-neutral-light pt-4"></div>
        <div className="font-semibold text-sm text-neutral-darkest pt-5">This includes:</div>
        <div className={" pb-1"}>
          {points.map((point: string, index: number) => 
            <div className="pt-3 flex flex-row items-center" key={index}>
              <div>
                <Icon variant={"checkCircleFilled"} size={13} />
              </div>
              <div className="font-normal text-sm text-neutral-darkest pl-2">{`${point}`}</div>
            </div>
          )}
        </div>
        {/* <div className="flex flex-row pt-2">
          <div className="pt-1">
            <div className="font-normal text-sm text-neutral-dark">Large organization? Talk to our team for</div>
          </div>
          <div className="pl-1">
            <Button
              variant="link"
              label="Enterprise pricing"
              fontWeight="normal"
              color={"neutral-darkest"}
              fontSize="sm"
              onClick={onSendEmail}
              underline
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};