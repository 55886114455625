import { FormEvent, useEffect } from "react";
import { FormikErrors } from "formik";
import { Button, Image, Input } from "@atoms";
import { localEnv, isProductionBetaVersion } from "@config";
export interface FormValues {
  emailAddress: string;
  password: string;
}

export type SignInPageTemplateProps = {
  values: FormValues;
  errors: FormikErrors<FormValues>;
  loading: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleLoginWithGoogle: () => void;
  handleSignUpRedirect: () => void;
  handleForgotPasswordRedirect: () => void;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
};

export const SignInPageTemplate: React.FC<SignInPageTemplateProps> = ({
  values,
  errors,
  loading,
  handleInputChange,
  handleLoginWithGoogle,
  handleSignUpRedirect,
  handleForgotPasswordRedirect,
  handleSubmit,
}: SignInPageTemplateProps): JSX.Element => {
  const { emailAddress, password } = values;
  const { emailAddress: emailAddressError, password: passwordError } = errors;

  function onKeyDown(keyEvent: any) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
      handleSubmit()
    }
  }

  return (
    <div className="min-h-screen flex">
      <div className="relative hidden lg:block min-h-screen w-6/10">
        <div className="absolute top-6 left-6">
          <Image variant="logoWhite" />
        </div>
        <div style={{ backgroundColor: "#ed2828" }} className="w-full h-full">
          <Image variant="backgroundPhone" width={827} height={775} />
        </div>
      </div>
      <div className="relative w-full min-h-screen lg:w-4/10 lg:flex justify-center items-center">
        <div className="lg:hidden px-6 pt-6 pb-12 sm:pb-24">
          <Image variant="logoColour" />
        </div>
        <form
          className="text-center flex flex-col items-center max-w-xs mx-auto px-4"
          onSubmit={handleSubmit}
          onKeyDown={onKeyDown}
        >
          <div className="px-3 mb-6">
            <div className="mb-2">
              <h3 className="text-2xl sm:text-3xl font-black">
                Sign-in to your Partner Account
              </h3>
            </div>
          </div>
          {localEnv === "dev" ?
            <div className="px-8 mb-4 bg-primary-medium text-secondary-dark">
              {"DEV VERSION"}
            </div>
            :
            isProductionBetaVersion &&
              <div className="text-center bg-primary-light text-secondary-dark">
                {"BETA VERSION"}
              </div>   
          }
          <div className="w-full">
            <div className="mb-3">
              <Input
                name="emailAddress"
                variant="text"
                onChange={handleInputChange}
                placeholder="Email address"
                value={emailAddress}
                errorMessage={emailAddressError}
              />
            </div>
            <div className="mb-3">
              <Input
                name="password"
                variant="password"
                onChange={handleInputChange}
                placeholder="Password"
                value={password}
                errorMessage={passwordError}
              />
            </div>
          </div>
          <div className="self-end mb-5">
            <Button
              variant="link"
              fontWeight="normal"
              color={"neutral-darkest"}
              fontSize="base"
              label="Forgot password?"
              onClick={handleForgotPasswordRedirect}
            />
          </div>
          <div className="mb-6 w-full sm:mb-4">
            <div>
              <Button
                type="submit"
                variant="primary"
                label="Continue with Email"
                stretch
                color="secondary-medium"
                hoverColor="secondary-dark"
                textColor="white"
                // brightness={600}
                loading={loading}
              />
            </div>
            <div className="my-3">or</div>
            <div className="mb-2">
              <Button
                variant="primary"
                onClick={handleLoginWithGoogle}
                label="Continue with Google"
                stretch
                color="black"
                textColor="white"
                hoverColor="neutral-dark"
                brightness={500}
                withIcon="google"
              />
            </div>
          </div>
          <div className="sm:flex mb-12">
            <p className="mb-3 sm:mb-0 font-md font-normal mr-2 w-60">
              Don't have a Partner Account?
            </p>
            <div className="w-20">
              <Button
                variant="link"
                label="Join now"
                fontWeight="normal"
                color={"neutral-darkest"}
                fontSize="base"
                onClick={handleSignUpRedirect}
                underline
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
