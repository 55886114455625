import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import BackgroundPhoneSubtitle from "./background-phone-subtitle-1x.png";

export const BackgroundPhoneImage = ({
  height,
  width,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <div style={{ height, width }}>
      <ImageTemplate
        {...props}
        imgSrc={BackgroundPhoneSubtitle}
      />
    </div>
  );
};