import { useContext } from "react";
import {
  gql,
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
} from "@apollo/client";
import { Auth } from "@aws-amplify/auth";
import {
  getPartner as getPartnerGraphQL,
  getPartnerUpdatedSeatCount as getPartnerUpdatedSeatCountGraphQL,
  getPartnerCheckoutPortalLink as getPartnerCheckoutPortalLinkGraphQL,
  getPartnerBillingPortalLink as getPartnerBillingPortalLinkGraphQL,
  getCardRegisterClientSecretAndCustomerId as getClientSecretGraphQL,
  getPartnerPaymentMethods as getPartnerPaymentMethodsGraphQL,
  getPresignedUrlS3 as getPresignedUrlS3GraphQL,
  getPartnerInvoices as getPartnerInvoicesGraphQL,
  getPartnerBillingDetails as getPartnerBillingDetailsGraphQL,
  getAllGiftCardOffers as getAllGiftCardOffersGraphQL,
  getPartnerStatistics as getPartnerStatisticsGraphQL,
  getPartnerAirdropTransactions as getPartnerAirdropTransactionsGraphQL,
} from "./queries";
import { CreditCardPaymentMethod, Invoice, BillingDetails, GiftCardOffer, PartnerStatsAll, PartnerAirdropTransaction } from "../types";
import { Partner } from "@api";
import { AuthContext } from "../auth/auth-context";


// ===== getClientSecret() =====
interface GetClientSecretValues {
  getClientSecret: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void,
  customerId: string | undefined;
  clientSecretData: string;
}

export function useGetClientSecret(): GetClientSecretValues {
  const { updateClientSecret } = useContext(AuthContext);

  const query = gql(getClientSecretGraphQL);
  const [getClientSecret, { data }] = useLazyQuery<{
    getPartnerCardRegisterClientSecretAndCustomerId: {
      customerId: string;
      clientSecret: string;
    }
  }>(query, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const result = data?.getPartnerCardRegisterClientSecretAndCustomerId;
      console.log("client ID and secret query complete...");
      updateClientSecret(result?.clientSecret);
    },
    onError: async (error: any) => {
      console.error(
        `Failed to get clientId and clientSecret, error: ${error}...`,
      );
    },
  });

  return { 
    getClientSecret,
    customerId: data?.getPartnerCardRegisterClientSecretAndCustomerId?.customerId ?? "", 
    clientSecretData: data?.getPartnerCardRegisterClientSecretAndCustomerId?.clientSecret ?? ""
  };
}

// =========== getPartner() ===========
// Note that during the initial load of the partner, the Context vars have not been initialized yet, so can't use them in this method
interface GetPartnerValues {
  getPartner: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;
  partner: Partner | undefined;
}

export function useGetPartner(): GetPartnerValues {
  const query = gql(getPartnerGraphQL);
  const [getPartner, { data }] = useLazyQuery<{
    getPartner: Partner;
  }>(query, {
    onCompleted: async (data) => {
      console.log("partner query complete.");
    },
    onError: async (error: any) => {
      console.error(`Failed to "getPartner", error: ${error}...`);
      await Auth.signOut();
      location.replace("/");
    },
  });

  return { getPartner, partner: data?.getPartner };
}

// =========== getPartnerUpdatedSeatCount() ===========
interface GetPartnerUpdatedSeatCountValues {
  getPartnerUpdatedSeatCount: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;
  partnerUpdatedSeatCount: number | undefined;
}

export function useGetPartnerUpdatedSeatCount(): GetPartnerUpdatedSeatCountValues {
  const query = gql(getPartnerUpdatedSeatCountGraphQL);
  const [getPartnerUpdatedSeatCount, { data }] = useLazyQuery<{
    getPartnerUpdatedSeatCount: number;
  }>(query, {
    onError: async (error: any) => {
      console.error(
        `Failed to "getPartnerUpdatedSeatCountGraphQL", error: ${error}...`,
      );
    },
  });
  return {
    getPartnerUpdatedSeatCount,
    partnerUpdatedSeatCount: data?.getPartnerUpdatedSeatCount,
  };
}

// =========== getPartnerPaymentMethods() ===========
interface GetPartnerPaymentMethodsValues {
  getPartnerPaymentMethods: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;
  creditCards: [CreditCardPaymentMethod] | null;
}

export function useGetPartnerPaymentMethods(): GetPartnerPaymentMethodsValues {
  const { billingDetails, setBillingDetails } = useContext(AuthContext);
  const query = gql(getPartnerPaymentMethodsGraphQL);

  const [getPartnerPaymentMethods, { data }] = useLazyQuery<{
    getPartnerPaymentMethods: [CreditCardPaymentMethod];
  }>(query, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      console.log("payment method query complete.");
      setBillingDetails({
        invoices: billingDetails.invoices,  // temporary
        creditCards: data?.getPartnerPaymentMethods,
        planAmount: billingDetails.planAmount // temporary
      });
    },
    onError: async (error: any) => {
      console.error(
        `Failed getPartnerPaymentMethodsGraphQL, error: ${error}...`,
      );
    },
  });
  return {
    getPartnerPaymentMethods,
    creditCards: data?.getPartnerPaymentMethods ?? null,
  };
}

// =========== GetPartnerInvoices() ===========
interface GetPartnerInvoicesValues {
  getPartnerInvoices: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;
  invoices: [Invoice] | null;
}

export function useGetPartnerInvoices(): GetPartnerInvoicesValues {
  const { billingDetails, setBillingDetails } = useContext(AuthContext);
  const query = gql(getPartnerInvoicesGraphQL);

  const [getPartnerInvoices, { data }] = useLazyQuery<{
    getPartnerInvoices: [Invoice] | null;
  }>(query, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      console.log("invoices query complete");
      const invoiceData = data?.getPartnerInvoices;
      // console.log("invoiceData: ", invoiceData);
      setBillingDetails({
        invoices: invoiceData !== null ? invoiceData : [], 
        creditCards: billingDetails.creditCards,  // temporary
        planAmount: billingDetails.planAmount // temporary
      });
    },
    onError: async (error: any) => {
      console.error(
        `Failed getPartnerInvoices, error: ${error}...`,
      );
    },
  });
  return {
    getPartnerInvoices,
    invoices: data?.getPartnerInvoices ?? null,
  };
}

// =========== getPartnerStatistics() ===========
interface GetPartnerStatisticsValues {
  getPartnerStatistics: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;
  partnerStatsAll: PartnerStatsAll | undefined;
}

export function useGetPartnerStatistics(): GetPartnerStatisticsValues {
  const { setPartnerStatistics } = useContext(AuthContext);
  const query = gql(getPartnerStatisticsGraphQL);
  const [getPartnerStatistics, { data }] = useLazyQuery<{
    getPartnerStatistics: PartnerStatsAll;
  }>(query, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      console.log("got partner statistics...");
      setPartnerStatistics(data?.getPartnerStatistics);
    },
    onError: async (error: any) => {
      console.error(
        `Failed to "getPartnerStatisticsGraphQL", error: ${error}...`,
      );
    },
  });

  return {
    getPartnerStatistics,
    partnerStatsAll: data?.getPartnerStatistics,
  };
}

// NOTE: we are going to remove getPartnerInvoices and getPartnerPaymentMethods, and instead fetch all that inside a single call to getBillingDetails. At the moment getBillingDetails just fetches the planAmount.

// =========== GetBillingDetails() ===========
interface GetBillingDetailsValues {
  getBillingDetails: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;
}

export function useGetBillingDetails():  GetBillingDetailsValues {
  const { billingDetails, setBillingDetails } = useContext(AuthContext);
  const query = gql(getPartnerBillingDetailsGraphQL);

  const [ getBillingDetails, { data }] = useLazyQuery<{
    getPartnerBillingDetails: BillingDetails;
  }>(query, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      console.log("Billing details fetched");
      setBillingDetails({
        invoices: billingDetails.invoices,  // todo
        creditCards: billingDetails.creditCards,  // todo
        planAmount: data?.getPartnerBillingDetails?.planAmount
      });
      console.log(JSON.stringify(data?.getPartnerBillingDetails, null, 4));
    },
    onError: async (error: any) => {
      console.error(
        `Failed getBillingDetails, error: ${error}...`,
      );
    },
  });
  return {
    getBillingDetails,
  };
}


// =========== getPresignedUrlS3() ===========
interface GetPresignedUrlS3Values {
  getPresignedUrlS3: (
    filename: string,
    filepath: string
  ) => void;
  fileUrlS3: string | undefined;
}

export function useGetPresignedUrlS3(): GetPresignedUrlS3Values {
  const query = gql(getPresignedUrlS3GraphQL);
  
  const [getPresignedUrlS3Query, { data }] = useLazyQuery<{
    getPresignedUrlS3: string | undefined;
  }>(query, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      console.log("Got presigned URL:");
      console.log("data", data);
    },
    onError: async (error: any) => {
      console.error(
        `Failed getPresignedUrlS3, error: ${error}...`,
      );
    },
  });

  const getPresignedUrlS3 = async function (filename: string, filepath: string) {
    console.log("getting presigned Url ... ");
    getPresignedUrlS3Query({ variables: { filename, filepath, bucket: "partnerLogo" } });
  };

  return { 
    getPresignedUrlS3,
    fileUrlS3: data?.getPresignedUrlS3
  }
}


// =========== getAllGiftCardOffers() ===========

interface GetAllGiftCardOffersValues {
  getAllGiftCardOffers: () => void;
  giftCardOffers: [GiftCardOffer] | undefined;
}

export function useGetAllGiftCardOffers(): GetAllGiftCardOffersValues {
  const { setGiftCardOffers } = useContext(AuthContext);
  const query = gql(getAllGiftCardOffersGraphQL);
  
  const [getAllGiftCardOffersQuery, { data }] = useLazyQuery<{
    getAllGiftCardOffers: [GiftCardOffer];
  }>(query, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      console.log("Got all gift-card offers:");
      console.log("data", data?.getAllGiftCardOffers);
      setGiftCardOffers(data?.getAllGiftCardOffers);

    },
    onError: async (error: any) => {
      console.error(
        `Failed getAllGiftCardOffers, error: ${error}...`,
      );
    },
  });

  const getAllGiftCardOffers = async function () {
    console.log("getting all Gift Card Offers ... ");
    getAllGiftCardOffersQuery();
  };

  return { 
    getAllGiftCardOffers,
    giftCardOffers: data?.getAllGiftCardOffers
  }
}

interface GetPartnerAirdropTransactionsValues {
  getPartnerAirdropTransactions: () => void;
  airdropTransactions: [PartnerAirdropTransaction] | undefined;
}

export function useGetPartnerAirdropTransactions(): GetPartnerAirdropTransactionsValues {
  const { setPartnerAirdropTransactions } = useContext(AuthContext);
  const query = gql(getPartnerAirdropTransactionsGraphQL);

  const [getPartnerAirdropTransactionsQuery, { data }] = useLazyQuery<{
    getAirdropTransactions: [PartnerAirdropTransaction];
  }>(query, {
    fetchPolicy: "no-cache",
    onCompleted: (data: any) => {
      console.log("Got all Airdrop transactions:");
      console.log("data", data?.getAirdropTransactions);
      setPartnerAirdropTransactions(data?.getAirdropTransactions);

    },
    onError: async (error: any) => {
      console.error(
        `Failed getPartnerAirdropTransactions, error: ${error}...`,
      );
    },
  });

  const getPartnerAirdropTransactions = async function () {
    console.log("getting all Gift Card Offers ... ");
    getPartnerAirdropTransactionsQuery();
  };

  return {
    getPartnerAirdropTransactions,
    airdropTransactions: data?.getAirdropTransactions,
  }

}

// ======================
//
//       NOT USED
//
// ======================

// =========== getPartnerCheckoutPortalLink() ===========
interface GetPartnerCheckoutPortalLinkValues {
  getPartnerCheckoutPortalLink: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;
  partnerCheckoutPortalLink: string | undefined;
}

export function useGetPartnerCheckoutPortalLink(): GetPartnerCheckoutPortalLinkValues {
  const query = gql(getPartnerCheckoutPortalLinkGraphQL);
  const [getPartnerCheckoutPortalLink, { data }] = useLazyQuery<{
    getPartnerCheckoutPortalLink: string;
  }>(query, {
    onError: async (error: any) => {
      console.error(
        `Failed to "getPartnerCheckoutPortalLink", error: ${error}...`,
      );
    },
  });
  return {
    getPartnerCheckoutPortalLink,
    partnerCheckoutPortalLink: data?.getPartnerCheckoutPortalLink,
  };
}

// =========== getPartnerBillingPortalLink() ===========
interface GetPartnerBillingPortalLinkValues {
  getPartnerBillingPortalLink: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;
  partnerBillingPortalLink: string | undefined;
}

export function useGetPartnerBillingPortalLink(): GetPartnerBillingPortalLinkValues {
  const query = gql(getPartnerBillingPortalLinkGraphQL);
  const [getPartnerBillingPortalLink, { data }] = useLazyQuery<{
    getPartnerBillingPortalLink: string;
  }>(query, {
    onError: async (error: any) => {
      console.error(
        `Failed to "getPartnerBillingPortalLink", error: ${error}...`,
      );
    },
  });
  return {
    getPartnerBillingPortalLink,
    partnerBillingPortalLink: data?.getPartnerBillingPortalLink,
  };
}