import { Icon, Spinner } from "@atoms";
import { BrightnessType, SizeType } from "@theme/types";
import { widthMixin } from "@theme/mixins";
import { PRIMARY_BUTTON_SIZE } from "./constants";

export type SecondaryButtonTemplateProps = {
  onClick?: () => void;
  label: string;
  stretch?: boolean;
  autosize?: boolean;
  size?: SizeType;
  withIcon?: "google";
  color: string;
  brightness?: BrightnessType;
  type?: "button" | "submit";
  loading?: boolean;
  height?: number;
  width?: number;
};

export const SecondaryButtonTemplate = ({
  onClick,
  label,
  stretch,
  autosize,
  size,
  color,
  withIcon,
  type = "button",
  loading,
}: SecondaryButtonTemplateProps): JSX.Element => {
  return (
    <button
      type={type}
      color="black"
      className={`${widthMixin({
        table: PRIMARY_BUTTON_SIZE,
        size,
        stretch,
        autosize,
        centered: true,
      })} py-2 px-3 text-white rounded-full min-h-full`}
      style={{backgroundColor: `${color}`, borderStyle: "solid", borderColor: "#bbbbbb", borderWidth: 1, color: "black"}}
      {...(onClick && { onClick })}
    >
      <div style={{height: 25}} className="flex items-center">
        {withIcon ? (
          <div style={{ bottom: 0.5 }} className="mr-3 relative">
            <Icon variant={withIcon} height={15} width={16} />
          </div>
        ) : null}
        {loading ? <Spinner size={12} /> : <span>{label}</span>}
      </div>
    </button>
  );
};
