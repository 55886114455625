import { Icon, Image } from "@atoms";
import { Item, Card } from "@molecules";
import { SideBarLogicInterface } from "./logic";
import { usePartnerUtils } from "../../../../utils/partner-utils"; // todo: implement @ pathing
import { localEnv, isProductionBetaVersion } from "@config";

export type SideBarTemplateProps = SideBarLogicInterface;

export const SideBarTemplate = ({
  menuItems,
  signOut,
  selectedPageIndex,
  navigateToPage,
}: SideBarTemplateProps): JSX.Element => {
  const { isSectionIncomplete } = usePartnerUtils();

  return (
    <div className="flex flex-col justify-between w-full h-screen min-h-screen px-4 pt-6 bg-neutral-lightest">
      <div className=""> 
        <div className="mt-4 mb-16 ml-3">
          <Image variant="logoColour" />
        </div>
        {localEnv === "dev" ?
          <div className="text-center bg-primary-medium text-secondary-dark">
            {"DEV VERSION"}
          </div>
          :
          isProductionBetaVersion &&
            <div className="text-center bg-primary-light text-secondary-dark">
              {"BETA VERSION"}
            </div>         
        }
        <div>
          {menuItems.map(({ label, to, icon }, index) => (
            <div key={index} className="my-2">
              <Item
                variant="sidebar"
                icon={<Icon {...icon} width={24} height={24} size={24} />}
                label={label}
                onClick={() => {
                  navigateToPage(index, to)}
                }
                selected={selectedPageIndex !== undefined && selectedPageIndex === index ? true : false}
                isSectionIncomplete={isSectionIncomplete(to)}
              />
            </div>
          ))}
          <div key={"sign-out"} className="my-2">
            <Item
              variant="sidebar"
              icon={<Icon variant="signOut" size={24} />}
              label={"Sign out"}
              onClick={() => signOut?.()}
              selected={false}
              isSectionIncomplete={false}
            />
          </div>
        </div>
      </div>
      <div className="pb-8">
        <Card variant="userInfo" onClick={() => {}} />
      </div>
    </div>
  );
};
