import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@shadcn"

export type DashboardCardTemplateProps = {
  title: string;
  description: string;
  icon: JSX.Element;
};

export const DashboardCardTemplate = ({
  title,
  description,
  icon,
}: DashboardCardTemplateProps): JSX.Element => {
  return (
    <div className="ml-0 mr-4 my-2 w-64">
      <Card className="w-[350px]">
        <CardHeader>
          <div className="flex flex-row justify-between items-center">
            <div className="text-left w-full">
              <CardTitle>{title}</CardTitle>
            </div>
            <div className="">
              {icon}
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <CardDescription>{description}</CardDescription>
        </CardContent>
        {/* <CardFooter className="flex justify-between">
          <div className="text-xs text-neutral-mediumDark">+15% change from last month</div>
        </CardFooter> */}
      </Card>
     </div>
  );
};
