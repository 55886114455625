import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import LogoColour2x from "./logo-colour.png";

export const LogoColourImage = ({
  height = 39,
  width = 137,
  highResolution = true,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <div style={{ height, width }}>
      <ImageTemplate
        {...props}
        imgSrc={LogoColour2x}
      />
    </div>
  );
};
