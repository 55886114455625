import { RefObject, useRef, useState } from "react";

export const useTextareaInputTemplate = (
  value: string,
): {
  isActive: boolean;
  toggleFocus: () => void;
  toggleBlur: (value: string) => void;
  inputRef: RefObject<HTMLTextAreaElement>;
} => {
  const [isActive, setIsActive] = useState(value !== "");
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const toggleFocus = () => {
    setIsActive(true);
    inputRef.current?.focus();
  };

  const toggleBlur = (value: string) => {
    if (!value) {
      setIsActive(false);
      inputRef.current?.blur();
    }
  };

  return { isActive, toggleFocus, toggleBlur, inputRef };
};
