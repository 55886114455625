import {
  DashboardLayoutTemplate,
  DashboardLayoutTemplateProps,
} from "./template";

export type DashboardLayoutProps = DashboardLayoutTemplateProps;

export const DashboardLayout = (props: DashboardLayoutProps): JSX.Element => {
  return <DashboardLayoutTemplate {...props} />;
};
