/* tslint:disable */
/* eslint-disable */


/*
    selectedOptions {
      rewards {
        type
      }
      perks {
        type
      }
    }
*/

export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner(
    $partnerUpdate: PartnerUpdate!
    $username: String!
    $signUp: Boolean
  ) {
    updatePartner(
      partnerUpdate: $partnerUpdate
      username: $username
      signUp: $signUp
    ) {

      emailAddress
      firstName
      lastName
      brandIds
      didLogin
      description
      organization
      phoneNumber
      website
      images {
        logoUrl
      }
      address {
        street
        city
        province
        country
        postalCode
      }
      premiumSettings {
        users {
          firstName
          lastName
          emailAddress
          userId
        }
        percentage
        availableSeats
      }
    }
  }
`;

export const addPartnerAddress = /* GraphQL */ `
  mutation AddPartnerAddress($street: String!, $city: String!, $province: String!, $country: String!, $postalCode: String!) {
    addPartnerAddress(street: $street, city: $city, province: $province, country: $country, postalCode: $postalCode) {
      emailAddress
      firstName
      lastName
      brandIds
      didLogin
      description
      organization
      phoneNumber
      website
      address {
        street
        city
        province
        country
        postalCode
      }
      premiumSettings {
        users {
          firstName
          lastName
          emailAddress
          userId
        }
        percentage
        availableSeats
      }
    }
  }
`;

export const addUserToPremiumPartner = /* GraphQL */ `
  mutation AddUserToPremiumPartner($firstName: String!, $lastName: String!, $userEmail: String!, $paymentId: String!, $employmentStartDate: String, $birthday: String) {
    addUserToPremiumPartner(firstName: $firstName, lastName: $lastName, userEmail: $userEmail, paymentId: $paymentId, employmentStartDate: $employmentStartDate, birthday: $birthday) {
      emailAddress
      firstName
      lastName
      brandIds
      didLogin
      description
      organization
      phoneNumber
      website
      address {
        street
        city
        province
        country
        postalCode
      }
      premiumSettings {
        users {
          firstName
          lastName
          emailAddress
          userId
          employmentStartDate
          birthday
        }
        percentage
        availableSeats
      }
    }
  }
`;

export const editUserFromPremiumPartner = /* GraphQL */ `
  mutation EditUserFromPremiumPartner($firstName: String!, $lastName: String!, $index: Int!, $employmentStartDate: String, $birthday: String) {
    editUserFromPremiumPartner(firstName: $firstName, lastName: $lastName, index: $index, employmentStartDate: $employmentStartDate, birthday: $birthday) {
      emailAddress
      firstName
      lastName
      brandIds
      didLogin
      description
      organization
      phoneNumber
      website
      address {
        street
        city
        province
        country
        postalCode
      }
      premiumSettings {
        users {
          firstName
          lastName
          emailAddress
          userId
          employmentStartDate
          birthday
        }
        percentage
        availableSeats
      }
    }
  }
`;

export const removeUserFromPremiumPartner = /* GraphQL */ `
  mutation RemoveUserFromPremiumPartner($userEmail: String!) {
    removeUserFromPremiumPartner(userEmail: $userEmail) {
      emailAddress
      firstName
      lastName
      brandIds
      didLogin
      description
      organization
      phoneNumber
      website
      address {
        street
        city
        province
        country
        postalCode
      }
      premiumSettings {
        users {
          firstName
          lastName
          emailAddress
          userId
        }
        percentage
        availableSeats
      }
    }
  }
`;

export const resendEmailToUserFromPremiumPartner = /* GraphQL */ `
mutation ResendEmailToUserFromPremiumPartner($userResendIndex: Int!) {
  resendEmailToUserFromPremiumPartner(userResendIndex: $userResendIndex) {
    emailAddress
      firstName
      lastName
      brandIds
      didLogin
      description
      organization
      phoneNumber
      website
      address {
        street
        city
        province
        country
        postalCode
      }
      premiumSettings {
        users {
          firstName
          lastName
          emailAddress
          userId
        }
        percentage
        availableSeats
      }
  }
}`;

export const saveImageToS3 = /* GraphQL */ `
  mutation SaveImageToS3($filename: String!, $bucket: String!, $data: Buffer!) {
    saveImageToS3(filename: $filename, bucket: $bucket, data: $data)
  }
`;

export const airdropGiftCard = /* GraphQL */ `
mutation AirdropGiftCard($airdropGiftCardRequest: PremiumGiftCardAirdropRequest!, $paymentId: String!) {
  airdropGiftCard(airdropGiftCardRequest: $airdropGiftCardRequest, paymentId: $paymentId) {
    emailAddress
  }
}`

export const updatePartnerBoostedRewards = /* GraphQL */ `
mutation UpdatePartnerBoostedRewards($boostedRewardsData: OfferBoostInput) {
  updatePartnerBoostedRewards(boostedRewardsData: $boostedRewardsData) {
    emailAddress
      firstName
      lastName
      brandIds
      didLogin
      description
      organization
      phoneNumber
      website
      status
      address {
        street
        city
        province
        country
        postalCode
      }
      premiumSettings {
        users {
          firstName
          lastName
          emailAddress
          userId
          employmentStartDate
          birthday
        }
        percentage
        availableSeats
      }
    offerAmountsManagement {
      offerBoost {
        isBoostingAllOffers
        boostAmount
        boostLimitPerUser
      }
    }
  }
}`