import { FormEvent } from "react";
import { FormikErrors } from "formik";
import { Button, Image, Input } from "@atoms";
import ReactModal from "react-modal";
import { styles as globalStyles } from "../../../styles/styles";

export interface FormValues {
  password: string;
  repeatPassword: string;
}

export type ForceResetPasswordPageTemplateProps = {
  values: FormValues;
  errors: FormikErrors<FormValues>;
  loading: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSignInRedirect: () => void;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  onCloseModal: () => void;
  showSuccessModal: boolean;

};

export const ForceResetPasswordPageTemplate: React.FC<ForceResetPasswordPageTemplateProps> = ({
  values,
  errors,
  loading,
  handleInputChange,
  handleSignInRedirect,
  handleSubmit,
  onCloseModal,
  showSuccessModal
}: ForceResetPasswordPageTemplateProps): JSX.Element => {
  const { password, repeatPassword } = values;
  const { password: passwordError, repeatPassword: repeatPasswordError } = errors;

  return (
    <div className="min-h-screen flex">
      <div className="relative hidden lg:block min-h-screen w-6/10">
        <div className="absolute top-6 left-6">
          <Image variant="logoWhite" />
        </div>
        <div style={{ backgroundColor: "#ed2828" }} className="w-full h-full">
          <Image variant="backgroundPhone" width={827} height={775} />
        </div>
      </div>
      <div className="relative w-full min-h-screen lg:w-4/10 lg:flex justify-center items-center">
        <div className="lg:hidden absolute top-6 left-6">
          <Image variant="logoColour" />
        </div>
        <form
          className="text-center flex flex-col items-center max-w-xs mx-auto px-4"
          style={{marginTop: -110}}
          onSubmit={handleSubmit}
        >
          <div className="px-3 mb-2">
            <div className="w-80">
              <h3 className="text-3xl font-black">Choose a Password</h3>
            </div>
          </div>
          <div className="px-3 mb-6">
            <div className="mb-2">
              <h3 className="text-md">
                Before you get started you need a new password
              </h3>
            </div>
          </div>
          <div className="w-full">
            <div className="mb-2">
              <Input
                name="password"
                variant="password"
                onChange={handleInputChange}
                placeholder="Password"
                value={password}
                errorMessage={passwordError}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="mb-2">
              <Input
                name="repeatPassword"
                variant="password"
                onChange={handleInputChange}
                placeholder="Repeat Password"
                value={repeatPassword}
                errorMessage={repeatPasswordError}
              />
            </div>
          </div>
          <div className="mt-2 mb-6 w-full sm:mb-4">
            <div>
              <Button
                type="submit"
                variant="primary"
                label="Submit"
                stretch
                color="secondary-medium"
                hoverColor="secondary-dark"
                textColor="white"
                loading={loading}
              />
            </div>
          </div>
          <div className="mb-6 w-full sm:mb-4">
            <div>
              <Button
                variant="secondary"
                label="Back to Sign In"
                stretch
                color="#ffffff"
                brightness={500}
                onClick={handleSignInRedirect}
              />
            </div>
          </div>
          <ReactModal
            isOpen={showSuccessModal}
            onRequestClose={onCloseModal}
            style={globalStyles.generalModal}
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}
            contentLabel="Password Reset Modal"
          >
            <div className="font-medium text-neutral-darkest text-center text-xl pt-4">Success!</div>
            <div className="font-normal text-neutral-dark text-base text-center pt-1 pb-8">Your password has been changed. You may now login to the dashboard.</div>             
            <div className="flex flex-row justify-center pt-4">
              <div className="w-40">   
                <Button
                  type="submit"
                  onClick={onCloseModal}
                  variant="primary"
                  label="OK"
                  stretch
                  color="primary-medium"
                  textWeight="semibold"
                /> 
              </div>
            </div>    
          </ReactModal>
        </form>
      </div>
    </div>
  );
};
