
import { Image } from "@atoms";

export type OnboardCardTemplateProps = {
  title: string;
  description: string;
  icon: JSX.Element;
  onClick: () => void;
  index: number;
  isSectionIncomplete: boolean;
};

export const OnboardCardTemplate = ({
  title,
  icon,
  onClick,
  index,
  isSectionIncomplete
}: OnboardCardTemplateProps): JSX.Element => {
  return (
    <button
      onClick={onClick}
      style={{ height: 297 }}
      className="flex flex-col text-center pt-3 pl-3 pb-3 pr-3 h-full bg-transparent border border-neutral-light rounded-3xl
      transition ease-in-out delay-0 hover:bg-neutral-lightest duration-200 transition transform scale-100 ease-in-out delay-0 duration-200 hover:scale-105"
    >
      <div className="flex-col items-start justify-between h-auto max-w-min">
        <div className="absolute z-50 font-bold text-xs m-2 border-0 p-4 w-4 h-4 rounded-3xl bg-gray-50 flex justify-center items-center">{index + 1}</div>
        <div className="h-full">
          <div className="overflow-hidden rounded-lg">
            <div className="">
              {icon}
            </div>
          </div>
          <div className="text-left w-full h-16">
            <h5 className="my-3 w-11/12 font-medium text-base leading-5 text-neutral-darkest">{title}</h5>
          </div>
        </div>
        {isSectionIncomplete ?
          <div>
            <div className="flex flex-row text-left items-center h-6 pb-2">
              <h4 className="font-medium text-base pr-1">Start</h4>
              <Image variant={"rightArrow"} />
            </div>
          </div>
        :
          <div>
            <div className="flex flex-row justify-end items-center h-6 pb-2">
              <div className="flex font-semibold text-xs text-successful-darkest px-3 pt-2 h-8 bg-successful-lightest rounded-full align-self-center justify-content-center">
                Completed
              </div>
            </div>  
          </div>
        }
      </div>
    </button>
  );
};
