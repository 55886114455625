import { SideBar } from "./bar-side";
import { BarBottomNav, BarBottomNavProps } from "./bar-bottom-nav";

export type BarProps = 
| ({ variant: "side" }) 
| ({ variant: "bottom" } & BarBottomNavProps);

export const Bar = (props: BarProps): JSX.Element => {
  switch (props.variant) {
    case "side":
      return <SideBar />;
    case "bottom":
      return <BarBottomNav {...props} />;
    default:
      throw new Error(`[BAR] Invalid variant provided`);
  }
};
