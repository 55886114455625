import { Card } from "@molecules";
import { BlogSectionLogicInterface } from "./logic";
import { BookOpen as BookOpenIcon } from "lucide-react";

export type BlogSectionTemplateProps = BlogSectionLogicInterface;

export const BlogSectionTemplate = ({
  onClickBlog,
  blogData
}: BlogSectionTemplateProps): JSX.Element => {
  return (
    <div>
      <div className="flex flex-row items-center h-4 mt-8 ml-0">
        <BookOpenIcon size={20} />
        <div className="text-neutral-darkest text-xl font-normal ml-2">
          Learn more
        </div>
      </div>
      <div className="flex flex-row flex-wrap w-full mt-4 pr-4 pb-4 justify-start max-w-full w-full">
        {blogData.map((item: any, index: number) => (
          <div key={index}>
            <Card
              variant="blog"
              image={item.image}
              date={item.date}
              blogTypeText={item.blogTypeText}
              title={item.title}
              description={item.description}
              onClick={() => onClickBlog(item.blogUrl)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
