import { useEffect, useState } from "react";
import { AirdropConfirmPageTemplateProps } from "./template";
import { useAirdropGiftCard } from "@mutations";
import { useGetPartnerPaymentMethods } from "@queries";
import { useNavigate } from "react-router";


export const useAirdropConfirmPage = (): AirdropConfirmPageTemplateProps => {
    const navigate = useNavigate();
    const { getPartnerPaymentMethods } = useGetPartnerPaymentMethods();
    const airdropGiftCard = useAirdropGiftCard();
    const [progress, setProgress] = useState(75);

    useEffect(() => {
        const timer = setTimeout(() => setProgress(100), 1000);
        return () => clearTimeout(timer);
      }, []);

    // Isolated useEffect to get the partner's payment methods
    useEffect(() => {
        getPartnerPaymentMethods();
    }, []);

    const navigateBackto = (to: string) => {
        navigate(to)
    };

    const onReturnPressed = () => {
        const to = "/dashboard/airdrop/select-amount";
        navigateBackto(to);
    }

    return {
        progress,
        onReturnPressed,
        airdropGiftCard
    }
}