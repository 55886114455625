import { HomePage } from "./home";
import { CardLinkedOffersPage } from "./card-linked-offers";
import { CashbackRewardsPage } from "./cashback-rewards";
import { OnlineAffiliatesPage } from "./online-affiliates";
import { BillingPage, BillingPageTemplateProps } from "./billing";
import { PendingApprovalPage } from "./pending-approval";
import { ProfilePage } from "./profile";
import { TeamPage } from "./team";
import { PerksPage } from "./perks";
import { WelcomePage } from "./welcome";
import {
  AirdropPage,
  AirdropSelectMembersPage,
  AirdropSelectOfferPage,
  AirdropSelectAmountPage,
  AirdropConfirmPage,
  AirdropCompletePage,
  AirdropHistoryPage,
} from "./airdrop";
import {
  CelebrationsBirthdayPage,
  CelebrationsCustomPage,
  CelebrationsHolidayPage,
  CelebrationsMainPage,
  CelebrationsSeniorityPage,
} from "./celebrations";

export type PageProps =
  | { variant: "home" }
  | { variant: "cardLinkedOffers" }
  | { variant: "cashback-rewards"}
  | { variant: "onlineAffiliates" }
  | { variant: "billing" }
  | { variant: "pendingApproval" }
  | { variant: "profile" }
  | { variant: "team" }
  | { variant: "perks" }
  | { variant: "welcome" }
  | { variant: "airdrop" }
  | { variant: "select-members" }
  | { variant: "select-offers" }
  | { variant: "select-amount" }
  | { variant: "airdrop-confirm" }
  | { variant: "airdrop-complete" }
  | { variant: "airdrop-history" }
  | { variant: "celebrations-main" }
  | { variant: "celebrations-birthday" }
  | { variant: "celebrations-holiday" }
  | { variant: "celebrations-seniority" }
  | { variant: "celebrations-custom" };

export const Page = (props: PageProps): JSX.Element => {
  switch (props.variant) {
    case "home":
      return <HomePage />;
    case "cardLinkedOffers":
      return <CardLinkedOffersPage />;
    case "cashback-rewards":
      return <CashbackRewardsPage />;
    case "onlineAffiliates":
      return <OnlineAffiliatesPage />;
    case "billing":
      return <BillingPage />;
    case "pendingApproval":
      return <PendingApprovalPage />;
    case "profile":
      return <ProfilePage />;
    case "team":
      return <TeamPage />;
    case "perks":
      return <PerksPage />;
    case "welcome":
      return <WelcomePage />;
    case "airdrop":
      return <AirdropPage />;
    case "select-members":
      return <AirdropSelectMembersPage />;
    case "select-offers":
      return <AirdropSelectOfferPage />;
    case "select-amount":
      return <AirdropSelectAmountPage />;
    case "airdrop-confirm":
      return <AirdropConfirmPage />;
    case "airdrop-complete":
      return <AirdropCompletePage />;
    case "airdrop-history":
      return <AirdropHistoryPage />;
    case "celebrations-main":
      return <CelebrationsMainPage />;
    case "celebrations-birthday":
      return <CelebrationsBirthdayPage />;
    case "celebrations-holiday":
      return <CelebrationsHolidayPage />;
    case "celebrations-seniority":
      return <CelebrationsSeniorityPage />;
    case "celebrations-custom":
      return <CelebrationsCustomPage />;
    default:
      throw new Error(`[SIGN UP ERROR]: Invalid variant provided`);
  }
};
