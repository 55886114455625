import { useState, useContext } from "react";
import { useFormik, FormikErrors } from "formik";
import { useForceResetPassword } from "@auth";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "@auth";

import { FormValues, ForceResetPasswordPageTemplateProps } from "./template";

export const useForceResetPasswordPage = (): ForceResetPasswordPageTemplateProps => {
  const forceResetPassword = useForceResetPassword();
  const navigate = useNavigate();

  const { cognitoUser } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const validate = (
    values: FormValues,
  ): FormikErrors<FormValues> | undefined => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.password) {
      errors.password = "Password is required";
    } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(values.password)) {
      errors.password =
        "Password must contain one upper case character, one lower case character and one number";
    } else if (!values.repeatPassword) {
      errors.repeatPassword = "Please confirm the password";
    } else if (values.password !== values.repeatPassword) {
      errors.repeatPassword = "The passwords do not match";
    }

    return errors;
  };
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldError,
  } = useFormik({
    initialValues: {
      password: "",
      repeatPassword: "",
    },
    validate,
    validateOnChange: false,
    onSubmit: async ({ password }: FormValues) => {
      setLoading(true);
      try {
        await forceResetPassword(cognitoUser, password);
        setShowSuccessModal(true);
      } catch (error) {
        setLoading(false);
        setFieldError("emailAddress", "Something went wrong");
      }
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e);

  const handleSignInRedirect = () => navigate("/signin");

  const onCloseModal = (): void => {
    setShowSuccessModal(false);
    navigate("/signin");
  }

  return {
    values,
    errors,
    loading,
    handleInputChange,
    handleSignInRedirect,
    handleSubmit,
    showSuccessModal,
    onCloseModal
  };
};
