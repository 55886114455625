import { Partner } from "@api";
import { BillingPageTemplate } from "./template";
import { useBillingPage } from "./logic";
import { Invoice } from "../../../types";

export type BillingPageTemplateProps = {
  partner: Partner,
  modalIsOpen: boolean;
  closeModal: () => void;
  onPressAddCard: () => void;
  selectedCardIndex: number;
  onPressDownloadInvoice: () => void;
  cardDataList: any;
  invoices: [Invoice] | null;
  handleSubmit: () => void;
  handleCardInputChange: () => void;
  succeeded: boolean;
  processing: boolean;
  disabled: boolean;
  error: any;
  isAddressSubmitted: boolean;
  handleUpgradeClick: () => void;
  handleSendEmailClick: () => void;
  planAmount: number;
}

export const BillingPage = (): JSX.Element => {
  const logic = useBillingPage();

  return (
    <BillingPageTemplate {...logic} />
  );
};
