import {
  PartnersButtonTemplate,
  PartnersButtonTemplateProps,
} from "./template";

export type PartnersButtonProps = PartnersButtonTemplateProps;

export const PartnersButton = (props: PartnersButtonProps): JSX.Element => {
  return <PartnersButtonTemplate {...props} />;
};
