import { Icon } from "@atoms";

export type CloseButtonTemplateProps = {
  onClick: () => void,
};

export const CloseButtonTemplate = ({
  onClick,
}: CloseButtonTemplateProps): JSX.Element => {  
  return (
    <button onClick={onClick}>
      <div className={`absolute w-6 h-6 flex flex-row right-6 top-6`}>
        <Icon variant={"closeX"} size={24} strokeWidth={1.5} />
      </div>
    </button>
  );
};
