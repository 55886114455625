import { CheckBoxInput, CheckBoxInputProps } from "./input-checkbox";
import { PasswordInput, PasswordInputProps } from "./input-password";
import { TextInput, TextInputProps } from "./input-text";
import { TextareaInput, TextareaInputProps } from "./input-textarea";
import { DropdownInput, DropdownInputProps } from "./input-dropdown";

export type InputProps =
  | ({ variant: "checkbox" } & CheckBoxInputProps)
  | ({ variant: "dropdown" } & DropdownInputProps)
  | ({ variant: "text" } & TextInputProps)
  | ({ variant: "textArea" } & TextareaInputProps)
  | ({ variant: "password" } & PasswordInputProps);

export const Input = (props: InputProps): JSX.Element => {
  const { variant } = props;
  switch (props.variant) {
    case "checkbox":
      return <CheckBoxInput {...props} />;
    case "dropdown":
      return <DropdownInput {...props} />;
    case "text":
      return <TextInput {...props} />;
    case "textArea":
      return <TextareaInput {...props} />;     
    case "password":
      return <PasswordInput {...props} />;
    default:
      throw new Error(`Invalid Input variant [${variant}] provided`);
  }
};
