import { Layout } from "@layouts";
import { Card, Item } from "@molecules";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "@auth";
import { Image, ImageVariantType } from "@atoms";
import { Input as SInput, Progress, Button as SButton } from "@shadcn";

export type CelebrationsBirthdayPageTemplateProps = {
  premiumUsers: any[];
  navigateToPage: (index: number, to: string) => void;
  onReturnPressed: () => void;
};

export const CelebrationsBirthdayPageTemplate = ({
  premiumUsers,
  navigateToPage,
  onReturnPressed,
}: CelebrationsBirthdayPageTemplateProps): JSX.Element => {
  const { partner } = useContext(AuthContext);
  const [typedAmount, setTypedAmount] = useState<number | null>(null);

  return (
    <Layout
      variant="dashboard"
      header="Celebrations"
      subheader={
        <div className="flex flex-row items-start justify-between max-w-5xl">
          <div className="text-neutral-dark text-base font-normal mt-2">
            Set up and manage automated celebrations here
          </div>
          <div>
            <Item variant="beta" />
          </div>
        </div>
      }
    >
      <div className="p-1 rounded-md max-w-5xl">
        <div className="my-4">
          <h2 className="text-2xl">
            {"Birthday Celebrations 🎂"}
          </h2>
          <div
          className={`justify-start p-1 max-w-5xl bg-gray-100 rounded-md`}
        >
          <div className="text-xl ml-2 my-2">
            {"A - General Rule (Quick Set Up)"}
          </div>
          <div className="text-lg ml-2 my-2 flex flex-row items-center">
            {"All of my team members will receive a "}
            <SInput
                    type="number"
                    lang="en-US"
                    placeholder="Amount"
                    step="1"
                    value={typedAmount || undefined}
                    onChange={(event) => {
                      setTypedAmount(Number(event?.target.value));
                    }}
                    className="h-12 w-24 bg-white rounded-md ml-2"
                  />
          </div>
        </div>
        </div>
        <div className="flex items-center justify-between mt-4 mb-2 sticky bottom-0 bg-white py-6 border-t border-neutral-light">
          <SButton
            variant="outline"
            className="hover:bg-gray-100 w-28"
            onClick={onReturnPressed}
          >
            Go back
          </SButton>
          <SButton
            onClick={() => {}} // Change later
            disabled={false} // Change later
            variant="default"
            className="disabled disabled:bg-gray-100 disabled:cursor-not-allowed w-56"
          >
            Continue
          </SButton>
        </div>
      </div>
    </Layout>
  );
};
