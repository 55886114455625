import { useState, useEffect } from "react";
import {useDropzone} from 'react-dropzone';
import { ImageUploaderTemplateProps } from "./template";
import { Auth, Storage } from 'aws-amplify';
import { useUser } from "@auth";
import { useUpdatePartner } from "@mutations";
import { Env, localEnv } from "@config";

export const useImageUploader = (): ImageUploaderTemplateProps => {
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const partner = useUser();
    const [logo, setLogo] = useState<any>(partner?.images?.logoUrl);
    const updatePartner = useUpdatePartner();

    const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
      accept: {
        'image/*': ['.jpeg', '.png']
      },
      // the file picker can do multiple files but it's set to one file only (will be the first result in the returned array)
      onDrop: acceptedFiles => {
        setSelectedFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
      },
      maxFiles: 1,
      noClick: true,
      noKeyboard: true
    });
  
    // when the fileUrlS3 value is returned from the query, will trigger the upload operation
    useEffect(() => {
      if (selectedFiles === undefined || selectedFiles.length == 0) {
        return;
      }
      (async function uploadProfilePhotoToS3(): Promise<void> {
        try {
          setLogo("");
          try {
            const user = await Auth.currentAuthenticatedUser();
            // eslint-disable-next-line prefer-destructuring
            const extension = selectedFiles[0].path.match(/\.[0-9a-z]+$/i)[0];
            let extensionType = "";
            switch(extension){
                case ".jpeg":
                case ".jpg":
                  extensionType = "image/jpeg";
                  break;
                case ".png":
                  extensionType = "image/png";
                  break;
                default:
                  throw("Wrong type selected. Needs to be jpg or png.");
            }

            const timestamp = Date.now();
            const pathPrefix = "logos/";
            const filename = `${pathPrefix}${partner?.id}-${timestamp}${extension}`;
            
            const fullPrefix = `https://coinmiles-premium-${localEnv === "dev" ? "dev" : "production"}.s3.ca-central-1.amazonaws.com/public/logos/`;
            const fullFilename = `${fullPrefix}${partner?.id}-${timestamp}${extension}`;

            // // save image direct to S3
            await Storage.put(filename, selectedFiles[0], {
              contentType: extensionType, // contentType is optional
            });

            try {
              // save image url to the partner
              const username = partner?.emailAddress || "";
              await updatePartner(
                { 
                  images: {
                    logoUrl: fullFilename,
                    backgroundUrl: "" 
                  } 
                },
                username,
                false
              );
              setLogo(fullFilename);
            } catch(error) {
              console.log("Cannot update logo: ", error);
            }
          } catch (error) {
            console.log("Error uploading file: ", error);
          }
        } catch (error) {
            console.log("Error uploading logo to S3: ", error)
        }
      })();
    }, [selectedFiles]);

  return {
    openFileSelector: open,
    selectedFiles,
    getRootProps,
    getInputProps,
    logo
  }
}
