
import { Button, Image, TextChip, ChipTypes } from "@atoms";

export type RewardCardTemplateProps = {
  title: string;
  isSelected: boolean;
  isAvailable: boolean;
  icon:  JSX.Element;
  onClick: any;
};

export const RewardCardTemplate = ({
  title,
  isSelected,
  isAvailable,
  icon,
  onClick
}: RewardCardTemplateProps): JSX.Element => {

  return (
    <button
      onClick={onClick}
      className={`flex flex-row h-44 w-52 pt-2 pl-5 pb-3 pr-3 bg-transparent border rounded-2xl hover:bg-neutral-lightest
      border-neutral-light hover:border-neutral-medium
      `}
    >
      <div className="flex flex-col w-full h-auto max-w-max justify-start">
        {isAvailable ?
          <div className="pl-28">
            <TextChip type={ChipTypes.activated} />
          </div>
          :
          <div className="ml-2">
            <div className="ml-16">
              <TextChip type={ChipTypes.comingSoon} />
            </div>
          </div>
        }
        <div className={`pt-6 w-10 h-10 self-start ${isSelected || isAvailable ? "opacity-100" : "opacity-50"}`}>
          {icon}
        </div>
        <div className="h-full w-full mt-12">
          <div className="text-left w-full">
            <div className="font-medium text-base text-neutral-darkest self-center">{title}</div>
          </div>
        </div>
      </div>
    </button>
  );
};