import { Image, Button } from "@atoms";
import { useNavigate } from "react-router-dom";

export const PendingPageTemplate = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen flex">
      <div className="relative hidden lg:block min-h-screen w-6/10">
        <div className="absolute top-6 left-6">
          <Image variant="logoWhite" />
        </div>
        <div style={{ backgroundColor: "#ed2828" }} className="w-full h-full">
          <Image variant="backgroundPhone" width={827} height={775} />
        </div>
      </div>
      <div className="relative w-full min-h-screen lg:w-4/10 lg:flex flex-col justify-center items-center">

        <div className="px-3 mb-6">
          <div className="mb-2">
            <h3 className="text-3xl font-black">Account Pending</h3>
          </div>
        </div>
        <div className="px-3 mb-6">
          <div className="mb-2">
            <h3 className="text-2xl font-medium">
              Your account is currently under review!
            </h3>
          </div>
        </div>
        <div className="mb-6 w-2/10 sm:mb-4 w-1/3">
          <Button
            variant="primary"
            label="Back to Sign In"
            stretch
            color="secondary-medium"
            hoverColor="secondary-dark"
            textColor="white"
            // brightness={500}
            onClick={() => navigate("/signin")}
          />
        </div>
      </div>
    </div>
  );
};
