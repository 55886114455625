import { useEffect, useState } from "react";
import { AirdropSelectAmountPageTemplateProps} from "./template";
import { useLocation, useNavigate } from "react-router";

export const useAirdropSelectAmountPage = (): AirdropSelectAmountPageTemplateProps => {
    const location = useLocation();
    const navigate = useNavigate();
    const combinedData = location.state?.combinedData;
    const [offerSelected] = [combinedData?.offerSelected];
    const [selectedUser] = [combinedData?.selectedUser];
    const [progress, setProgress] = useState(50);


    const navigateBackto = (to: string) => {
        navigate(to, {state: {selectedUser}});
    };

    const navigateTo = (to: string, data: any, message: string) => {
        const assembledData = {amount: data, message, combinedData };
        navigate(to, {state: {assembledData}})
    }
    

    useEffect(() => {
        console.log(JSON.stringify(offerSelected))
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => setProgress(75), 1000);
        return () => clearTimeout(timer);
      }, []);

    const onReturnPressed = () => {
        const to = "/dashboard/airdrop/select-offers";
        navigateBackto(to);
    }

    const onContinuePressed = (data: any, message: string) => {
        const to = "/dashboard/airdrop/confirm";
        navigateTo(to, data, message); 
    }

    return {
        offerSelected,
        progress,
        onReturnPressed,
        onContinuePressed,
    }
}