import { Button, Image } from "@atoms";

export type SentPageTemplateProps = {
  handleBackButtonClick: () => void;
  handleSendEmailClick: () => void;
};

export const SentPageTemplate = ({
  handleBackButtonClick,
  handleSendEmailClick,
}: SentPageTemplateProps): JSX.Element => {
  return (
    <div className="min-h-screen flex">
      <div className="relative hidden lg:block min-h-screen w-6/10">
        <div className="absolute top-6 left-6">
          <Image variant="logoWhite" />
        </div>
        <div style={{ backgroundColor: "#ed2828" }} className="w-full h-full">
          <Image variant="backgroundPhone" width={827} height={775} />
        </div>
      </div>
      <div className="flex-col relative w-full min-h-screen lg:w-4/10 lg:flex justify-center items-center px-10">
        <div className="mb-7">
          <Image variant="horns" />
        </div>
        <div className="mb-6">
          <h3 className="text-3xl font-black">Request Pending</h3>
        </div>
        <h3 className="text-lg text-gray-500 text-center w-3/4">
          Your request to join Coinmiles Partners was sent to our Partnership
          Team. We will notify you by email once it has been approved.
        </h3>
        <div className="flex mt-2">
          <h3 className="text-lg text-gray-500 mr-2">Questions?</h3>
          <Button
            variant="link"
            label="Send us an email!"
            fontWeight="normal"
            // color={"attention-medium"}
            color="red"
            brightness={400}
            fontSize="lg"
            onClick={handleSendEmailClick}
            underline
          />
        </div>
        <div className="mt-16">
          <Button
            variant="primary"
            label="Back to home"
            color="secondary-medium"
            hoverColor="secondary-dark"
            textColor="white"
            // brightness={500}
            onClick={handleBackButtonClick}
            size="sm"
          />
        </div>
      </div>
    </div>
  );
};
