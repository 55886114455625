import { useNavigate } from "react-router-dom";
import { useContext } from "react";

import { IconVariantType } from "@atoms";
import { useSignOut, AuthContext } from "@auth";
import { menuItems } from "./menu-data";
import { useUser, useAuth } from "@auth";

export type SideBarLogicInterface = {
  selectedPageIndex: number | undefined,
  menuItems: {
    label: string;
    to: string;
    icon: {
      variant: any; // IconVariantType;
    };
  }[];
  navigateToPage: (index: number, to: string) => void;
  signOut?: () => Promise<void>;
};

export const useSideBarTemplate = (): SideBarLogicInterface => {
  const { selectedPageIndex, setSelectedPageIndex, isPerksPageVisited } = useContext(AuthContext);
  const signOut = useSignOut();
  const navigate = useNavigate();
  const partner = useUser();

  const navigateToPage = (index: number, to: string) => {
    setSelectedPageIndex(index);
    navigate("/dashboard/" + to);
  }

  return {
    menuItems,
    selectedPageIndex,
    navigateToPage,
    signOut: async () => {
      await signOut();
      navigate("/signin");
    },
  };
};
