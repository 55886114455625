import { createContext } from "react";

import { Partner } from "@api";
import { SignInInput, SocialProvider } from "@auth";
import { useAuth } from "./auth-hooks";
import { BillingDetails, GiftCardOffer, PartnerStatsAll, PartnerAirdropTransaction } from "../types";

export interface AuthState {
  partner: Partner | null;
  cognitoUser: any;
  setCognitoUser: (user: any) => void;
  updatePartnerData: (partner: any) => void;
  signIn(input: SignInInput): Promise<string>;
  signOut(): Promise<void>;
  socialSignIn(provider: SocialProvider): Promise<void>;
  syncPartner: (partner: Partner) => Promise<void>;
  newPartnerInfo: any;
  setNewPartnerInfo: (value: any) => void;
  clientSecret: string;
  updateClientSecret: (secret: string) => void;
  customerId: string | undefined;
  stripePromise: any;
  isLoadingData: boolean;
  setIsLoadingData: (isLoading: boolean) => void;
  isSetupComplete: boolean | undefined; // this is true when all new partner setup steps are done
  calculateIsSetupComplete: () => void;
  selectedPageIndex: number | undefined;
  setSelectedPageIndex: (index: number) => void;
  isPerksPageVisited: boolean;
  setIsPerksPageVisited: (value: boolean) => void;
  billingDetails: BillingDetails,
  setBillingDetails: (value: BillingDetails) => void;
  giftCardOffers: GiftCardOffer[] | null;
  setGiftCardOffers: (value: GiftCardOffer[]) => void;
  partnerStatistics: PartnerStatsAll | undefined,
  setPartnerStatistics: (value: PartnerStatsAll) => void,
  partnerAirdropTransactions: PartnerAirdropTransaction[] | null;
  setPartnerAirdropTransactions: (value: PartnerAirdropTransaction[]) => void;
}

export const AuthContext = createContext<AuthState>({
  // This object merely defines the default values for the context vars
  partner: null,
  updatePartnerData: (partner) => {},
  cognitoUser: null,
  setCognitoUser: (user: any) => {},
  signIn: async (_input) => {
    return "";
  },
  socialSignIn: async (_input) => {
    /* Initial State */
  },
  signOut: async () => {
    /* Initial State */
  },
  syncPartner: async () => {
    /* Initial State */
  },
  newPartnerInfo: {},
  setNewPartnerInfo: () => {},
  clientSecret: "",
  updateClientSecret: (newSecret: string):void => {},
  customerId: "",
  stripePromise: undefined,
  isLoadingData: false,
  setIsLoadingData: (isLoading: boolean) => {},
  isSetupComplete: undefined,
  calculateIsSetupComplete: () => {},
  selectedPageIndex: undefined,
  setSelectedPageIndex: (index: number) => {},
  isPerksPageVisited: true, // not sure if we'll use this
  setIsPerksPageVisited: (value: boolean) => {},
  billingDetails: {
    invoices: null,
    creditCards: null,
    planAmount: null
  },
  setBillingDetails: (value: BillingDetails) => {},
  giftCardOffers: null,
  setGiftCardOffers: (value: GiftCardOffer[]) => {},
  partnerStatistics: undefined,
  setPartnerStatistics: (value: PartnerStatsAll) => {},
  partnerAirdropTransactions: null,
  setPartnerAirdropTransactions: (value: PartnerAirdropTransaction[]) => {},
});

interface AuthProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProps> = ({ children }) => {
  // invoking useAuth() will trigger the useEffect and reload all data, so this is probably the only place to use it
  const auth = useAuth();

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
};
