import { Image } from "@atoms";
import { Button } from "@shadcn"
import {
  Blog,
  BlogContent,
  BlogDescription,
  BlogHeader,
  BlogTitle,
  BlogTag,
  BlogDate,
  BlogImage
} from "@shadcn"
import { Input } from "@shadcn"
import { Label } from "@shadcn"

export type BlogCardTemplateProps = {
  image: string,
  date: string,
  blogTypeText: string,
  title: string;
  description: string;
  onClick: () => void;
};

export const BlogCardTemplate = ({
  image,
  date,
  blogTypeText,
  title,
  description,
  onClick
}: BlogCardTemplateProps): JSX.Element => {
  return (
    <button className="flex flex-col text-left transition transform scale-100 ease-in-out delay-0 duration-200 hover:scale-105" onClick={() => onClick()}> 
      <div className="ml-0 mr-5 my-2 w-64">
        <Blog className="w-[350px]">
          <Image variant="blog" imageType={image} />
          <BlogHeader>
            <div className="text-left w-full flex flex-row w-full justify-between items-center">
              <BlogTag>{blogTypeText}</BlogTag>
              <BlogDate>{date}</BlogDate>
            </div>
          </BlogHeader>
          <BlogContent>
            <BlogTitle>{title}</BlogTitle>
            <BlogDescription>{description}</BlogDescription>
          </BlogContent>
        </Blog>
      </div>
   </button>
  );
};
