import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PerksPageTemplateProps } from "./template";
import { rewardsData } from "./rewards-data";
import { perksData } from "./perks-data";
import { PerkTypes, RewardTypes, PagePaths } from "../../../types";
import { useUser } from "@auth";
import { InfoDetailsModalType } from "./item-details";

export const usePerksPage = (): PerksPageTemplateProps => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [selectedItemType, setSelectedItemType] = useState<RewardTypes | PerkTypes | null>(null);
  const [modalType, setModalType] = useState<InfoDetailsModalType>(InfoDetailsModalType.Rewards);
  const [isSelectedItemEnabled, setIsSelectedItemEnabled] = useState(false);
  const partner = useUser();
  const navigate = useNavigate();

  const navigateToPage = (to: string, index?: number) => {
    navigate("/dashboard/" + to);
  }

  // temporary sample data
  const [selectedRewards, setSelectedRewards] = useState({
    reward_btc: true,
    reward_cad: true,
    reward_amazon: false
  });

 // temporary sample data
  const [selectedPerks, setSelectedPerks] = useState({   
    perk_allCashback: true,
    perk_onlineCashback: true,
    perk_giftCardCashback: true,
    perk_travelCashback: true,
    perk_airdrop: false,
    perk_celebrations: false,
    perk_prepaidCard: false,
    perk_onTheGo: false,
    perk_roundUp: false,
    perk_slack: false,
    perk_24_7: false,
    perk_bitcoinPayroll: false,
    perk_customerRewards: false,
  });

  const openInfoModal = () => {
    setIsInfoModalOpen(true);
  }

  const closeInfoModal = () => {
    setIsInfoModalOpen(false);
  }

  const sendEmail = (type: InfoDetailsModalType, serviceName = "") => {
    let subject
    let optionType;

    if (type === InfoDetailsModalType.Rewards) {
      subject = "Coinmiles Partner reward request";
      optionType = "reward";
    } else {
      subject = "Coinmiles Partner perks request";
      optionType = "perk";
    }
    const body = `Hey, please add me (${partner?.organization} - ${partner?.emailAddress}) to the waitlist for the ${serviceName} ${optionType} option. %0D%0A%0D%0A Optional feedback on this upcoming feature:`;

    window.open(`mailto:${partner?.emailAddress}?subject=${subject}&body=${body}`);
  }

  const closeAndAccept = () => {
    if (modalType === InfoDetailsModalType.Rewards) {
      const type = selectedItemType as RewardTypes;
      const data = rewardsData?.find(item => item.type === type);
      const isAvailable = data?.isAvailable;
       // note: temporarily disable this so that Bitcoin is always active 
      // if (rewardsData?.find(item => item.type === type)?.isAvailable) {
      if (!isAvailable) {
        const serviceName = data?.title;
        sendEmail(InfoDetailsModalType.Rewards, serviceName);
      } else if (false) {
        setSelectedRewards({...selectedRewards, [type]: selectedRewards[type] = !selectedRewards[type]});
      }
    } else {
      const type = selectedItemType as PerkTypes;
      const data = perksData?.find(item => item.type === type);
      const isAvailable = data?.isAvailable;
      const isIncluded = data?.includedInPackage;

      if (isAvailable && !isIncluded) {
        setSelectedPerks({...selectedPerks, [type]: selectedPerks[type] = !selectedPerks[type]});
      } else if (!isAvailable) {
        const serviceName = data?.title;
        sendEmail(InfoDetailsModalType.Perks, serviceName);
      }
    }
    setIsInfoModalOpen(false);
  }

  const onClickReward = (type: RewardTypes) => {
    setModalType(InfoDetailsModalType.Rewards);
    setSelectedItemType(type);
    setIsSelectedItemEnabled(selectedRewards[type]);

    if (rewardsData?.find(item => item.type === type)?.isAvailable) {
      openInfoModal(); // todo: unnecessary, should remove...
    } else {
      openInfoModal();
    }
  }

  const onClickPerk = (type: PerkTypes) => {

    if (type === PerkTypes.perk_airdrop) {
      const to = PagePaths.airdrop + "/main";
      navigateToPage(to);
      // open advanced details modal
    }
    // Uncomment below out to be able to view Celebrations frontend skeleton left
    /* else if (type === PerkTypes.perk_celebrations) {
      const to = PagePaths.celebrations + "/main";
      navigateToPage(to);

    } */
    else if (type === PerkTypes.perk_allCashback) {
      const to = PagePaths.cashbackRewards;
      navigateToPage(to);
    } else {
      setModalType(InfoDetailsModalType.Perks);
      setSelectedItemType(type);
      setIsSelectedItemEnabled(selectedPerks[type]);

      if (perksData?.find(item => item.type === type)?.isAvailable) {
        openInfoModal();
      } else {
        openInfoModal();
      }
    }
  }

  return {
    onClickReward,
    onClickPerk,
    selectedRewards,
    selectedPerks,
    isInfoModalOpen,
    openInfoModal,
    closeInfoModal,
    closeAndAccept,
    selectedItemType,
    isSelectedItemEnabled,
    modalType,
    navigateToPage
  };
};
