import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";

// import { Auth } from "aws-amplify";
import { Auth } from "@aws-amplify/auth";
import { createAuthLink, AUTH_TYPE, AuthOptions } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

import { Env } from "@config";

//import awsConfig from "@aws";

interface AuthLink {
  url: string;
  region: string;
  auth: AuthOptions;
  disableOffline?: boolean;
}

// Config Objects
const awsConfig = {
  graphQlEndpoint: Env.APP_SYNC_GRAPHQL_ENDPOINT,
  region: Env.REGION,
};

const userPoolAuthConfig: AuthLink = {
  url: awsConfig.graphQlEndpoint,
  region: awsConfig.region,
  disableOffline: true,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => {
      try {
        return (await Auth.currentSession()).getIdToken().getJwtToken();
      } catch {
        return "";
      }
    },
  },
};

const createLink = (config: AuthLink): ApolloLink => {
  const httpLink = createHttpLink({ uri: config.url });

  return from([
    createAuthLink({
      url: config.url,
      region: config.region,
      auth: config.auth,
    }),
    createSubscriptionHandshakeLink(config.url, httpLink),
  ]);
};

const UserPoolClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: createLink(userPoolAuthConfig),
  cache: new InMemoryCache({}),
  connectToDevTools:
    (process.env.NODE_ENV as "dev" | "production") === "dev" ? true : false,
});

export { UserPoolClient };
export { ApolloProvider } from "@apollo/client";
