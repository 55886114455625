import { useEffect, useState } from "react";
import { AirdropCompletePageTemplateProps } from "./template";
import { useAirdropGiftCard } from "@mutations";
import { useGetPartnerPaymentMethods } from "@queries";
import JSConfetti from 'js-confetti'

export const useAirdropCompletePage = (): AirdropCompletePageTemplateProps => {
    const { getPartnerPaymentMethods } = useGetPartnerPaymentMethods();
    const airdropGiftCard = useAirdropGiftCard();
    const [progress, setProgress] = useState(75);
    const [confetti, setConfetti] = useState<boolean>(true);

    let jsConfetti: any;

    useEffect(() => {
        const timer = setTimeout(() => setProgress(100), 1000);
        return () => clearTimeout(timer);
      }, []);

    // Isolated useEffect to get the partner's payment methods
    useEffect(() => {
        getPartnerPaymentMethods();
        jsConfetti = new JSConfetti();
    }, []);

    useEffect(() => {
        if (jsConfetti && confetti) {
            jsConfetti.addConfetti({
                emojis: ['🚀', '💥', '✨', '🎁'],
             });
        }
      }, [confetti]);

    return {
        progress,
        airdropGiftCard
    }
}