import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import FlagCanada1x from "./flag-canada-1x.png";
import FlagCanada2x from "./flag-canada-2x.png";

export const FlagCanadaImage = ({
  width,
  height,
  highResolution,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <div style={{ height, width }}>
      <ImageTemplate
        {...props}
        imgSrc={highResolution ? FlagCanada2x : FlagCanada1x}
      />
    </div>
  );
};
