export type EnvironmentOption = "dev" | "prod";

interface Env {
  [key: string]: string;
}

const universalVariables = {
  DEFAULT_COUNTRY_CODE: "CA",
  DEFAULT_LANGUAGE: "en",
  REGION: "ca-central-1",
};

const specificVariables: { [key: string]: Env } = {
  dev: {
    APP_SYNC_GRAPHQL_ENDPOINT:
      "https://t3igniphync5fmahuhsc3by2ui.appsync-api.ca-central-1.amazonaws.com/graphql",
    COGNITO_IDENTITY_POOL_ID:
      "ca-central-1:06d73ef4-f057-4cb8-a4f5-6e99d6921734",
    COGNITO_USER_POOL_ID: "ca-central-1_oLiTyj1Y7",
    COGNITO_USER_POOL_WEB_CLIENT_ID: "7gaubehnvucmoucbqvevumut71",
    COGNITO_OAUTH_DOMAIN: "partners-auth-dev.coinmiles.io",
    SIGNIN_URL: `${window.location.origin}/dashboard/welcome/`,  // This is a callback url
    SIGNOUT_URL: `${window.location.origin}/`,  // This is a redirect url
    S3_BUCKET: "coinmiles-affiliate-offers-dev.s3.ca-central-1.amazonaws.com",
    STRIPE_API_KEY_CA: "pk_test_vWsProaLbBEtZamHCnpfyT4I0072KANIph",
    STRIPE_API_KEY_US:
      "pk_test_51MCqyxDpJQ62T54nsWwFxQsKXcnUO0Gll57tQ3hU1NHnVccX38Xp2qIbrcs5GoF8ddXXUAablal90mYEH6gvKNyC00BohAjQi7",
    S3_IMAGE_BUCKET: "coinmiles-premium-dev"
  },
  prod: {
    APP_SYNC_GRAPHQL_ENDPOINT:
      "https://sqxulg3gfzbwne36l2mrmojig4.appsync-api.ca-central-1.amazonaws.com/graphql",
    COGNITO_IDENTITY_POOL_ID:
      "ca-central-1:f5b4a9f2-3bd1-4b9f-951c-7b5cf6ad3327",
    COGNITO_USER_POOL_ID: "ca-central-1_BLVecUoeX",
    COGNITO_USER_POOL_WEB_CLIENT_ID: "61lspr1g4rp42v9kkpo09s5t3d",
    COGNITO_OAUTH_DOMAIN: "coinmilespartnersauthdev-production.auth.ca-central-1.amazoncognito.com",
    SIGNIN_URL: "https://partners.coinmiles.io/dashboard/welcome/",  // This is a redirect url
    SIGNOUT_URL: "https://partners.coinmiles.io/",  // This is a redirect url
    S3_BUCKET:
      "coinmiles-affiliate-offers-production.s3.ca-central-1.amazonaws.com",
    STRIPE_API_KEY_CA: "pk_live_5tTAiLalAWFiyZL0O7Qmm9CF00ZlDkGYi9",
    STRIPE_API_KEY_US:
      "pk_live_51MCqyxDpJQ62T54nw4fpimSafQBBtaK9Sr7DFcuQw4C1NXvIEndjtTXQI8ElEBcTHi8pD6DmfTGbU51ZKBjKs4AT00zCt8Da7x",
    S3_IMAGE_BUCKET: "coinmiles-premium-production"
  },
};

// Set desired running environment here
export const localEnv: EnvironmentOption = "prod";

export const isProductionBetaVersion = false; // making this true puts a "Beta Version" tag on the prod UI

const getEnv = (): Env => {
  return { ...universalVariables, ...specificVariables[localEnv] };
};

const env: Env = getEnv();

export { env as Env };
