import { useState, useEffect, useContext } from "react";
import { TeamPageTemplateProps } from "./template";
import { useUser } from "@auth";
import { useFormik, FormikErrors } from "formik";
import { useAddUserToPremiumPartner } from "@mutations";
import { useGetPartnerPaymentMethods } from "@queries";
import { AuthContext } from "@auth";

export interface EmployeeFormValues {
  firstName: string;
  lastName: string;
  emailAddress: string;
  employmentStartDate: string;
  birthday: string;
}

/* Below type can be moved to a more appropriate types folder later and have more attributes added */
export type EmployeeAdditionProps = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  employmentStartDate: string;
  birthday: string;
};

export const useTeamPage = (): TeamPageTemplateProps => {
  const partner = useUser();
  const addUser = useAddUserToPremiumPartner();
  const { billingDetails, isLoadingData, setIsLoadingData } = useContext(AuthContext);
  const { getPartnerPaymentMethods } = useGetPartnerPaymentMethods();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [emailNotAlreadyAdded, setEmailNotAlreadyAdded] = useState(true);

  const isAddressSubmitted =
    partner?.address !== null && partner?.address !== undefined;
  const isCreditCardAdded = billingDetails?.creditCards === null || (billingDetails?.creditCards && billingDetails.creditCards.length > 0) ? true : false;
  
  useEffect(() => {
    // load the list of linked payment methods, only used to determine if the Add Team Member button is enabled
    getPartnerPaymentMethods();
  }, []);

  const onEditTeamMemberClicked = () => {
    alert("Modal to edit member information should appear");
  };

  const onManageNumberOfSeatsClicked = () => {};

  function closeModal() {
    setModalIsOpen(false);
  }

  useEffect(() => {
    if (!isLoadingData) closeModal();
  }, [isLoadingData]);

  const validate = (
    values: EmployeeFormValues,
  ): FormikErrors<EmployeeFormValues> => {
    const errors: FormikErrors<EmployeeFormValues> = {};
    if (!values.emailAddress) {
      errors.emailAddress = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)
    ) {
      errors.emailAddress = "Invalid email address";
    }
    if (!values.firstName) {
      errors.firstName = "First name is required";
    }
    if (!values.lastName) {
      errors.lastName = "Last name is required";
    }
    return errors;
  };

  const { values, errors, handleChange, handleSubmit, setFieldError } =
    useFormik<EmployeeFormValues>({
      initialValues: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        employmentStartDate: "",
        birthday: "",
      },
      validate,
      validateOnChange: false,
      onSubmit: async (values: EmployeeAdditionProps) => {
        setIsLoadingData(true);
        try {
          console.log(values.firstName, values.lastName, values.emailAddress);
          const isUserAlreadyAdded = partner?.premiumSettings?.[0]?.users?.find(
            (u) => u.emailAddress === values.emailAddress,
          );
          if (isUserAlreadyAdded) {
            throw new Error("UserAlreadyAdded");
          }
          addUser(values.firstName, values.lastName, values.emailAddress, billingDetails?.creditCards?.[0]?.paymentId ?? "", values.employmentStartDate, values.birthday);
          return;
        } catch (error: any) {
          if (error.message === "UserAlreadyAdded") {
            setFieldError(
              "emailAddress",
              "This email address has already been added as a team member!",
            );
            setEmailNotAlreadyAdded(false);
          } else {
            console.log(error);
            closeModal();
          }
        }
      },
    });

  const onAddTeamMemberClicked = () => {
    if (values) {
      values.firstName = "";
      values.lastName = "";
      values.emailAddress = "";
    }
    errors.emailAddress = ""; // Reset the possible email already added error
    setModalIsOpen(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e);

  return {
    premiumUsers: partner?.premiumSettings?.[0]?.users || [],
    onAddTeamMemberClicked,
    onEditTeamMemberClicked,
    onManageNumberOfSeatsClicked,
    partner,
    handleInputChange,
    errors,
    values,
    handleSubmit,
    modalIsOpen,
    closeModal,
    isAddressSubmitted,
    isCreditCardAdded,
    creditCards: billingDetails?.creditCards,
    isLoadingData,
    setIsLoadingData,
    emailNotAlreadyAdded
  };
};
