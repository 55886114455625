import { useUser } from "@auth";
import { AirdropSelectOfferPageTemplateProps } from "./template";
import { useGetAllGiftCardOffers } from "@queries";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { AuthContext } from "../../../../auth/auth-context";

export const useAirdropSelectOfferPage =
  (): AirdropSelectOfferPageTemplateProps => {
    const partner = useUser();
    const { getAllGiftCardOffers } = useGetAllGiftCardOffers();
    const { giftCardOffers } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [progress, setProgress] = useState(25);
    const selectedUser = location.state?.selectedUser;

    useEffect(() => {
      getAllGiftCardOffers();
    }, []);

    useEffect(() => {
      const timer = setTimeout(() => setProgress(50), 1000);
      return () => clearTimeout(timer);
    }, []);

    const navigateTo = (to: string, data: any) => {
      const combinedData = { offerSelected: data, selectedUser}
      navigate(to, { state: { combinedData } });
    };

    const navigateBackTo = (to: string) => {
        navigate(to);
    }

    const onContinuePressed = (data: any) => {
        const to = "/dashboard/airdrop/select-amount";
        navigateTo(to, data);
    };

    const onReturnPressed = () => {
        navigateBackTo("/dashboard/airdrop/select-members");
    }

    return {
      premiumUsers:
        partner?.premiumSettings?.[0]?.users.filter(
          (item) => item.userId !== "",
        ) || [],
      giftCardOffers,
      progress,
      onContinuePressed,
      onReturnPressed,
    };
  };
