import { useTextareaInputTemplate } from "./template-logic";

export type TextareaInputTemplateProps = {
  placeholder: string;
  value: string;
  onChange: (e: any) => void;
  name: string;
  errorMessage: string | undefined;
  height?: number;
};

export const TextareaInputTemplate = ({
  placeholder,
  value,
  name,
  errorMessage,
  onChange,
  height = 64,
}: TextareaInputTemplateProps): JSX.Element => {
  const {
    isActive,
    toggleFocus,
    toggleBlur,
    inputRef,
  } = useTextareaInputTemplate(value);
  return (
    <div className="w-full">
      <div
        className={`w-full relative px-3 pt-4 pb-2 border ${
          errorMessage ? "border-orange-600" : "border-gray-100"
        } ${
          errorMessage ? "bg-orange-50" : "bg-gray-50"
        } rounded-md text-input-animation`}
      >
        <textarea
          autoCapitalize="none"
          autoComplete="none"
          autoCorrect="none"
          name={name}
          onFocus={toggleFocus}
          onBlur={() => toggleBlur(value)}
          onChange={onChange}
          ref={inputRef}
          value={isActive || value !== "" ? value : ""}
          style={{ height }}
          className="w-full border-none outline-none font-normal resize-none bg-gray-50"
        />
        <div
          className={`absolute ${isActive || value !== "" ? "is-focused" : "not-focused"} h-8 text-neutral-darkest`}
          onClick={toggleFocus}
        >
          {placeholder}
        </div>
      </div>
      {errorMessage ? (
        <div className="text-left text-xs text-orange-600 ml-2 mt-1">
          {errorMessage}
        </div>
      ) : null}
    </div>
  );
};
