import { FormEvent } from "react";
import { Button, Input, Image } from "@atoms";

export interface FormValues {
  firstName: string;
  lastName: string;
  emailAddress: string;
  password: string;
  confirmPassword: string;
}

export interface FormErrorValues {
  firstName: string | undefined;
  lastName: string | undefined;
  emailAddress: string | undefined;
  password: string | undefined;
  confirmPassword: string | undefined;
}

export type SignUpPageTemplateProps = {
  values: FormValues;
  errors: FormErrorValues;
  loading: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSignInRedirect: () => void;
  handleSignUpWithGoogle: () => void;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
};

export const SignUpPageTemplate = ({
  values,
  errors,
  loading,
  handleInputChange,
  handleSignInRedirect,
  handleSignUpWithGoogle,
  handleSubmit,
}: SignUpPageTemplateProps): JSX.Element => {
  return (
    <div className="min-h-screen flex">
      <div className="relative hidden lg:block min-h-screen w-6/10">
        <div className="absolute top-6 left-6">
          <Image variant="logoWhite" />
        </div>
        <div style={{ backgroundColor: "#ed2828" }} className="w-full h-full">
          <Image variant="backgroundPhone" width={827} height={775} />
        </div>
      </div>
      <div className="relative w-full min-h-screen lg:w-4/10 lg:flex justify-center items-center">
        <div className="lg:hidden px-6 pt-6 pb-12 sm:pb-24">
          <Image variant="logoColour" />
        </div>
        <form
          className="text-center flex flex-col items-center max-w-xs mx-auto px-4"
          onSubmit={handleSubmit}
        >
          <div className="px-3 mb-6">
            <div className="mb-2">
              <h3 className="text-2xl sm:text-3xl font-black">
                Sign up to continue
              </h3>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <div className="w-full">
              <div className="mb-2">
                <Input
                  variant="text"
                  name="firstName"
                  onChange={handleInputChange}
                  placeholder="First name"
                  value={values.firstName}
                  errorMessage={errors.firstName}
                />
              </div>
              <div className="mb-2">
                <Input
                  variant="text"
                  name="lastName"
                  onChange={handleInputChange}
                  placeholder="Last name"
                  value={values.lastName}
                  errorMessage={errors.lastName}
                />
              </div>
              <div className="mb-2">
                <Input
                  variant="text"
                  name="emailAddress"
                  onChange={handleInputChange}
                  placeholder="Email address"
                  value={values.emailAddress}
                  errorMessage={errors.emailAddress}
                />
              </div>
              <div className="mb-2">
                <Input
                  variant="password"
                  name="password"
                  onChange={handleInputChange}
                  placeholder="Password"
                  value={values.password}
                  errorMessage={errors.password}
                />
              </div>
              <div className="mb-8">
                <Input
                  variant="password"
                  name="confirmPassword"
                  onChange={handleInputChange}
                  placeholder="Confirm password"
                  value={values.confirmPassword}
                  errorMessage={errors.confirmPassword}
                />
              </div>
            </div>
            <div className="w-full mb-4">
              <div>
                <Button
                  type="submit"
                  variant="primary"
                  label="Continue with Email"
                  stretch
                  color="secondary-medium"
                  hoverColor="secondary-dark"
                  textColor="white"
                  brightness={500}
                  loading={loading}
                />
              </div>
              <div className="my-3">or</div>
              <div>
                <Button
                  variant="primary"
                  onClick={handleSignUpWithGoogle}
                  label="Sign up with Google"
                  stretch
                  color="black"
                  textColor="white"
                  hoverColor="neutral-dark"
                  withIcon="google"
                />
              </div>
            </div>
          </div>
          <div className="flex mb-16">
            <p className="font-md font-normal mr-2 whitespace-nowrap">
              Are you already a member?
            </p>
            <Button
              variant="link"
              label="Log in"
              fontWeight="normal"
              color={"neutral-darkest"}
              fontSize="base"
              onClick={handleSignInRedirect}
              underline
            />
          </div>
        </form>
      </div>
    </div>
  );
};
