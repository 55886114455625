import {
  BrightnessType,
  ColorType,
  FontSizeType,
  FontWeightType,
} from "@theme/types";
import {
  colorMixin,
  fontSizeMixin,
  fontWeightMixin,
  underlineMixin,
} from "@theme/mixins";

export type LinkButtonTemplateProps = {
  fontWeight: FontWeightType;
  color: string;
  brightness?: BrightnessType;
  underline?: boolean;
  fontSize: FontSizeType;
  onClick: () => void;
  label: string;
};

export const LinkButtonTemplate = ({
  fontWeight,
  color,
  underline,
  fontSize,
  onClick,
  brightness,
  label,
}: LinkButtonTemplateProps): JSX.Element => {
  return (
    <button
      onClick={onClick}
      className={`bg-transparent border-none underline text-${color} text-${fontWeight} text-${fontSize}`}
    >
      {label}
    </button>
  );
};
