// global styles

export const styles = {
  tableRow: {
    firstName: "w-1/6",
    lastName: "w-1/6",
    emailAddress: "w-1/3",
    accountStatus: "w-1/16",
    employmentStartDate: "w-1/3",
    birthday: "w-1/3",
    buttons: "w-1/8"
  },
  generalModal: {
    content: {
      top: 350,
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      borderStyle: "solid",
      borderWidth: 0,
      borderRadius: 16
    },
    overlay: {
      background: "rgba(32,26,21,0.7)"
    }
  }
}
