import { PaymentElement } from '@stripe/react-stripe-js';
import ReactModal from 'react-modal';

import { Layout } from "@layouts";
import { Card } from "@molecules";
import { Button, Icon } from "@atoms";
import { BillingPageTemplateProps } from "./";
import { styles as globalStyles } from "../../../styles/styles";
import { subscriptionPlanData } from "./subscription-data";
import { SubscriptionPlansTypes } from "../../../types";

export const BillingPageTemplate = ({
  modalIsOpen,
  closeModal,
  onPressAddCard,
  onPressDownloadInvoice,
  selectedCardIndex,
  cardDataList,
  invoices,
  handleSubmit,
  succeeded,
  processing,
  disabled,
  error,
  isAddressSubmitted,
  handleUpgradeClick,
  handleSendEmailClick,
  planAmount
}: BillingPageTemplateProps): JSX.Element => {

  // const stripeCardStyle = {
  //   style: {
  //     base: {
  //       color: "#32325d",
  //       fontFamily: 'Arial, sans-serif',
  //       fontSmoothing: "antialiased",
  //       fontSize: "16px",
  //       "::placeholder": {
  //         color: "#32325d"
  //       }
  //     },
  //     invalid: {
  //       fontFamily: 'Arial, sans-serif',
  //       color: "#fa755a",
  //       iconColor: "#fa755a"
  //     }
  //   }
  // };

  const isButtonDisabled = processing || disabled || succeeded;

  return (
    <Layout
      variant="dashboard"
      header="Subscription"
      subheader={
        <div className="text-neutral-dark text-base font-normal mt-2">
          Manage your billing here
        </div>
      }
    >
      <div className="pb-8 pt-4">
        <div>
          {!isAddressSubmitted && 
            <div className="pb-6">
              <div className="text-attention-darkest mt-2 p-3 pl-6 rounded-xl bg-attention-lightest w-1/2">
                🚨 First go to the Organization page and add your address!
              </div>
            </div>
          }
          <div className="flex flex-row flex-wrap">
            <div className="pr-12 pb-6">
              <Card
                variant="paymentMethod"
                title="Credit Cards"
                buttonText={`${cardDataList !== null && cardDataList.length > 0 ? "Update Payment" : "Add Payment"}`}
                icon={<Icon variant="creditCard" size={25}/>}
                iconSubtitle="No Cards Added yet"
                onClick={isAddressSubmitted ? onPressAddCard :  undefined}
                hasTransactionFee={false}
                hasAddButton={true}
                data={cardDataList}
                selectedIndex={selectedCardIndex}
              />
              <div>
                <Card
                  variant="invoices"
                  title="Invoices"
                  icon={<div></div>}
                  iconSubtitle="You don't have any invoices yet"
                  onClick={onPressDownloadInvoice}
                  hasAddButton={false}
                  data={invoices}
                />
              </div>
            </div>
            <div className="mr-5 w-64">
              <Card
                variant="subscription"
                planType={SubscriptionPlansTypes.basic}
                planAmount={planAmount}
                subscriptionPlanData={subscriptionPlanData[SubscriptionPlansTypes.basic]}
                // todo: add the plan pricing as a variable
                onClick={() => handleUpgradeClick()}
                onSendEmail={() => handleSendEmailClick()}
              />
            </div>
          </div>
        </div>
        <div>
          {modalIsOpen ? (
            <ReactModal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={globalStyles.generalModal}
              shouldCloseOnOverlayClick={true}
              ariaHideApp={false}
            >
              <div className="font-semibold text-2xl flex flex-row justify-center py-2">Add a Card</div>
              <form className="p-2" id="payment-form" onSubmit={handleSubmit}>
                <div className="flex flex-col justify-center items-center">
                  <PaymentElement />
                  <div className="mt-3 w-56">
                    <Button
                      onClick={isButtonDisabled ? undefined : handleSubmit}
                      variant="primary"
                      label={processing ? "Registering..." : "Register Card"}
                      textWeight={"semibold"}
                      color="primary-medium"
                      stretch
                    />
                  </div>
                  {/* Show any error that happens when processing the card add */}
                  {error && (
                    <div className="card-error" role="alert">
                      {error}
                    </div>
                  )}
                  {/* Show a success message upon completion */}
                  <p className={succeeded ? "result-message" : "result-message hidden"}>
                    Card added!
                  </p>
                </div>
              </form>
            </ReactModal>
          ) : null}        
        </div>
      </div>
    </Layout>
  );
};
