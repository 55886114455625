import { Button, Image, TextChip, ChipTypes } from "@atoms";

export type PerkCardTemplateProps = {
  title: string;
  text: string;
  isSelected: boolean;
  isAvailable: boolean;
  icon: JSX.Element;
  onClick: any;
  includedInPackage: boolean;
  isBeta?: boolean;
};

export const PerkCardTemplate = ({
  title,
  text,
  isSelected,
  isAvailable,
  icon,
  onClick,
  includedInPackage,
  isBeta,
}: PerkCardTemplateProps): JSX.Element => {
  return (
    <button
      onClick={onClick}
      className={`flex flex-row w-52 h-56 pt-2 pl-5 pb-3 pr-3 bg-transparent border border-neutral-light rounded-2xl hover:bg-neutral-lightest
      border-neutral-light hover:border-neutral-medium
      `}
    >
      <div className="flex flex-col w-full h-auto max-w-max items-end justify-start">
        {isAvailable ? (
          true ? ( //!includedInPackage ?
            <div>
              <TextChip type={ChipTypes.activated} />
            </div>
          ) : null
        ) : isBeta ? (
          <div>
            <TextChip type={ChipTypes.beta} />
          </div>
        ) : (
          <div>
            <TextChip type={ChipTypes.comingSoon} />
          </div>
        )}
        <div className="flex flex-row border-0 p-2 mt-8 rounded-full bg-neutral-light self-start">
          {icon}
        </div>
        <div className="h-full w-full mt-6">
          <div className="text-left w-full">
            <div className="my-1 w-40 font-medium text-base leading-5 text-neutral-darkest">
              {title}
            </div>
            <div className="font-normal text-sm leading-5 text-neutral-darkest">
              {text}
            </div>
          </div>
        </div>
      </div>
    </button>
  );
};
