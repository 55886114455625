import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import ImageTestBlog from "./blog01.png"
import ImageHowToEarn from "./how-to-earn-rewards-with-coinmiles.png";
import ImageActivatePerks from "./boost-activate-your-perks.png"; 

export const BlogImage = ({
  height = 160,
  width = "100%",
  imageType,
  ...props
}: ImageProps): JSX.Element => {

  let image;
  switch (imageType) {
    case "howToEarn":
      image = ImageHowToEarn;
      break;
    case "boostActivatePerks":
      image = ImageActivatePerks;
      break;
    case "test":
      image = ImageTestBlog;
      break; 
    default:  
      image = "";
      break; 
  }

  return (
    <div className={`w-full h-40 rounded-xl overflow-hidden`}> 
      <ImageTemplate {...props} imgSrc={image} />
    </div>
  );
};
