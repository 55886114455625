import { ImageVariantType } from "@atoms";
import { PagePaths } from "../../../types";

export const todos: {
  variant: ImageVariantType;
  title: string;
  description: string;
  navigationString: string;
}[] = [
  {
    variant: "businessInfo",
    title: "Set up your organization",
    description: "",
    navigationString: PagePaths.organization,
  },
  {
    variant: "paymentInfo",
    title: "Add your billing information",
    description: "",
    navigationString: PagePaths.billing,
  },
  {
    variant: "perksProgram",
    title: "Set up your Perks Program",
    description: "",
    navigationString: PagePaths.perks,
  },  
  {
    variant: "newCampaign",
    title: "Invite your first team member!",
    description: "",
    navigationString: PagePaths.team,
  },
];

const BlogTypes = {
  bitcoin: "Bitcoin & Crypto"
}

export const blogData: {
  image: string,
  date: string,
  blogTypeText: string,
  title: string;
  description: string;
  blogUrl: string;
}[] = [
  {
    image: "",
    date: "2022-04-30",
    blogTypeText: BlogTypes.bitcoin,
    title: "Bitcoin 101 - What you need to know",
    description: "Bitcoin seems complicated, but don't worry.",
    blogUrl: "https://coinmiles.io/blog/bitcoin-101-what-you-need-to-know",
  },
  {
    image: "",
    date: "2022-04-30",
    blogTypeText: BlogTypes.bitcoin,
    title: "Bitcoin 101 - What you need to know",
    description: "Bitcoin seems complicated, but don't worry.",
    blogUrl: "https://coinmiles.io/blog/bitcoin-101-what-you-need-to-know",
  },
  {
    image: "",
    date: "2022-04-30",
    blogTypeText: BlogTypes.bitcoin,
    title: "Bitcoin 101 - What you need to know",
    description: "Bitcoin seems complicated, but don't worry.",
    blogUrl: "https://coinmiles.io/blog/bitcoin-101-what-you-need-to-know",
  },
  {
    image: "",
    date: "2022-04-30",
    blogTypeText: BlogTypes.bitcoin,
    title: "Bitcoin 101 - What you need to know",
    description: "Bitcoin seems complicated, but don't worry.",
    blogUrl: "https://coinmiles.io/blog/bitcoin-101-what-you-need-to-know",
  },
  {
    image: "",
    date: "2022-04-30",
    blogTypeText: BlogTypes.bitcoin,
    title: "Bitcoin 101 - What you need to know",
    description: "Bitcoin seems complicated, but don't worry.",
    blogUrl: "https://coinmiles.io/blog/bitcoin-101-what-you-need-to-know",
  },
  {
    image: "",
    date: "2022-04-30",
    blogTypeText: BlogTypes.bitcoin,
    title: "Bitcoin 101 - What you need to know",
    description: "Bitcoin seems complicated, but don't worry.",
    blogUrl: "https://coinmiles.io/blog/bitcoin-101-what-you-need-to-know",
  },
];

export const campaigns = [];
