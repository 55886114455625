import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import Background1x from "./payment-info-1x.png";
import Background2x from "./payment-info-2x.png";

export const PaymentInfoImage = ({
  height,
  width,
  highResolution,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <div style={{ height, width }}>
      <ImageTemplate
        {...props}
        imgSrc={highResolution ? Background1x : Background2x}
      />
    </div>
  );
};
