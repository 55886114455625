import { blogData } from "./card-data";
import { PagePaths } from "../../../types";

export type BlogSectionLogicInterface = {
  onClickBlog: (blogUrl: string) => void;
  blogData: any;
};

export const useBlogSectionTemplate = ({page} : {page: string}): BlogSectionLogicInterface => {
  const onClickBlog = (blogUrl: string): void => {
    window.open(blogUrl, "_blank");
  }

  let data;
  switch(page) {
    case "home":
      data = blogData[PagePaths.home]
      break;
    case "cashbackRewards":
      data = blogData[PagePaths.cashbackRewards]
      break;
  }

  return {
    onClickBlog,
    blogData: data
  };
};
