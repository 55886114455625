import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import AmazonLogo1x from "./amazon-logo-1x.png";
import AmazonLogo2x from "./amazon-logo-2x.png";

export const AmazonLogoImage = ({
  width,
  height,
  highResolution,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <div style={{ height, width }}>
      <ImageTemplate
        {...props}
        imgSrc={highResolution ? AmazonLogo2x : AmazonLogo1x}
      />
    </div>
  );
};
