import { SignUpPage } from "./root";
import { ConfirmPage } from "./confirm";
import { SentPage } from "./sent";
import { WelcomePage } from "./welcome";

export type PageProps = { variant: "root" | "confirm" | "sent" | "welcome" };

export const Page = (props: PageProps): JSX.Element => {
  switch (props.variant) {
    case "root":
      return <SignUpPage />;
    case "confirm":
      return <ConfirmPage />;
    case "sent":
      return <SentPage />;
    case "welcome":
      return <WelcomePage />;
    default:
      throw new Error(`[SIGN UP ERROR]: Invalid variant provided`);
  }
};
