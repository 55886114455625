import { Layout } from "@layouts";
import { Card, Item } from "@molecules";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "@auth";
import { todos } from "./card-data";
import { Image, ImageVariantType } from "@atoms";

export type CelebrationsMainPageTemplateProps = {
  premiumUsers: any[];
  navigateToPage: (index: number, to: string) => void;
};

export const CelebrationsMainPageTemplate = ({
  premiumUsers,
  navigateToPage,
}: CelebrationsMainPageTemplateProps): JSX.Element => {
  const { partner } = useContext(AuthContext);

  return (
    <Layout
      variant="dashboard"
      header="Celebrations"
      subheader={
        <div className="flex flex-row items-start justify-between max-w-5xl">
          <div className="text-neutral-dark text-base font-normal mt-2">
            Set up and manage automated celebrations here
          </div>
          <div>
            <Item variant="beta" />
          </div>
        </div>
      }
    >
      <div className="p-1 rounded-md max-w-5xl">
        <div className="my-4">
          <h2 className="text-2xl">
            {"Which celebration do you want to manage?"}
          </h2>
        </div>
        <div className="flex flex-row">
          {todos.map(
            ({ variant, title, description, navigationString }, index) => (
              <div key={index} className="mr-5 mb-5">
                <Card
                  onClick={() =>
                    navigateToPage(index + 1, "/dashboard/" + navigationString)
                  }
                  variant="onboard"
                  icon={<Image variant={variant} width={204} height={152} />}
                  title={title}
                  description={description}
                  index={index}
                  isSectionIncomplete={true}
                  // isSectionIncomplete={isSectionIncomplete(navigationString)}
                />
              </div>
            ),
          )}
        </div>
      </div>
    </Layout>
  );
};
