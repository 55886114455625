import { FormEvent } from "react";
import { FormikErrors } from "formik";
import { Button, Image, Input } from "@atoms";

export interface FormValues {
  code: string;
  password: string;
  confirmPassword: string;
}

export type ResetPasswordPageTemplateProps = {
  values: FormValues;
  errors: FormikErrors<FormValues>;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  backToSignIn: () => void;
  loading: boolean;
  success: boolean;
};

export const ResetPasswordPageTemplate: React.FC<ResetPasswordPageTemplateProps> = ({
  values,
  errors,
  handleInputChange,
  handleSubmit,
  backToSignIn,
  loading,
  success
}: ResetPasswordPageTemplateProps): JSX.Element => {
  const { code, password, confirmPassword } = values;
  const {
    code: codeError,
    password: passwordError,
    confirmPassword: confirmPasswordError,
  } = errors;

  return (
    <div className="h-screen flex">
      <div className="relative hidden lg:block min-h-screen w-6/10">
        <div className="absolute top-6 left-6">
          <Image variant="logoWhite" />
        </div>
        <div style={{ backgroundColor: "#ed2828" }} className="w-full h-full">
          <Image variant="backgroundPhone" width={827} height={775} />
        </div>
      </div>
      <div className="relative w-full h-full lg:w-4/10 flex justify-center items-center">
        <div className="lg:hidden absolute top-6 left-6">
          <Image variant="logoColour" />
        </div>
        <form
          className="text-center flex flex-col items-center max-w-xs mx-auto"
          onSubmit={handleSubmit}
        >

        {!success ?
          <div>
            <div className="px-3">
              <div className="mb-2">
                <h3 className="text-3xl font-black">Reset Password</h3>
              </div>
            </div>
            <div className="px-3 mb-6">
              <div className="">
                <h3 className="text-md">
                  We have sent an email to that address. Please enter the provided reset code and choose a new password.
                </h3>
              </div>
            </div>
            <div className="w-full">
              <div className="mb-2">
                <Input
                  name="code"
                  variant="text"
                  onChange={handleInputChange}
                  placeholder="Code"
                  value={code}
                  errorMessage={codeError}
                />
              </div>
            </div>
            <div className="w-full">
              <div className="mb-2">
                <Input
                  name="password"
                  variant="password"
                  onChange={handleInputChange}
                  placeholder="Password"
                  value={password}
                  errorMessage={passwordError}
                />
              </div>
            </div>
            <div className="w-full">
              <div className="mb-2">
                <Input
                  name="confirmPassword"
                  variant="password"
                  onChange={handleInputChange}
                  placeholder="Password confirmation"
                  value={confirmPassword}
                  errorMessage={confirmPasswordError}
                />
              </div>
            </div>
            <div className="mt-8 mb-6 w-full sm:mb-4">
              <div>
                <Button
                  type="submit"
                  variant="primary"
                  label="Submit"
                  stretch
                  color="secondary-medium"
                  hoverColor="secondary-dark"
                  textColor="white"
                  // brightness={500}
                  loading={loading}
                />
              </div>
            </div>
          </div> 
          :
          <div>
            <div>
              <h3 className="text-3xl font-black">
                Success!
              </h3>
            </div>
            <div className="mt-4">
              <h3 className="text-md">
                Please return to the Sign In screen and use your new password.
              </h3>
            </div>
            <div className="flex flex-col justify-center items-center"> 
              <div className="mt-8 w-2/3">
                <Button
                  type="submit"
                  variant="primary"
                  label="Return to Sign In"
                  stretch
                  color="secondary-medium"
                  hoverColor="secondary-dark"
                  textColor="white"
                  // brightness={500}
                  onClick={backToSignIn}
                />
              </div>
            </div>
          </div>
          }
        </form>
      </div>
    </div>
  );
};
