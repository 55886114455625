import { useLocation, useNavigate } from "react-router-dom";

import { useResendSignUp } from "@auth";
import { ConfirmPageTemplateProps } from "./template";

interface EmailState {
  email: string;
}

export const useConfirmPage = (): ConfirmPageTemplateProps => {
  const { state } = useLocation();
  const { email } = state as EmailState;
  const navigate = useNavigate();
  const resendSignUp = useResendSignUp();

  const handleBackButtonClick = () => navigate("/signin");
  const handleResendEmailClick = async () => {
    await resendSignUp(email);
  };

  return {
    handleBackButtonClick,
    handleResendEmailClick,
  };
};
