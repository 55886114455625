import { Button, Icon } from "@atoms";
import { useImageUploader } from "./logic";

export type ImageUploaderTemplateProps = {
  openFileSelector: any;
  selectedFiles: any;
  getRootProps: any;
  getInputProps: any;
  logo: any;
}

export const ImageUploaderTemplate = ({
  openFileSelector,
  selectedFiles,
  getRootProps,
  getInputProps,
  logo
}:ImageUploaderTemplateProps): JSX.Element => {

  const thumbs = selectedFiles.map((file: any) => (
    <div key={file.name} className="w-full h-full">
      <img
        src={file.preview}
        className="block object-contain w-full h-full"
        // Revoke data uri after image is loaded
        onLoad={() => { URL.revokeObjectURL(file.preview) }}
      />
    </div>
  ));

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />

        <div className={`rounded-md w-52 h-52 flex flex-col place-content-center ${selectedFiles.length === 0 && !logo ? "bg-neutral-lightest" : "#ffffff"}`}>
          {
          logo !== "" ?
            <div key={"key"} className="w-full h-full">
              <img
                src={logo}
                className="block object-contain w-full h-full"
                // Revoke data uri after image is loaded
                // onLoad={() => { URL.revokeObjectURL(file.preview) }}
              />
            </div>
            :
            selectedFiles.length === 0 ?
            <div className="flex flex-row place-self-center">
              <Icon variant={"emptyImage"} size={49} />
            </div>
            : 
            <div className="w-full h-full">
              {thumbs}
            </div>
          }
        </div>
        <div className="pt-6">
          <Button
            onClick={openFileSelector}
            variant="primary"
            label="Upload logo"
            stretch
            color="neutral-lightest"
            hoverColor="neutral-light"
            textColor="neutral-darkest"
            textWeight="semibold"
          />
        </div>
      </div>
    </section>
  );
}