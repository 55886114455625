import { ChipTypes } from "./";
import { Image } from "@atoms";

export type TextChipTemplateProps = { type: ChipTypes };

export const TextChipTemplate = ({type}: TextChipTemplateProps): JSX.Element => {
  let backgroundColor;
  let extraStyles = "h-8";

  switch (type) {
    case ChipTypes.activated:
      backgroundColor = "successful-lightest";
      break;
    case ChipTypes.comingSoon:
      backgroundColor = "neutral-light";
      break;
    case ChipTypes.planBasic:
      backgroundColor = "neutral-light";
      extraStyles = "h-5";
      break;
    case ChipTypes.planEnterprise:
      backgroundColor = "neutral-light";
      extraStyles = "h-5"
      break;
    case ChipTypes.beta:
      backgroundColor = "primary-light";
      break;
    case ChipTypes.popular:
      backgroundColor = "neutral-light";
      extraStyles = "h-5";
      break;
  }

  return (
    <div className={`flex flex-row font-medium text-xs text-neutral-darkest w-full px-3 rounded-full items-center align-self-center justify-content-center bg-${backgroundColor} ${extraStyles}`}>
      { type === ChipTypes.planBasic &&
        // <div className="pr-1">
        //   <Image variant="bitcoinLogo" width={12} height={12} />
        // </div>
        <div  className="pr-1">😀</div>
      }
      {type}
    </div>
  );
};
