import { useState } from "react";
import { useFormik, FormikErrors } from "formik";

import { ProfilePageTemplateProps } from "./template";
import { useUser } from "@auth";
import { useUpdateAddress, useUpdatePartner } from "@mutations";

export interface AddressFormValues {
  street: string | String;
  city: string | String;
  country: string | String;
  province: string | String;
  postalCode: string | String;
}

export interface CompanyFormValues {
  company: string;
}

export interface DescriptionFormValues {
  description: string;
}

export const useProfilePage = (): ProfilePageTemplateProps => {
  const partner = useUser();
  const addPartnerAddress = useUpdateAddress();
  const updatePartner = useUpdatePartner();

  const [modalChangeAddress, setModalChangeAddress] = useState<boolean>(false);
  const [modalChangeDescription, setModalChangeDescription] = useState<boolean>(false);
  const [modalChangeCompany, setModalChangeCompany] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [province, setProvince] = useState<string>(partner?.address?.province as string); // storing the province separately since it's a separate type of component
  // const [country, setCountry] = useState<string>(partner?.address?.country as string);
  const [country, setCountry] = useState<string>("CA");

  function closeModal() {
    setModalChangeCompany(false);
    setModalChangeDescription(false);
    setModalChangeAddress(false);
  }

  // ------------------------- Change company name  -------------------------
  const onEditCompanyClicked = () => {
    setModalChangeCompany(true);
  };
  
  const validateCompany = (values: CompanyFormValues): FormikErrors<CompanyFormValues> => {
    const errors: FormikErrors<CompanyFormValues> = {};
    if (!values.company) {
      errors.company = "Company name is required";
    }
    return errors;
  };

  const { values: valuesCompany, errors: errorsCompany, handleChange: handleChangeCompany, handleSubmit: handleSubmitCompany } =
    useFormik<CompanyFormValues>({
      initialValues: {
        company: partner?.organization || "",
      },
      validate: validateCompany,
      validateOnChange: false,
      onSubmit: async (values: CompanyFormValues) => {
        console.log("company onSubmit called");
        const username = partner?.emailAddress || "";
        console.log("partner: ", partner);
        setIsLoading(true);

        try {
          await updatePartner(
            { organization: values.company },
            username,
            false
          );
          setIsLoading(false);
          closeModal();
          return;
        } catch (error: any) {
          closeModal();
          console.log(error);
        }
      },
    });
    
  const handleInputChangeCompany = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeCompany(e);
  }


  // ------------------------- Change description  -------------------------
  const onEditDescriptionClicked = () => {
    setModalChangeDescription(true);
  };
  
  const validateDescription = (values: DescriptionFormValues): FormikErrors<DescriptionFormValues> => {
    const errors: FormikErrors<DescriptionFormValues> = {};
    if (!values.description) {
      errors.description = "Company description is required";
    }
    return errors;
  };

  const { values: valuesDescription, errors: errorsDescription, handleChange: handleChangeDescription, handleSubmit: handleSubmitDescription } =
    useFormik<DescriptionFormValues>({
      initialValues: {
        description: partner?.description || "",
      },
      validate: validateDescription,
      validateOnChange: false,
      onSubmit: async (values: DescriptionFormValues) => {
        console.log("description onSubmit called");
        const username = partner?.emailAddress || "";
        setIsLoading(true);

        try {
          await updatePartner(
            { description: values.description },
            username,
            false
          );
          setIsLoading(false);
          closeModal();
          return;
        } catch (error: any) {
          closeModal();
          console.log(error);
        }
      },
    });

  const handleInputChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeDescription(e);
  }

  // ------------------------- Change address -------------------------
  const onEditAddressClicked = () => {
    setModalChangeAddress(true);
  };

  const validateAddress = (values: AddressFormValues): FormikErrors<AddressFormValues> => {
    const errors: FormikErrors<AddressFormValues> = {};

    if (!values.street) {
      errors.street = "Street is required";
    } else if (!values.city) {
      errors.city = "City is required";
    } else if (!country) {
      errors.city = "Country is required";  
    } else if (!province) {
      errors.city = "Province is required";
    } else if (!values.postalCode) {
      errors.city = "Postal code is required";
    }
    return errors;
  };

  const { values: valuesAddress, errors: errorsAddress, handleChange: handleChangeAddress, handleSubmit: handleSubmitAddress } =
    useFormik<AddressFormValues>({
      initialValues: {
        street: partner?.address?.street || "",
        city:  partner?.address?.city || "",
        country:  partner?.address?.country || "CA",
        province:  partner?.address?.province || "",
        postalCode:  partner?.address?.postalCode || "",
      },
      
      validate: validateAddress,
      validateOnChange: false,
      onSubmit: async (values: AddressFormValues) => {
        console.log("address onSubmit called:");
        console.log({...values, province, country});
        setIsLoading(true);

        try {
          await addPartnerAddress(
            values.street as string,
            values.city as string,
            province,
            country,
            values.postalCode as string,
          );
          setIsLoading(false);
          closeModal();
          return;
        } catch (error: any) {
          closeModal();
          console.log(error);
        }
      },
    });

  const handleInputChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeAddress(e);
  }

  const handleInputChangeProvince = (e: React.ChangeEvent<HTMLInputElement>) => {

    console.log("set province to: ", (e as any).value);
    setProvince((e as any).value);
  }

  const handleInputChangeCountry = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log("clear province...");
    setProvince("");
    setCountry((e as any).value);
  }

  return {
    partnerData: partner,
    onEditCompanyClicked,
    onEditDescriptionClicked,
    onEditAddressClicked,
    handleInputChangeCompany,
    handleInputChangeDescription,
    handleInputChangeAddress,
    handleInputChangeProvince,
    handleInputChangeCountry,
    modalChangeCompany,
    modalChangeDescription,
    modalChangeAddress,
    closeModal,
    handleSubmitCompany,
    handleSubmitDescription,
    handleSubmitAddress,
    valuesCompany,
    valuesDescription,
    valuesAddress,
    province,
    country,
    errorsCompany,
    errorsDescription,
    errorsAddress,
    isLoading
  }
}
