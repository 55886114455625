import { Icon, Spinner } from "@atoms";
import { BrightnessType, SizeType } from "@theme/types";
import { widthMixin } from "@theme/mixins";
import { PRIMARY_BUTTON_SIZE } from "./constants";

export type PrimaryButtonTemplateProps = {
  onClick?: () => void;
  label: string;
  stretch?: boolean;
  autosize?:boolean;
  size?: SizeType;
  withIcon?: any;
  color: string;
  textColor?: string;
  textSize?: string;
  textWeight?: string;
  hoverColor?: string;
  brightness?: BrightnessType;
  type?: "button" | "submit";
  loading?: boolean;
  height?: number;
  width?: number;
  disabled?: boolean;
};

export const PrimaryButtonTemplate = ({
  onClick,
  label,
  stretch,
  autosize,
  size,
  color,
  textColor = "black",
  textSize = "md",
  textWeight = "normal",
  hoverColor = "primary-dark",
  withIcon,
  type = "button",
  loading,
  disabled = false,
}: PrimaryButtonTemplateProps): JSX.Element => {
  return (
    <button
      type={type}
      className={`${widthMixin({
        table: PRIMARY_BUTTON_SIZE,
        size,
        stretch,
        autosize,
        centered: true,
      })} py-2 px-3 text-black rounded-full min-h-full duration-150
      hover:bg-${hoverColor} bg-${color} text-${textColor} disabled disabled:bg-gray-100 disabled:cursor-not-allowed disabled:pointer-events-none disabled:opacity-25`}
      {...(onClick && { onClick })}
      disabled={disabled}
    >
      <div style={{height: 25}} className="flex items-center">
        {withIcon && !loading ? (
          <div style={{ bottom: 0.5 }} className="mr-3 relative">
            <Icon variant={withIcon} height={16} width={16} />
          </div>
        ) : null}
        {loading ? <Spinner size={12} /> : <span className={`text-${textSize} font-${textWeight}`}>{label}</span>}
      </div>
    </button>
  );
};
