import { Routes, Route } from "react-router-dom";
import { Page } from ".";

export const SignUpRouter = (): JSX.Element => (
  <Routes>
    <Route path="/*" element={<Page variant="root" />} />
    <Route path="/confirm/*" element={<Page variant="confirm" />} />
    <Route path="sent" element={<Page variant="sent" />} />
    <Route path="welcome" element={<Page variant="welcome" />} />
  </Routes>
);
