import { useEffect, useContext, useState } from "react";
import { Layout } from "@layouts";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../auth/auth-context";

export const PendingApprovalPageTemplate = () : JSX.Element => {
    const navigate = useNavigate();
    const {clientSecret} = useContext(AuthContext);
    const [showWarningMessage, setShowWarningMessage] = useState<boolean>(false);

    useEffect(() => {
      const timeoutId = setTimeout(() => setShowWarningMessage(true), 4000);
      return () => {
        clearTimeout(timeoutId);
      }
    }, []);

    useEffect(() => {
       if (clientSecret && clientSecret !== "") { 
         navigate("/dashboard/billing");
       }
    }, [clientSecret]);

    // This is a page that appears if the user goes to the Billing screen without a client secret set
    return (
      <Layout
        variant="dashboard"
        header="Payment Methods"
        subheader={
          <div className="text-neutral-dark text-base font-normal mt-2">
            Manage your billing here
          </div>
        }
      >
        <div>
          {showWarningMessage ?
            <div className="pb-6 pt-12">
              <div className="text-attention-darkest mt-2 p-3 pl-6 rounded-xl bg-attention-lightest w-1/2">
                🚨 First go to the Organization page and add your address!
              </div>
            </div>
            :
            <div className="pt-12">Loading...</div>
          }
        </div>
      </Layout>
    );
}