import {
  GoogleIcon,
  GoogleIconProps,
  PasswordShowIcon,
  PasswordShowIconProps,
  PasswordHideIcon,
  PasswordHideIconProps,
  HomeIcon,
  HomeIconProps,
  FunnelIcon,
  FunnelIconProps,
  CreditCardIcon,
  CreditCardIconProps,
  DollarSignIcon,
  DollarSignIconProps,
  BitcoinIcon,
  BitcoinIconProps,
  PinMarkerIcon,
  PinMarkerIconProps,
  ChevronDownIcon,
  ChevronDownIconProps,
  BackArrowIcon,
  BackArrowIconProps,
  ForwardArrowIcon,
  ForwardArrowIconProps,
  UploadCloudIcon,
  UploadCloudIconProps,
  UsersIconProps,
  GiftIconProps,
  UserIconProps,
  UsersIcon,
  GiftIcon,
  UserIcon,
  OrganizationIcon,
  OrganizationIconProps,
  SignOutIcon,
  SignOutIconProps,
  RocketIcon,
  RocketIconProps,
  StarIcon,
  StarIconProps,
  AirplaneIcon,
  AirplaneIconProps,
  BarchartIcon,
  BarchartIconProps,
  EmptyImageIcon,
  EmptyImageIconProps,
  AirdropIcon,
  AirdropIconProps,
  CakeIcon,
  CakeIconProps,
  OnTheGoIcon,
  OnTheGoIconProps,
  RoundUpIcon,
  RoundUpIconProps,
  IntegrationIcon,
  IntegrationIconProps,
  ClockIcon,
  ClockIconProps,
  LightningIcon,
  LightningIconProps,
  BitcoinPayrollIcon,
  BitcoinPayrollIconProps,
  PaymentCardIcon,
  PaymentCardIconProps,
  CloseXIcon,
  CloseXIconProps,
  CheckCircleFilledIcon,
  CheckCircleFilledIconProps
} from "./template";

import {
  HeartIcon
} from "@radix-ui/react-icons"

export type IconVariantType =
  | "google"
  | "passwordShow"
  | "passwordHide"
  | "home"
  | "funnel"
  | "creditCard"
  | "dollarSign"
  | "bitcoin"
  | "pinMarker"
  | "chevronDown"
  | "backArrow"
  | "forwardArrow"
  | "uploadCloud"
  | "users"
  | "gift"
  | "user"
  | "organization"
  | "signOut"
  | "rocket"
  | "star"
  | "airplane"
  | "barchart"
  | "emptyImage"
  | "airdrop"
  | "cake"
  | "onTheGo"
  | "roundUp"
  | "integration"
  | "clock"
  | "lightning"
  | "bitcoinPayroll"
  | "paymentCard"
  | "closeX"
  | "heartOutline"
  | "checkCircleFilled";

export type IconProps =
  | ({ variant: "google" } & GoogleIconProps)
  | ({ variant: "passwordShow" } & PasswordShowIconProps)
  | ({ variant: "passwordHide" } & PasswordHideIconProps)
  | ({ variant: "home" } & HomeIconProps)
  | ({ variant: "funnel" } & FunnelIconProps)
  | ({ variant: "creditCard" } & CreditCardIconProps)
  | ({ variant: "dollarSign" } & DollarSignIconProps)
  | ({ variant: "bitcoin" } & BitcoinIconProps)
  | ({ variant: "pinMarker" } & PinMarkerIconProps)
  | ({ variant: "chevronDown" } & ChevronDownIconProps)
  | ({ variant: "backArrow" } & BackArrowIconProps)
  | ({ variant: "forwardArrow" } & ForwardArrowIconProps)
  | ({ variant: "uploadCloud" } & UploadCloudIconProps)
  | ({ variant: "users" } & UsersIconProps)
  | ({ variant: "gift" } & GiftIconProps)
  | ({ variant: "user" } & UserIconProps)
  | ({ variant: "organization" } & OrganizationIconProps)
  | ({ variant: "signOut" } & SignOutIconProps)
  | ({ variant: "rocket" } & RocketIconProps)
  | ({ variant: "star" } & StarIconProps)
  | ({ variant: "airplane" } & AirplaneIconProps)
  | ({ variant: "barchart" } & BarchartIconProps)
  | ({ variant: "emptyImage" } & EmptyImageIconProps)
  | ({ variant: "airdrop" } & AirdropIconProps)
  | ({ variant: "cake" } & CakeIconProps)
  | ({ variant: "onTheGo" } & OnTheGoIconProps)
  | ({ variant: "roundUp" } & RoundUpIconProps)
  | ({ variant: "integration" } & IntegrationIconProps)
  | ({ variant: "clock" } & ClockIconProps)
  | ({ variant: "lightning" } & LightningIconProps)
  | ({ variant: "bitcoinPayroll" } & BitcoinPayrollIconProps)
  | ({ variant: "paymentCard" } & PaymentCardIconProps)
  | ({ variant: "closeX" } & CloseXIconProps)
  | ({ variant: "heartOutline" } & CloseXIconProps)
  | ({ variant: "checkCircleFilled" } & CheckCircleFilledIconProps);

export const Icon = (props: IconProps): JSX.Element => {
  const { variant } = props;
  switch (props.variant) {
    case "google":
      return <GoogleIcon {...props} />;
    case "passwordShow":
      return <PasswordShowIcon {...props} />;
    case "passwordHide":
      return <PasswordHideIcon {...props} />;
    case "home":
      return <HomeIcon {...props} />;
    case "funnel":
      return <FunnelIcon {...props} />;
    case "creditCard":
      return <CreditCardIcon {...props} />;
    case "dollarSign":
      return <DollarSignIcon {...props} />;
    case "bitcoin":
      return <BitcoinIcon {...props} />;
    case "pinMarker":
      return <PinMarkerIcon {...props} />;
    case "chevronDown":
      return <ChevronDownIcon {...props} />;
    case "backArrow":
      return <BackArrowIcon {...props} />;
    case "forwardArrow":
      return <ForwardArrowIcon {...props} />;
    case "uploadCloud":
      return <UploadCloudIcon {...props} />;
    case "users":
      return <UsersIcon {...props} />;
    case "gift":
      return <GiftIcon {...props} />;
    case "user":
      return <UserIcon {...props} />;
    case "organization":
      return <OrganizationIcon {...props} />;
    case "signOut":
      return <SignOutIcon {...props} />;
    case "rocket":
      return <RocketIcon {...props} />;   
    case "star":
      return <StarIcon {...props} />;  
    case "airplane":
      return <AirplaneIcon {...props} />;   
    case "barchart":
      return <BarchartIcon {...props} />; 
    case "emptyImage":
      return <EmptyImageIcon {...props} />; 
    case "airdrop":
      return <AirdropIcon {...props} />;
    case "cake":
      return <CakeIcon {...props} />;  
    case "onTheGo":
      return <OnTheGoIcon {...props} />;  
    case "roundUp":
      return <RoundUpIcon {...props} />;      
    case "integration":
      return <IntegrationIcon {...props} />; 
    case "clock":
      return <ClockIcon {...props} />; 
    case "lightning":
      return <LightningIcon {...props} />; 
    case "bitcoinPayroll":
      return <BitcoinPayrollIcon {...props} />; 
    case "paymentCard":
      return <PaymentCardIcon {...props} />; 
    case "closeX":
      return <CloseXIcon {...props} />; 
    case "checkCircleFilled":
      return <CheckCircleFilledIcon {...props} />; 
    case "heartOutline":
      return <HeartIcon className="h-6 w-6" />
    default:
      throw new Error(`Invalid Icon variant [${variant}] used`);
  }
};
