import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import BitcoinLogo1x from "./bitcoin_logo-1x.png";
import BitcoinLogo2x from "./bitcoin_logo-2x.png";

export const BitcoinLogoImage = ({
  width,
  height,
  highResolution,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <div style={{ height, width }}>
      <ImageTemplate
        {...props}
        imgSrc={highResolution ? BitcoinLogo2x : BitcoinLogo1x}
      />
    </div>
  );
};
