import { FormEvent, useEffect, useState } from "react";
import ReactModal from "react-modal";

import { Partner } from "@api";
import { Button, Input } from "@atoms";
import { Layout } from "@layouts";
import { Item, Card, TeamColumns } from "@molecules";
import { DataTable } from "@organisms";
import { FormikErrors, useFormik } from "formik";
import {
  useGetPartnerUpdatedSeatCount,
  useGetPartnerCheckoutPortalLink,
} from "@queries";
import {
  useRemoveUserToPremiumPartner,
  useEditUserFromPremiumPartner,
  useResendEmailToUserFromPremiumPartner,
} from "@mutations";
import { EmployeeFormValues } from "./logic";
import { styles as globalStyles } from "../../../styles/styles";
import { subscriptionPlanData } from "../billing/subscription-data";
import { SubscriptionPlansTypes, Invoice } from "../../../types";
import { PremiumUser } from "@api";
import { Input as SInput } from "@shadcn";

export type EmployeeFormValuesEdit = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  coinmilesAccount: string;
  employmentStartDate: string;
  birthday: string;
};

export interface EmployeeEditProps {
  firstName: string;
  lastName: string;
  emailAddress: string;
  coinmilesAccount: string;
  employmentStartDate: string;
  birthday: string;
}

export type TeamPageTemplateProps = {
  premiumUsers: any[];
  onAddTeamMemberClicked: () => void;
  onEditTeamMemberClicked: () => void;
  onManageNumberOfSeatsClicked: () => void;
  partner: Partner | null;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  values: EmployeeFormValues;
  errors: FormikErrors<EmployeeFormValues>;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  modalIsOpen: boolean;
  closeModal: () => void;
  isAddressSubmitted: boolean;
  isCreditCardAdded: boolean;
  creditCards: any;
  isLoadingData: boolean;
  setIsLoadingData: (isLoading: boolean) => void;
  emailNotAlreadyAdded: boolean;
};

export const TeamPageTemplate = ({
  premiumUsers,
  onAddTeamMemberClicked,
  onEditTeamMemberClicked,
  onManageNumberOfSeatsClicked,
  partner,
  handleInputChange,
  values,
  errors,
  handleSubmit,
  modalIsOpen,
  closeModal,
  isAddressSubmitted,
  isCreditCardAdded,
  creditCards,
  isLoadingData,
  setIsLoadingData,
  emailNotAlreadyAdded,
}: TeamPageTemplateProps): JSX.Element => {
  const [rowIndex, setRowIndex] = useState<number>(-1);
  const [rowData, setRowData] = useState<PremiumUser | null>(null);
  const [isDeletingEmployeeAttempted, setIsDeletingEmployeeAttempted] =
    useState<boolean>(false);
  const [isEditingEmployeeAttempted, setIsEditingEmployeeAttempted] =
    useState<boolean>(false);
  const [isResendingInvitationAttempted, setIsResendingInvitationAttempted] =
    useState<boolean>(false);
  const removeUser = useRemoveUserToPremiumPartner();
  const editUser = useEditUserFromPremiumPartner();
  const resendInviteToUser = useResendEmailToUserFromPremiumPartner();
  const [seatCount, setSeatCount] = useState<number | undefined>(undefined);
  const [checkoutLink, setCheckoutLink] = useState<string | undefined>("");

  const validate = (
    values: EmployeeFormValuesEdit,
  ): FormikErrors<EmployeeFormValuesEdit> => {
    const errors: FormikErrors<EmployeeFormValuesEdit> = {};
    /* if (!values.emailAddress) {
        errors.emailAddress = "Email is required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)
      ) {
        errors.emailAddress = "Invalid email address";
      } */
    return errors;
  };

  const {
    values: editValues,
    errors: editErrors,
    handleChange,
    handleSubmit: handleEditSubmit,
    setFieldError,
  } = useFormik<EmployeeFormValuesEdit>({
    initialValues: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      coinmilesAccount: "",
      employmentStartDate: "",
      birthday: "",
    },
    validate,
    validateOnChange: false,
    onSubmit: async (values: EmployeeEditProps) => {
      setIsLoadingData(true);
      try {
        console.log("Attempted editing");
        await editUser(values.firstName, values.lastName, rowIndex ?? -1, values.employmentStartDate, values.birthday);
        editValues.firstName = "";
        editValues.lastName = "";
        return;
      } catch (error: any) {
        console.log(error);
        // use setFieldErrors here or nuke that stuff
      }
    },
  });

  const onClickeResendInvitation = (rowIndex: number) => {
    setRowIndex(rowIndex);
    setIsResendingInvitationAttempted(true);
  };
  const onClickEdit = (rowIndex: number) => {
    setRowIndex(rowIndex);
    setIsEditingEmployeeAttempted(true);
    editValues.firstName = premiumUsers[rowIndex].firstName;
    editValues.lastName = premiumUsers[rowIndex].lastName;
    editValues.employmentStartDate = premiumUsers[rowIndex].employmentStartDate;
    editValues.birthday = premiumUsers[rowIndex].birthday;
  };

  const onClickDelete = (rowIndex: number) => {
    setRowIndex(rowIndex);
    setIsDeletingEmployeeAttempted(true);
  };
  const { teamColumns, rowData: returnedRowData } = TeamColumns({
    rowIndex,
    setRowIndex,
    rowData,
    setRowData,
    isDeletingEmployeeAttempted,
    setIsDeletingEmployeeAttempted,
    isEditingEmployeeAttempted,
    setIsEditingEmployeeAttempted,
    onClickeResendInvitation,
    onClickEdit,
    onClickDelete,
  });

  const { TeamTable } = DataTable({
    columns: teamColumns,
    data: premiumUsers,
    searchable: true,
    addButton:
      <div className="w-56">
        <Button
          onClick={
            isAddressSubmitted && creditCards?.length > 0
              ? onAddTeamMemberClicked
              : undefined
          }
          variant="primary"
          label="+ Add Team Member"
          stretch
          textWeight={"semibold"}
          color={
            isAddressSubmitted && creditCards?.length > 0
              ? "primary-medium"
              : "neutral-light"
          }
          hoverColor={
            isAddressSubmitted && creditCards?.length > 0
              ? "primary-dark"
              : "neutral-light"
          }
          loading={isAddressSubmitted && creditCards === null}
        />
      </div>
  });
  //const { isLoadingData, setIsLoadingData } = useContext(AuthContext);

  function closeDeleteModal() {
    setIsDeletingEmployeeAttempted(false);
  }

  function closeEditModal() {
    setIsEditingEmployeeAttempted(false);
  }

  function closeResendModal() {
    setIsResendingInvitationAttempted(false);
  }

  const onConfirmResendEmailInvitation = () => {
    setIsLoadingData(true);
    resendInviteToUser(rowIndex);
  }

  const onConfirmUserDelete = () => {
    /* Delete email from list of users */
    setIsLoadingData(true);
    removeUser(premiumUsers?.[rowIndex]?.emailAddress);
    // closeModal();
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e);

    useEffect(() => {
      if (!isLoadingData) closeResendModal();
    }, [isLoadingData]);
  useEffect(() => {
    if (!isLoadingData) closeEditModal();
  }, [isLoadingData]);

  useEffect(() => {
    if (!isLoadingData) closeDeleteModal();
  }, [isLoadingData]);

  const { birthday, emailAddress, employmentStartDate, firstName, lastName } =
    values;
  const {
    birthday: birthdayError,
    emailAddress: emailAddressError,
    employmentStartDate: employmentStartDateError,
    firstName: firstNameError,
    lastName: lastNameError,
  } = errors;

  const { getPartnerUpdatedSeatCount, partnerUpdatedSeatCount } =
    useGetPartnerUpdatedSeatCount();

  // const { getPartnerCheckoutPortalLink, partnerCheckoutPortalLink } = useGetPartnerCheckoutPortalLink();

  useEffect(() => {
    // getPartnerUpdatedSeatCount();
    // getPartnerCheckoutPortalLink();
  }, []);

  // useEffect(() => {
  //   setCheckoutLink(partnerCheckoutPortalLink);
  // }, [partnerCheckoutPortalLink])

  useEffect(() => {
    setSeatCount(partnerUpdatedSeatCount);
  }, [partnerUpdatedSeatCount]);

  const hasSeatsAvailable = premiumUsers.length > 0;

  return (
    <Layout
      variant="dashboard"
      header="Team"
      subheader={
        <div className="text-neutral-dark text-base font-normal mt-2">
          Manage your Coinmiles+ invites here
        </div>
        //   hasSeatsAvailable ? (seatCount && (partner?.premiumSettings?.[0]?.users?.length !== undefined)) ?
        //   <h5 className="text-primary-dark text-lg font-normal">

        //     You currently have {seatCount - (partner?.premiumSettings?.[0]?.users?.length)} seats still available
        //   </h5> :
        //   <h5 className="text-primary-dark text-lg font-normal">

        //   Checking how many seats you have available...
        // </h5>
        // :
        // <h5 className="text-primary-dark text-lg font-normal">
        //   You currently have no seats available
        // </h5>
      }
    >
      <div className="max-w-5xl">
        <div className="p-2">
          {!isAddressSubmitted && (
            <div className="pb-6">
              <div className="text-attention-darkest mt-2 p-3 pl-6 rounded-xl bg-attention-lightest w-1/2">
                🚨 First go to the Organization page and add your address!
              </div>
            </div>
          )}
          {isAddressSubmitted && !isCreditCardAdded && (
            <div className="pb-6">
              <div className="text-attention-darkest mt-2 p-3 pl-6 rounded-xl bg-attention-lightest w-1/2">
                🚨 First go to the Subscription page and add a credit card!
              </div>
            </div>
          )}
          {/* <div className="flex flex-row justify-between items-center">
            <div>
              <div className="text-neutral-darkest text-xl font-normal">
                My Plan
              </div>
              <div className="text-md font-semibold">$5 / Month / User</div>
            </div>
            <a
             href={checkoutLink}
              onClick={onManageNumberOfSeatsClicked}
              className="w-56 mx-3 my-2 px-2 py-2 rounded-full text-black font-bold text-center border border-black bg-white"
              style={styles.changeMembershipButton}
            >
              Upgrade My Plan
            </a>
          </div> */}
        </div>

        <div className="p-1 mr-4 rounded-md max-w-5xl">
          {/* Below we'll need an if-else statement to either display employees or display smth to say no employees are added */}
          {premiumUsers.length ? (
            <div>
              <TeamTable />
            </div>
          ) : (
            <div className = "w-full">
            <div>
              You currently have no team members added. Add your first employee
              by pressing the "Add Team Member" button.
            </div>
            <div className="pb-6 mt-4 flex flex-row w-full justify-end">
            <div className="flex flex-row justify-between items-center mr-4">
              <div className="w-56">
                <Button
                  onClick={
                    isAddressSubmitted && creditCards?.length > 0
                      ? onAddTeamMemberClicked
                      : undefined
                  }
                  variant="primary"
                  label="+ Add Team Member"
                  stretch
                  textWeight={"semibold"}
                  color={
                    isAddressSubmitted && creditCards?.length > 0
                      ? "primary-medium"
                      : "neutral-light"
                  }
                  hoverColor={
                    isAddressSubmitted && creditCards?.length > 0
                      ? "primary-dark"
                      : "neutral-light"
                  }
                  loading={isAddressSubmitted && creditCards === null}
                />
              </div>
            </div>
          </div>
            </div>
            
          )}
        </div>
        {modalIsOpen ? (
          <ReactModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={globalStyles.generalModal}
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}
            contentLabel="Example Modal"
          >
            <div className="font-semibold text-2xl flex flex-row justify-center py-2">
              Add a Team Member
            </div>
            <form className="p-2" onSubmit={handleSubmit}>
              <div className="mb-3">
                <Input
                  name="firstName"
                  variant="text"
                  onChange={handleInputChange}
                  placeholder="First name"
                  value={firstName}
                  errorMessage={firstNameError}
                />
              </div>
              <div className="mb-3">
                <Input
                  name="lastName"
                  variant="text"
                  onChange={handleInputChange}
                  placeholder="Last name"
                  value={lastName}
                  errorMessage={lastNameError}
                />
              </div>
              <div className="mb-3">
                <Input
                  name="emailAddress"
                  variant="text"
                  onChange={handleInputChange}
                  placeholder="Email address"
                  value={emailAddress}
                  errorMessage={emailAddressError}
                />
              </div>
              <div className="mb-3">
                <div className="text-xs text-gray-500">
                  Work Anniversary (Optional)
                </div>
                <SInput
                  name="employmentStartDate"
                  type="date"
                  onChange={handleInputChange}
                  placeholder="Anniversary"
                  value={employmentStartDate}
                  className="w-full h-12 rounded-sm"
                  //errorMessage={birthdayError}
                />
              </div>
              <div className="mb-3">
                <div className="text-xs text-gray-500">Birthday (Optional)</div>
                <SInput
                  name="birthday"
                  type="date"
                  onChange={handleInputChange}
                  placeholder="Birthday"
                  value={birthday}
                  className="w-full h-12 rounded-sm"
                  //errorMessage={birthdayError}
                />
              </div>
              <div className="flex flex-row justify-center">
                <div className="w-64">
                  <Button
                    onClick={isLoadingData ? () => {} : handleSubmit}
                    variant="primary"
                    label="Send Invite"
                    stretch
                    color={"primary-medium"}
                    brightness={600}
                    textWeight={"semibold"}
                    loading={isLoadingData && emailNotAlreadyAdded}
                  />
                </div>
              </div>
            </form>
          </ReactModal>
        ) : null}
        {isEditingEmployeeAttempted ? (
          <ReactModal
            isOpen={isEditingEmployeeAttempted}
            onRequestClose={closeEditModal}
            style={globalStyles.generalModal}
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}
            contentLabel="Example Modal"
          >
            <div className="font-semibold text-2xl flex flex-row justify-center py-2">
              Edit a Team Member
            </div>
            <form className="p-2" onSubmit={handleEditSubmit}>
              <div className="mb-3">
                <Input
                  name="firstName"
                  variant="text"
                  onChange={handleEditInputChange}
                  placeholder="First name"
                  value={editValues.firstName}
                  errorMessage={editErrors.firstName}
                />
              </div>
              <div className="mb-3">
                <Input
                  name="lastName"
                  variant="text"
                  onChange={handleEditInputChange}
                  placeholder="Last name"
                  value={editValues.lastName}
                  errorMessage={editErrors.lastName}
                />
              </div>
              <div className="mb-3">
                <div className="text-xs text-gray-500">
                  Work Anniversary (Optional)
                </div>
                <SInput
                  name="employmentStartDate"
                  type="date"
                  onChange={handleEditInputChange}
                  placeholder="Anniversary"
                  value={editValues.employmentStartDate}
                  className="w-full h-12 rounded-sm"
                  //errorMessage={birthdayError}
                />
              </div>
              <div className="mb-3">
                <div className="text-xs text-gray-500">Birthday (Optional)</div>
                <SInput
                  name="birthday"
                  type="date"
                  onChange={handleEditInputChange}
                  placeholder="Birthday"
                  value={editValues.birthday}
                  className="w-full h-12 rounded-sm"
                  //errorMessage={birthdayError}
                />
              </div>
              {/* <div className="mb-3">
              <Input
                name="emailAddress"
                variant="text"
                onChange={handleInputChange}
                placeholder="Email address"
                value={emailAddress}
                errorMessage={errors.emailAddress}
              />
            </div> */}
              <div className="flex flex-row justify-center">
                <div className="w-64">
                  <Button
                    onClick={isLoadingData ? () => {} : handleEditSubmit}
                    variant="primary"
                    label="Update Team Member"
                    stretch
                    color="primary-medium"
                    textWeight={"semibold"}
                    loading={isLoadingData}
                  />
                </div>
              </div>
            </form>
          </ReactModal>
        ) : null}
        {isDeletingEmployeeAttempted ? (
          <ReactModal
            isOpen={isDeletingEmployeeAttempted}
            onRequestClose={closeDeleteModal}
            style={globalStyles.generalModal}
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}
          >
            <div className="font-semibold text-2xl flex flex-row justify-center py-4">
              Remove User
            </div>
            <div className="mb-5 flex flex-row justify-center">
              {`Remove ${premiumUsers?.[rowIndex]?.firstName} ${premiumUsers?.[rowIndex]?.lastName} from premium?`}
            </div>
            <div className="flex flex-row justify-center">
              <div className="w-64">
                <Button
                  onClick={isLoadingData ? () => {} : onConfirmUserDelete}
                  variant="primary"
                  label="Confirm"
                  stretch
                  textWeight={"semibold"}
                  color="primary-medium"
                  loading={isLoadingData}
                />
              </div>
            </div>
          </ReactModal>
        ) : null}
        {isResendingInvitationAttempted ? (
          <ReactModal
            isOpen={isResendingInvitationAttempted}
            onRequestClose={closeResendModal}
            style={globalStyles.generalModal}
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}
          >
            <div className="font-semibold text-2xl flex flex-row justify-center py-4">
              Resend Invitation
            </div>
            <div className="mb-5 flex flex-row justify-center">
              {`Resend a premium invitation email to ${premiumUsers?.[rowIndex]?.firstName} ${premiumUsers?.[rowIndex]?.lastName}?`}
            </div>
            <div className="flex flex-row justify-center">
              <div className="w-64">
                <Button
                  onClick={isLoadingData ? () => {} : onConfirmResendEmailInvitation}
                  variant="primary"
                  label="Confirm"
                  stretch
                  textWeight={"semibold"}
                  color="primary-medium"
                  loading={isLoadingData}
                />
              </div>
            </div>
          </ReactModal>
        ) : null}
      </div>
    </Layout>
  );
};
