import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import ChequeLayout1x from "./cheque-layout-1x.png";
import ChequeLayout2x from "./cheque-layout-2x.png";

export const ChequeLayoutImage = ({
  width,
  height,
  highResolution,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <div style={{ height, width }}>
      <ImageTemplate
        {...props}
        imgSrc={highResolution ? ChequeLayout2x : ChequeLayout1x}
      />
    </div>
  );
};
