import { useUser } from "@auth";
import { AirdropPageTemplateProps } from "./template";
import { useGetAllGiftCardOffers } from "@queries";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../auth/auth-context";


export const useAirdropPage = (): AirdropPageTemplateProps => {
    const navigate = useNavigate();
    const partner = useUser();
    const { getAllGiftCardOffers } = useGetAllGiftCardOffers();
    const { giftCardOffers, partnerAirdropTransactions } = useContext(AuthContext);

    useEffect(() => {
        getAllGiftCardOffers();   
    }, []);

    const navigateToPage = (index: number, to: string) => {
        navigate(to);
      }

    const onReturnPressed = () => {
        const to = "/dashboard/perks";
        navigate(to);
    }

    return {
        premiumUsers: partner?.premiumSettings?.[0]?.users.filter(item => item.userId !== "") || [],
        giftCardOffers,
        navigateToPage,
        onReturnPressed
    }
}