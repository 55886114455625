/* tslint:disable */
/* eslint-disable */

/*
      selectedOptions {
        rewards {
          type
        }
        perks {
          type
        }
      }
*/

export const getCardRegisterClientSecretAndCustomerId = /* GraphQL */ `
  query GetPartnerCardRegisterClientSecretAndCustomerId {
    getPartnerCardRegisterClientSecretAndCustomerId {
      customerId
      clientSecret
    }
  }`;

export const getPartner = /* GraphQL */ `
  query GetPartner {
    getPartner {
      id
      emailAddress
      firstName
      lastName
      brandIds
      didLogin
      description
      organization
      phoneNumber
      website
      status
      images {
        logoUrl
      }
      address {
        street
        city
        province
        country
        postalCode
      }
      premiumSettings {
        users {
          firstName
          lastName
          emailAddress
          userId
          employmentStartDate
          birthday
        }
        percentage
        availableSeats
      }
    offerAmountsManagement {
      offerBoost {
        isBoostingAllOffers
        boostAmount
        boostLimitPerUser
      }
    }
    }
  }
`;

export const getPartnerBillingPortalLink = /* GraphQL */ `
  query GetPartnerBillingPortalLink {
    getPartnerBillingPortalLink
  }
`;

export const getPartnerCheckoutPortalLink = /* GraphQL */ `
  query GetPartnerCheckoutPortalLink {
    getPartnerCheckoutPortalLink
  }
`;

export const getPartnerUpdatedSeatCount = /* GraphQL */ `
  query GetPartnerUpdatedSeatCount {
    getPartnerUpdatedSeatCount
  }
`;

export const getPartnerPaymentMethods = /* GraphQL */ `
  query GetPartnerPaymentMethods {
    getPartnerPaymentMethods {
      cardBrand
      expiryMonth
      expiryYear
      lastFourDigits
      paymentId
    }
  }
`;

export const getPartnerInvoices = /* GraphQL */ `
  query GetPartnerInvoices {
    getPartnerInvoices {
      id
      createdDate
      amount
      downloadUrl
      planType
    }
  }
`;

export const getPartnerBillingDetails = /* GraphQL */ `
  query GetPartnerBillingDetails {
    getPartnerBillingDetails {
      planAmount
    }
  }`;

export const getPresignedUrlS3 = /* GraphQL */ `
  query GetPresignedUrlS3($filename: String!, $filepath: String!, $bucket: String!) {
    getPresignedUrlS3(filename: $filename, filepath: $filepath, bucket: $bucket)
  }
`;

export const getAllGiftCardOffers = /* GraphQL */ `
query GetAllGiftCardOffers{
  getAllGiftCardOffers {
    brandName
    id
    networkName
    offerId
    presetAmounts
    activationSettings {
      isVariableValue
      maxActivationAmount
      minActivationAmount
      forcedMaxActivationAmount
      forcedMinActivationAmount
    }
    images {
      imageUrl
      imageType
      imageSize
    }
  }
}`

export const getPartnerStatistics = /* GraphQL */ `
  query GetPartnerStatistics {
    getPartnerStatistics {
      current {
        date
        totalRewardFiatValue
        numTeamMembers
        numActiveMembers
        numPerkPartners
        numAirdrops
        popularBrands {
          brandName
          numSales
        }
      }
      previous {
        date
        totalRewardFiatValue
        numTeamMembers
        numActiveMembers
        numPerkPartners
        numAirdrops
        popularBrands {
          brandName
          numSales
        }
      }
    }
  }`;

export const getPartnerAirdropTransactions = /* GraphQL */ `
query GetAirdropTransactions {
  getAirdropTransactions {
    userId
    externalRef {
      type
      data {
        totalSaleAmount
        billingInformation {
          partner_receipt_url
        }
        externalData {
          brandName
        }
      }
    }
  }
}
`
