import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import Background1x from "./new-campaign-1x.png";
import Background2x from "./new-campaign-2x.png";

export const NewCampaignImage = ({
  height,
  width,
  highResolution,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <div style={{ height, width }}>
      <ImageTemplate
        {...props}
        imgSrc={highResolution ? Background1x : Background2x}
      />
    </div>
  );
};
