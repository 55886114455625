import { Routes, Route } from "react-router-dom";
import { Page } from ".";

export const SignInRouter = (): JSX.Element => {
  return (
    <Routes>
      <Route path="*" element={<Page variant="root" />} />
      <Route
        path="forgot-password"
        element={<Page variant="forgot-password" />}
      />
      <Route
        path="reset-password"
        element={<Page variant="reset-password" />}
      />
      <Route
        path="force-reset-password"
        element={<Page variant="force-reset-password" />}
      />      
      <Route path="pending" element={<Page variant="pending" />} />
    </Routes>
  );
};
