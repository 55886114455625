import { FormEvent } from "react";
import { FormikErrors } from "formik";
import { Button, Image, Input } from "@atoms";

export interface FormValues {
  emailAddress: string;
}

export type ForgotPasswordPageTemplateProps = {
  values: FormValues;
  errors: FormikErrors<FormValues>;
  loading: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSignInRedirect: () => void;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
};

export const ForgotPasswordPageTemplate: React.FC<ForgotPasswordPageTemplateProps> = ({
  values,
  errors,
  loading,
  handleInputChange,
  handleSignInRedirect,
  handleSubmit,
}: ForgotPasswordPageTemplateProps): JSX.Element => {
  const { emailAddress } = values;
  const { emailAddress: emailAddressError } = errors;

  return (
    <div className="min-h-screen flex">
      <div className="relative hidden lg:block min-h-screen w-6/10">
        <div className="absolute top-6 left-6">
          <Image variant="logoWhite" />
        </div>
        <div style={{ backgroundColor: "#ed2828" }} className="w-full h-full">
          <Image variant="backgroundPhone" width={827} height={775} />
        </div>
      </div>
      <div className="relative w-full min-h-screen lg:w-4/10 lg:flex justify-center items-center">
        <div className="lg:hidden absolute top-6 left-6">
          <Image variant="logoColour" />
        </div>
        <form
          className="text-center flex flex-col items-center max-w-xs mx-auto px-4"
          style={{marginTop: -110}}
          onSubmit={handleSubmit}
        >
          <div className="px-3 mb-2">
            <div className="w-72">
              <h3 className="text-3xl font-black">Forgot Password?</h3>
            </div>
          </div>
          <div className="px-3 mb-6">
            <div className="mb-2">
              <h3 className="text-md">
                Enter your Coinmiles Partners email
              </h3>
            </div>
          </div>
          <div className="w-full">
            <div className="mb-2">
              <Input
                name="emailAddress"
                variant="text"
                onChange={handleInputChange}
                placeholder="Email address"
                value={emailAddress}
                errorMessage={emailAddressError}
              />
            </div>
          </div>
          <div className="mt-2 mb-6 w-full sm:mb-4">
            <div>
              <Button
                type="submit"
                variant="primary"
                label="Submit"
                stretch
                color="secondary-medium"
                hoverColor="secondary-dark"
                textColor="white"
                // brightness={500}
                loading={loading}
              />
            </div>
          </div>
          <div className="mb-6 w-full sm:mb-4">
            <div>
              <Button
                variant="secondary"
                label="Back to Sign In"
                stretch
                color="#ffffff"
                brightness={500}
                onClick={handleSignInRedirect}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
