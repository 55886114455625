import { PagePaths } from "../../../types";

const BlogTypes = {
  bitcoin: "Bitcoin & Crypto",
  coinmiles: "Coinmiles"
}

export const blogData = 
  {
    [PagePaths.home]: [
        {
          image: "howToEarn",
          date: "2024-05-02",
          blogTypeText: BlogTypes.bitcoin,
          title: "How to Earn Rewards with Coinmiles+",
          description: "Learn how to earn your first rewards.",
          blogUrl: "https://coinmiles.io/blog/how-to-earn-rewards-with-coinmiles",
        },
      ],
    [PagePaths.cashbackRewards]: [
        {
          image: "boostActivatePerks",
          date: "2022-05-09",
          blogTypeText: BlogTypes.coinmiles,
          title: "Boosts: Activate your Perks",
          description: "Introducing Boosts, your best tool to increase adoption.",
          blogUrl: "https://coinmiles.io/blog/activate-with-boosts",
        },
      ]  
    }

