import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, FormikErrors } from "formik";

import { useSignOut, useSignUp, useUser } from "@auth";
import { FormValues, WelcomePageTemplateProps } from "./template";

import { Auth } from "aws-amplify";
import { AuthContext } from "../../../auth/auth-context";
import { useUpdatePartner } from "@mutations";

export const useWelcomePage = (): WelcomePageTemplateProps => {
  const navigate = useNavigate();
  const partner = useUser();
  const signOut = useSignOut();
  const { newPartnerInfo } = useContext(AuthContext);
  const updatePartner = useUpdatePartner();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);

  useEffect(() => {
    console.log("partner", partner);
    if(partner && partner !== null) {
      setPageIsLoading(false);

    }
    if (partner && partner.status === "PartnerApproved") {
      navigate("/dashboard/");
    }
  }, [partner]);

  const validate = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.description) {
      errors.description = "Description is required";
    }
    if (!values.organization) {
      errors.organization = "Organization is required";
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    }
    if (!values.website) {
      errors.website = "Website URL is required";
    }

    return errors;
  };

  // regex to remove all non-numeric digits then add a "+" to the front, then it looks like Cognito will magically add the country code, so turns the "+" into a "+1"
  const formatPhoneNumber = (value: string): string => {
    return `+${value.replace(/\D+/g, "")}`;
  };

  const { values, errors, handleChange, handleSubmit, setFieldError } =
    useFormik<FormValues>({
      initialValues: {
        description: "",
        organization: "",
        phoneNumber: "",
        website: "",
      },
      validate,
      validateOnChange: false,
      onSubmit: async (values: FormValues) => {
        setLoading(true);
        const extraInfo = {
          customDescription: values.description,
          customOrganization: values.organization,
          customPhoneNumber: formatPhoneNumber(values.phoneNumber),
          customWebsite: values.website,
        };

        try {
          console.log({ ...newPartnerInfo, ...extraInfo });
          const { username } = await Auth.currentAuthenticatedUser();
          console.log(
            `Updating partner ${username} with values: ${JSON.stringify(
              values,
            )}...`,
          );

          await updatePartner(values, username, true);
          // navigate("/dashboard/");
        } catch (error: any) {
          setLoading(false);
          if (error.code === "InvalidParameterException") {
            setFieldError(
              "phoneNumber",
              "Please enter a phone number with a valid format, it will usually begin with +1",
            );
          } else {
            console.log(error);
          }
        }
      },
    });

  const handleBackButtonClick = async () => {
    await signOut();
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e);

  return {
    values,
    errors: {
      organization: errors.organization,
      website: errors.website,
      phoneNumber: errors.phoneNumber,
      description: errors.description,
    },
    loading,
    handleBackButtonClick,
    handleInputChange,
    handleSubmit,
    partner,
    pageIsLoading,
  };
};
