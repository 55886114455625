import { useState } from "react";
import { useFormik, FormikErrors } from "formik";
import { useForgotPassword } from "@auth";
import { useNavigate } from "react-router-dom";

import { FormValues, ForgotPasswordPageTemplateProps } from "./template";

export const useForgotPasswordPage = (): ForgotPasswordPageTemplateProps => {
  const forgotPassword = useForgotPassword();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const validate = (
    values: FormValues,
  ): FormikErrors<FormValues> | undefined => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.emailAddress) {
      errors.emailAddress = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)
    ) {
      errors.emailAddress = "Invalid email address";
    }

    return errors;
  };
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldError,
  } = useFormik({
    initialValues: {
      emailAddress: "",
    },
    validate,
    validateOnChange: false,
    onSubmit: async ({ emailAddress }: FormValues) => {
      setLoading(true);
      try {
        await forgotPassword(emailAddress);
        navigate("/signin/reset-password", { state: { email: emailAddress } });
      } catch (error) {
        setLoading(false);
        setFieldError("emailAddress", "Something went wrong");
      }
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e);
  const handleSignInRedirect = () => navigate("/signin");

  return {
    values,
    errors,
    loading,
    handleInputChange,
    handleSignInRedirect,
    handleSubmit,
  };
};
