import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext, useUser } from "@auth";
import { useGetPartnerStatistics } from "@queries";
import { todos, blogData } from "./card-data";
import { HomePageTemplateProps } from "./template";
import { useCurrencyUtils } from "../../../utils"; // todo: implement @ pathing for utils
import { DashboardWidgets } from "../../../types"; // todo: implement @ pathing

export const useHomePage = (): HomePageTemplateProps => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { selectedPageIndex, setSelectedPageIndex, partnerStatistics, isSetupComplete, calculateIsSetupComplete } = useContext(AuthContext);
  const navigate = useNavigate();
  const partner = useUser();
  const { getPartnerStatistics } = useGetPartnerStatistics();
  const { convertToCurrencyFormat } = useCurrencyUtils();
  
  useEffect(() => {
    calculateIsSetupComplete();
    getPartnerStatistics();
  }, []);

  useEffect(() => {
    console.log(JSON.stringify(partnerStatistics, null, 4));
  }, [partnerStatistics]);
 
  const navigateToPage = (index: number, to: string) => {
    setSelectedPageIndex(index);
    navigate(to);
  }

  useEffect(() => {
    console.log("Home page isSetupComplete: ", isSetupComplete);
  }, [isSetupComplete]);
  
  const getWidgetTitle = (widgetType: DashboardWidgets): string => {
    switch (widgetType) {
      case DashboardWidgets.teamMembers:
        return "Team Members"
      case DashboardWidgets.activeMembers:
        return "Active Members"
      case DashboardWidgets.totalRewards:
        return "Total Rewards"
      case DashboardWidgets.airdrops:
        return "Airdrops"   
      case DashboardWidgets.perksPartners:
        return "Perks Partners"               
      default:
        console.log("ERROR: Invalid widget type.");
        return "";
    }
  }

  const getWidgetValue = (widgetType: DashboardWidgets): string => {
    const data = partnerStatistics?.current;

    switch (widgetType) {
      case DashboardWidgets.teamMembers:
        // for teamMembers, even though this value is returned from the server, we'll use the local value since it's possibly more up-to-date
        return partner?.premiumSettings?.[0]?.users ? partner?.premiumSettings?.[0]?.users?.length.toString() : "--";
      case DashboardWidgets.activeMembers:
        // Note: instead of showing the "active" users (i.e. those who've logged in recently) we're shwoing the "activated" users (i.e. team members that have accepted the partner invitation)
        //  data?.numActiveMembers != null ? data.numActiveMembers?.toString() : "--";
        let result;
        if (partner?.premiumSettings?.[0]?.users) {
          result = partner?.premiumSettings?.[0]?.users?.filter(user => user.userId !== "").length.toString();
          return result;
        } else {
          return "--"
        }
      case DashboardWidgets.totalRewards:
        return data?.totalRewardFiatValue != null ? convertToCurrencyFormat(data.totalRewardFiatValue) : "--";
      case DashboardWidgets.airdrops:
        return data?.numAirdrops != null ? data.numAirdrops?.toString() : "--";
      case DashboardWidgets.perksPartners:
        return "1978";
        // return data?.perksPartners != null ? data.perksPartners?.toString() : "--";       
      default:
        console.log("ERROR: Invalid widget type.");
        return "";
    }
  }

  const getPopularBrands = (): any => {
    return [];
  }

  return { 
    todos,
    blogData,
    partner,
    navigateToPage,
    isSetupComplete,
    isLoading,
    getWidgetTitle,
    getWidgetValue,
    getPopularBrands,
   };
};
