export type PaymentMethodCardTemplateProps = {
  title: string;
  onClick: (() => void)| undefined;
  buttonText?: string;
  icon: JSX.Element;
  iconSubtitle: string;
  hasTransactionFee: boolean;
  hasAddButton: boolean;
  data: any;
  selectedIndex?: number;
};

export const PaymentMethodCardTemplate = ({
  onClick,
  icon,
  iconSubtitle,
  data,
  selectedIndex
}: PaymentMethodCardTemplateProps): JSX.Element => {
  return (
    <div className="w-full">
      {data?.length === 0 ?
        <div className="flex flex-col items-center h-20 justify-evenly">
          {icon}
          {iconSubtitle}
        </div>
        :
        data?.length > 0 ?
          <div className={"w-full py-1"}>
            <div className={`flex flex-row w-full items-center justify-start pl-2 border-b border-neutral-light`}>
              <div className={"w-1/8 p-1 pr-9 font-semibold"}>{`Card#`}</div>
              <div className={"w-1/8 p-1 pr-4 font-semibold"}>{`Expiry`}</div>
              <div className={"w-1/8 p-1 pr-4 text-green-600"}></div>
              <div className={"w-1/8 p-1 pr-4 text-red-500"}></div>
            </div>
            {data?.map((card: any, index: number) => {
              return (
                <div key={card.lastFourDigits} className={`flex flex-row items-center justify-start pl-2 border-b border-neutral-light ${index === selectedIndex && "bg-successful-lightest"}`}>
                  <div className={"w-1/8 p-1 pr-4"}>{`**** ${card.lastFourDigits}`}</div>
                  <div className={"w-1/8 p-1 pr-4"}>{`${card.expiryMonth}/${card.expiryYear}`}</div>
                  {/* <button className={"w-1/8 p-1 pr-4"} onClick={() => {console.log("click")}}>
                    <div className={"w-1/8 p-1 pr-4 text-green-600"}>Select</div>
                  </button>
                  <button className={"w-1/8 p-1 pr-4"} onClick={() => {console.log("click")}}>
                    <div className={"p-1 pr-4 text-red-500"}>Delete</div>
                  </button> */}
                </div>
              )
            })}
          </div>
        :
          <div className="pt-2 pl-2">
            Loading cards...
          </div>
      }
    </div>
  );
};
