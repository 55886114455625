import { Image, ImageVariantType } from "@atoms";

export type RadioButtonTemplateProps = {
  image: ImageVariantType,
  onClick: (isSelected: boolean) => void,
  isSelected: boolean
};

export const RadioButtonTemplate = ({
  image,
  onClick,
  isSelected
}: RadioButtonTemplateProps): JSX.Element => {  
  return (
    // <button
    //   onClick={() => onClick(isSelected)}
    // >
    // Note: this is currently not a button, but is controlled via a parent button. Should refactor.
    <div>
      {isSelected ?
        <div className={`h-8 w-8`}>
          <Image variant={image} />
        </div>
        :
        <div className="border rounded-full h-6 w-6 m-1"></div>
       }
    </div>
  );
};
