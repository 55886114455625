import { useContext, useCallback, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useUser } from "@auth";
import { Page } from ".";
import { Elements } from '@stripe/react-stripe-js';
import { AuthContext } from "../../auth/auth-context";

export const DashboardRouter = (): JSX.Element => {
  const partner = useUser();
  const {clientSecret, stripePromise} = useContext(AuthContext);
  
  const isClientInitialized = clientSecret !== null && clientSecret !== undefined && clientSecret !== "" && stripePromise !== undefined;
  
  const options = {
    clientSecret: clientSecret
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          /* !partner?.didLogin || !partner?.organization ? (
            <Page variant="welcome" />
          ) : ( */
            <Page variant="home" />
          /* ) */
        }
      />
      <Route path="welcome" element={<Page variant="welcome" />} />
      <Route path="cashback-rewards" element={<Page variant="cashback-rewards" />} />
      <Route path="airdrop">
        <Route path="complete" element={<Page variant="airdrop-complete" />} />
        <Route path="confirm" element={<Page variant="airdrop-confirm" />} />
        <Route path="history" element={<Page variant="airdrop-history" />} />
        <Route path="main" element={<Page variant="airdrop" />} />
        <Route path="select-members" element={<Page variant="select-members" />} />
        <Route path="select-offers" element={<Page variant="select-offers" />} />
        <Route path="select-amount" element={<Page variant="select-amount" />} />
      </Route>
      <Route path="celebrations">
        <Route path="main" element={<Page variant="celebrations-main" />} />
        <Route path="birthdays" element={<Page variant="celebrations-birthday" />} />
        <Route path="holidays" element={<Page variant="celebrations-holiday" />} />
        <Route path="seniority" element={<Page variant="celebrations-seniority" />} />
        <Route path="custom" element={<Page variant="celebrations-custom" />} />
      </Route>
      <Route
        path="home"
        element={
          !partner?.didLogin ? (
            // Here when the partner has not been approved yet: Normally didLogin sets to true ONLY when user approved, to confirm
            /* !partner?.organization ? (
              <Page variant="welcome" />
            ) : ( */
              <Page variant="pendingApproval" />
            /* ) */
          ) : (
            <Page variant="home" />
          )
        }
      />
      <Route path="team" element={<Page variant="team" />} />
      <Route
        path="pendingApproval"
        element={<Page variant="pendingApproval" />}
      />
      <Route path="profile" element={<Page variant="profile" />} />
      <Route path="perks" element={<Page variant="perks" />} />
      <Route path="billing" element={
        !isClientInitialized ? 
          <Page variant="pendingApproval"/>
        : 
          <Elements stripe={stripePromise} options={options}>
            <Page variant="billing" />
          </Elements>
        } />
    </Routes>
  );
};
