import { Layout } from "@layouts";
import { Card, Item } from "@molecules";
import { useState, useContext } from "react";
import { AuthContext } from "@auth";
import { Button, ChipTypes, TextChip } from "@atoms";
import { Input as SInput, Progress, Button as SButton, Switch } from "@shadcn";
import { Bar, BlogSection } from "@organisms";
import { useUpdatePartnerBoostedRewards } from "@mutations";
import { IofferBoost } from "@api";
import * as RSwitch from "@radix-ui/react-switch";
import "./styles.css";

export type CashbackRewardsPageTemplateProps = {
  premiumUsers: any[];
  navigateToPage: (index: number, to: string) => void;
  onReturnPressed: () => void;
};

export const CashbackRewardsPageTemplate = ({
  premiumUsers,
  navigateToPage,
  onReturnPressed,
}: CashbackRewardsPageTemplateProps): JSX.Element => {
  const { partner, isLoadingData, setIsLoadingData } = useContext(AuthContext);
  const updatePartnerBoostedRewards = useUpdatePartnerBoostedRewards();
  const isBoostLimitAlreadyActive = partner?.offerAmountsManagement?.offerBoost
    ?.boostLimitPerUser !== null && partner?.offerAmountsManagement?.offerBoost
    ?.boostLimitPerUser !== undefined
    ? true
    : false;
  const [boostedAmount, setBoostedAmount] = useState<number | null | undefined>(
    partner?.offerAmountsManagement?.offerBoost?.boostAmount ?? undefined,
  ); // This will have the percent value stored
  const [boostLimitAmount, setBoostLimitAmount] = useState<number | undefined>(
    partner?.offerAmountsManagement?.offerBoost?.boostLimitPerUser ?? undefined,
  ); // This will have the boost spending limit
  const [boostActivated, setIsBoostActivated] = useState<boolean>(
    partner?.offerAmountsManagement?.offerBoost?.isBoostingAllOffers ?? false,
  ); // This will have to have init val matching the backend stored value
  const [boostLimitActivated, setBoostLimitActivated] = useState<boolean>(
    isBoostLimitAlreadyActive,
  ); // This will have to have init val matching the backend stored value

  const isBoostAmountIdenticalToDB = boostedAmount ===
  partner?.offerAmountsManagement?.offerBoost?.boostAmount;
  const isBoostActivatedIdenticalToDB = boostActivated ===
  partner?.offerAmountsManagement?.offerBoost?.isBoostingAllOffers || (boostActivated === false && !partner?.offerAmountsManagement?.offerBoost?.isBoostingAllOffers);
  const isBoostLimitActiveIdenticalToDB = (boostLimitAmount ===
    partner?.offerAmountsManagement?.offerBoost?.boostLimitPerUser || (!boostLimitAmount && partner?.offerAmountsManagement?.offerBoost?.boostLimitPerUser === null));
  const isValuesIdenticalToDB =
  isBoostAmountIdenticalToDB &&
  isBoostActivatedIdenticalToDB &&
  isBoostLimitActiveIdenticalToDB;

  console.log(isBoostAmountIdenticalToDB + "\n" + isBoostActivatedIdenticalToDB + "\n" + isBoostLimitActiveIdenticalToDB + boostLimitAmount + partner?.offerAmountsManagement?.offerBoost?.boostLimitPerUser);

  const onBoostChange = () => {
    if (boostActivated) {
      setIsBoostActivated(false);
      setBoostLimitAmount(undefined);
      setBoostLimitActivated(false);
    } else {
      setIsBoostActivated(true);
      setBoostedAmount(partner?.offerAmountsManagement?.offerBoost?.boostAmount ?? 5)
    }
  };

  const onBoostLimitChange = () => {
    if (boostLimitActivated) {
      setBoostLimitActivated(false);
      setBoostLimitAmount(undefined);
    } else {
      setBoostLimitActivated(true);
      setBoostLimitAmount(100);
    }
  };

  const onPressConfirmBoostChoices = async () => {
    setIsLoadingData(true);
    const offerBoost: IofferBoost = {
      boostAmount: boostedAmount ?? 0,
      isBoostingAllOffers: boostActivated,
      boostLimitPerUser: boostLimitAmount,
    }
    await updatePartnerBoostedRewards(offerBoost);
  };

  return (
    <Layout
      variant="dashboard"
      header="Cashback Rewards"
      subheader={
        <div className="flex flex-row items-start justify-between max-w-5xl">
          <div className="text-neutral-dark text-base font-normal mt-2">
            Manage your rewards program here
          </div>
          <div className="pr-4">
            <Item variant="beta" />
          </div>
        </div>
      }
    >
      <div className="p-1 rounded-md max-w-5xl">
        <div className="mb-8 mt-4">

          <div className="border border-neutral-light rounded-lg p-5 w-2/3">

            <h2 className="text-xl font-normal">Boost Rewards 🔥</h2>
            <div className="flex flex-row my-4 items-center">
              <RSwitch.Root
                className="SwitchRoot"
                id="boost-activated"
                checked={boostActivated}
                onCheckedChange={onBoostChange}
              >
                <RSwitch.Thumb className="SwitchThumb" />
              </RSwitch.Root>
              <div className="ml-2 flex flex-row items-center text-sm">
                Boost offers by
                <span className="flex flex-row">
                  <SInput
                    type="number"
                    lang="en-US"
                    placeholder="Amount"
                    step="1"
                    value={boostedAmount || 5}
                    onChange={(event) => {
                      setBoostedAmount(Number(event?.target.value));
                    }}
                    className="h-12 w-16 bg-white rounded-md ml-2 text-lg"
                  />
                </span>
                <div className="ml-1">%</div>
                {/* <div className="ml-4">
                  <TextChip type={ChipTypes.popular} />
                </div> */}
              </div>
            </div>
            <div className="text-sm">
              Activating this feature will enable your team to earn higher rewards
              when they shop with partner's brands (billed monthly to your
              organisation).
            </div>
            <div className="flex flex-row mb-4 mt-6 items-center">
              <RSwitch.Root
                className="SwitchRoot"
                id="boost-limit"
                disabled={false}
                checked={boostLimitActivated}
                onCheckedChange={onBoostLimitChange}
              >
                <RSwitch.Thumb className="SwitchThumb" />
              </RSwitch.Root>
              <div className="ml-2 flex flex-row items-center text-sm">
                Set Boost Limit  $
                <SInput
                  type="number"
                  lang="en-US"
                  placeholder="Amount"
                  step="1"
                  min={100}
                  value={boostLimitAmount || 100}
                  onChange={(event) => {
                    setBoostLimitAmount(Number(event?.target.value));
                  }}
                  disabled={!boostLimitActivated}
                  className="h-12 w-20 bg-white rounded-md ml-2 text-lg"
                />
              </div>
            </div>
            <div className="text-sm">
              Set a yearly maximum boost amount per employee.
            </div>

            <div className="w-64 pt-8">
              <Button
                onClick={
                  isLoadingData
                    ? () => {}
                    : onPressConfirmBoostChoices
                }
                variant="primary"
                label={"Confirm Changes"}
                //withIcon={"rocket"}
                stretch
                textWeight={"semibold"}
                color="primary-medium"
                loading={isLoadingData}
                disabled={isValuesIdenticalToDB}
                textSize="sm"
              />
            </div>
          </div>
          

        </div>
        <div className="pt-1 mb-16">
          <BlogSection page={"cashbackRewards"} />
        </div>
        <Bar
          variant="bottom"
          onReturnPressed={onReturnPressed}
          onContinuePressed={null}
          isContinueDisabled={true}
        />
        {/* <div className="flex items-center justify-between sticky bottom-0 bg-white pt-6 pb-16">
          <SButton
            variant="outline"
            className="hover:bg-gray-100 w-28"
            onClick={onReturnPressed}
          >
            Go back
          </SButton>
        </div> */}
      </div>
    </Layout>
  );
};
