import { Layout } from "@layouts";
import { Item, airdropConfirmationColumns } from "@molecules";
import { Bar, DataTable } from "@organisms";
import { Button as SButton, Progress } from "@shadcn";
import { useLocation, useNavigate } from "react-router";
import { useContext, useEffect, useState } from "react";
import { GiftCardOffer } from "../../../../types";
import { CurrencyTypes, PremiumGiftCardAirdropRequest } from "@api";
import { AuthContext, useUser } from "@auth";
import ReactModal from "react-modal";
import { Button, IconVariantType } from "@atoms";
import { styles as globalStyles } from "../../../../styles/styles";

export type AirdropConfirmPageTemplateProps = {
  progress: number;
  onReturnPressed: () => void;
  airdropGiftCard: (
    airdropGiftCardRequest: PremiumGiftCardAirdropRequest,
    paymentId: string,
  ) => Promise<void>;
};

export const AirdropConfirmPageTemplate = ({
  progress,
  airdropGiftCard,
}: AirdropConfirmPageTemplateProps) => {
  const { billingDetails, isLoadingData, setIsLoadingData, partner } =
    useContext(AuthContext);
  const [isAttemptingAirdropGiftCard, setIsAttemptingAirdropGiftCard] =
    useState(false);
  const [airdropCompleted, setIsAirdropCompleted] = useState(false);
  const [billingInformationFound, setBillingInformationFound] = useState(billingDetails !== null && billingDetails !== undefined);
  const location = useLocation();
  const navigate = useNavigate();
  const assembledData = location.state?.assembledData;
  const [selectedUser] = [assembledData.combinedData.selectedUser];
  const [amount] = [assembledData.amount];
  const [message] = [assembledData.message];
  const [offerSelected] = [assembledData.combinedData.offerSelected];
  const [creditCards, setCreditCards] = useState<boolean>(billingDetails?.creditCards?.length === undefined ? false : billingDetails?.creditCards?.length > 0);
  const { TeamTable } = DataTable({
    columns: airdropConfirmationColumns,
    data: [selectedUser],
    searchable: false,
  });

  console.log("message: ", message);

  useEffect(() => {
    billingDetails?.creditCards ? setBillingInformationFound(true) : setBillingInformationFound(false)
  }, [billingDetails])

  const navigateBackto = (to: string) => {
    const combinedData = { offerSelected, selectedUser};
    navigate(to, {state: {combinedData}})
};

// Temporarily added the onReturnPressed function in the template file to test out bugs regarding returning to prev pages
  const onReturnPressed = () => {
    const to = "/dashboard/airdrop/select-amount";
    navigateBackto(to);
}

  const onIsAirdropAttempted = () => {
    setIsAttemptingAirdropGiftCard(true);
  };

  const closeAirdropModal = () => {
    setIsAttemptingAirdropGiftCard(false);
    setIsAirdropCompleted(false);
  };

  const onPressAirdrop = async () => {
    setIsLoadingData(true);
    try {
      const premiumGiftCardAirdropRequest: PremiumGiftCardAirdropRequest = {
        giftCardOfferObjectId: offerSelected?.id,
        amount,
        network: offerSelected?.networkName,
        currency: CurrencyTypes.CAD,
        premiumUserId: selectedUser?.userId,
        partnerName: partner?.organization ?? "",
        partnerReceipt: undefined,
        giftCardAirdropMessage: message,
      };
      await airdropGiftCard(
        premiumGiftCardAirdropRequest,
        billingDetails?.creditCards?.[0]?.paymentId ?? "",
      )
      setIsAirdropCompleted(true);
      return;
    } catch (error) {
      console.log("Failed Airdrop: " + JSON.stringify(error));
    }
    
  };
  return (
    <Layout
      variant="dashboard"
      header="Airdrops"
      subheader={
        <div className="flex flex-row items-start justify-between max-w-5xl">
          <div className="text-neutral-dark text-base font-normal mt-2">
            Send Gift Cards to your employees
          </div>
        </div>
      }
    >
      <div className="px-1 mr-4 rounded-md max-w-5xl">
       {/*  <div className="flex items-center justify-center">
          <Progress value={progress} className="w-1/2" />
        </div> */}
        <div className="mt-4 mb-4">
          <h2 className="text-xl font-normal">
            4. Confirm your Airdrop
          </h2>
        </div>
        <div className={`flex flex-row justify-start p-4 max-w-5xl bg-gray-100 rounded-md mb-16`}>
          <div className="rounded-md my-2 mx-2 justify-center min-w-max">
            <img
              src={offerSelected?.images?.[0]?.imageUrl}
              className="rounded-md h-48 w-80"
            />
          </div>
          <div className="flex flex-col justify-between m-2 pb-2 rounded-md">
            <div className="text-2xl">
              {"You are"} <b>{` sending a $${amount} ${offerSelected?.brandName}`}</b> {" Gift Card to"} <b className="font-semibold">{` ${selectedUser?.firstName} ${selectedUser?.lastName} `}</b>
            </div>
            {message &&
              <div>
                {`"${message}"`}
              </div>
            }
            <div className="">
              <div className="color-neutral-dark text-xs pb-2">
                {`Email: ${selectedUser?.emailAddress} | Payment: ${billingDetails?.creditCards?.[0]?.cardBrand} ending in ${billingDetails?.creditCards?.[0]?.lastFourDigits}`}
              </div>
              <div className="color-neutral-dark text-xs">
                {`${selectedUser?.firstName} will receive a push and email notification (if enabled). Instructions on how to use the gift card will be provided.`}
              </div>
            </div>
          </div>
        </div>
        <Bar
          variant="bottom"
          onReturnPressed={onReturnPressed}
          onContinuePressed={onIsAirdropAttempted}
          isContinueDisabled={!billingInformationFound}
          altContinueText={"Send Airdrop"}
        />

        {/* confirm modal */}
        {isAttemptingAirdropGiftCard ? (
          <ReactModal
            isOpen={isAttemptingAirdropGiftCard}
            onRequestClose={closeAirdropModal}
            style={globalStyles.generalModal}
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}
          >
            <div>
              <div className="flex flex-col items-center">
                <div className="font-semibold text-2xl flex flex-row justify-center py-4">
                  Confirm Airdrop
                </div>
                <div className="mb-5 flex flex-row justify-center w-60 text-center">
                  {`Please confirm your $${amount} ${offerSelected?.brandName} gift card for ${selectedUser?.firstName}.`}
                </div>
                <div className="flex flex-row justify-center">
                  <div className="w-64">
                    <Button
                      onClick={
                        isLoadingData
                          ? () => {}
                          : onPressAirdrop
                      }
                      variant="primary"
                      label={"Confirm"}
                      withIcon={"rocket"}
                      stretch
                      textWeight={"semibold"}
                      color="primary-medium"
                      loading={isLoadingData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ReactModal>
        ) : null}
      </div>
    </Layout>
  );
};
