import { useUser } from "@auth";
import { CashbackRewardsPageTemplateProps } from "./template";
import { useNavigate } from "react-router-dom";

export const useCashbackRewardsPage = (): CashbackRewardsPageTemplateProps => {
    const navigate = useNavigate();
    const partner = useUser();
    

    const navigateToPage = (index: number, to: string) => {
        navigate(to);
      }
      const navigateBackTo = (to: string) => {
        navigate(to);
      }
    
      const onReturnPressed = () => {
        navigateBackTo("/dashboard/perks");
    }
    return {
        premiumUsers: partner?.premiumSettings?.[0]?.users.filter(item => item.userId !== "") || [],
        navigateToPage,
        onReturnPressed,
    }
}