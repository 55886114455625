import { useState } from "react";
import { useFormik, FormikErrors } from "formik";
import { useResetPassword } from "@auth";
import { useLocation, useNavigate } from "react-router-dom";

import { FormValues, ResetPasswordPageTemplateProps } from "./template";

interface EmailState {
  email: string;
}

export const useResetPasswordPage = (): ResetPasswordPageTemplateProps => {
  const { state } = useLocation();
  const { email } = state as EmailState;
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const resetPassword = useResetPassword();
  const navigate = useNavigate();

  const validate = (
    values: FormValues,
  ): FormikErrors<FormValues> | undefined => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.code) {
      errors.code = "Code is required";
    }
    if (!values.password) {
      errors.password = "Password is required";
    } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(values.password)) {
      errors.password =
        "Password must contain one upper case character, one lower case character and one number";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Password confirmation is required";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Password confirmation must match password";
    }

    return errors;
  };

  const backToSignIn = () => {
    navigate("/signin");
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldError,
  } = useFormik({
    initialValues: {
      code: "",
      password: "",
      confirmPassword: "",
    },
    validate,
    validateOnChange: false,
    onSubmit: async ({ code, password }: FormValues) => {
      try {
        setLoading(true);
        await resetPassword({ code, password, email });
        setSuccess(true)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setFieldError("emailAddress", "Something went wrong");
      }
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e);

  return {
    values,
    errors,
    handleInputChange,
    handleSubmit,
    backToSignIn,
    loading,
    success
  };
};
