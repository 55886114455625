import { RewardTypes } from "../../../types";

export type PartnerReward = {
  type: RewardTypes;
  title: string;
  fullTitle: string;
  subtitle: string;
  description: string;
  icon: string;
  isAvailable: boolean;
}

export const rewardsData = [
  {
    type: "reward_btc",
    title: "Bitcoin",
    fullTitle: "Bitcoin",
    subtitle: "Collecting Sats",
    description: "Everybody loves Bitcoin.",
    icon: "bitcoinLogo",
    isAvailable: true
  },
  {
    type: "reward_cad",
    title: "$CAD",
    fullTitle: "Canadian Dollars",
    subtitle: "Nothing but cold hard cash!",
    description: "Reward your users the classic way, with regular Canadian cash.",
    icon: "flagCanada",
    isAvailable: true
  },
  {
    type: "reward_amazon",
    title: "Amazon",
    fullTitle: "Amazon",
    subtitle: "Special Amazon points",
    description: "Earn Amazon Dollars, spendable on the world's biggest shopping platform.",
    icon: "amazonLogo",
    isAvailable: false
  }           
];