/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited (Matt says: in theory at least!)


export type PremiumUser = {
  firstName: string,
  lastName: string,
  emailAddress: string,
  userId: string,
  employmentStartDate: Date,
  birthday: Date,
};

export type PremiumSettings = {
  users: PremiumUser[];
  availableSeats: number;
  percentage: number;
}

export type PremiumImages = {
  logoUrl?: string
  backgroundUrl?: string
}

export type PartnerUpdate = {
  description?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  organization?: string | null,
  phoneNumber?: string | null,
  website?: string | null,
  images?: PremiumImages | null
};

export type PartnerAddress = {
  street: string
  city: string
  province: string
  country: string
  postalCode: string
}

export type Partner = {
  __typename: "Partner",
  id: string,
  emailAddress: string,
  firstName: string,
  lastName: string,
  username: string,
  brandIds: Array< string | null >,
  status?: string,
  didLogin?: boolean | null,
  description?: string | null,
  address?: PartnerAddress | null,
  organization?: string | null,
  phoneNumber?: string | null,
  website?: string | null,
  premiumSettings: PremiumSettings[],
  offerAmountsManagement: OfferAmountsManagement;
  images?: PremiumImages | null
};

export type PremiumGiftCardAirdropRequest = {
	giftCardOfferObjectId: string,
	amount: number,
	network: string,
	currency: CurrencyTypes,
	premiumUserId: string,
  partnerName?: string,
  partnerReceipt?: string,
  giftCardAirdropMessage?: string,
}

export type OfferAmountsManagement = {
  offerBoost: IofferBoost;
}

export type IofferBoost = {
  isBoostingAllOffers?: boolean,
  boostAmount?: number,
  boostLimitPerUser?: number,
}

export enum CurrencyTypes {
  CAD = "CAD",
  USD = "USD",

}

