import { useEffect } from "react";
import ReactModal from 'react-modal';

import { Layout } from "@layouts";
import { Button, Icon, Image } from "@atoms";
import { Card } from "@molecules";
import { rewardsData } from "./rewards-data";
import { perksData } from "./perks-data";
import { PerkTypes, RewardTypes } from "../../../types";
import { styles as globalStyles } from "../../../styles/styles";
import { ItemDetails } from "./item-details";
import { InfoDetailsModalType } from "./item-details";

export type PerksPageTemplateProps = {
  selectedRewards: any;
  selectedPerks: any;
  onClickReward: (type: RewardTypes) => void;
  onClickPerk: (type: PerkTypes) => void;
  isInfoModalOpen: boolean;
  openInfoModal: (type: RewardTypes | PerkTypes) => void;
  closeInfoModal: () => void;
  closeAndAccept: () => void;
  selectedItemType: RewardTypes | PerkTypes | null;
  isSelectedItemEnabled: boolean;
  modalType: InfoDetailsModalType;
  navigateToPage: (to: string, index?: number) => void;
};

export const PerksPageTemplate = ({
  selectedRewards,
  selectedPerks,
  onClickReward,
  onClickPerk,
  isInfoModalOpen,
  openInfoModal,
  closeInfoModal,
  closeAndAccept,
  selectedItemType,
  isSelectedItemEnabled,
  modalType
}: PerksPageTemplateProps): JSX.Element => {

  useEffect(() => {
  }, []);

  return (
    <Layout
      variant="dashboard"
      header="My Perks Program"
      subheader={
        <div className="text-neutral-dark text-base font-normal mt-2">
          Create and manage your perks program here
        </div>
      }
    >
      <div className="flex flex-col justify-between max-w-full w-full">
        {/* <div>
          <div className="text-neutral-darkest text-xl font-normal mt-7">
            Rewards Offered
          </div>
          <div className="text-neutral-dark text-base font-normal mt-2">
            Pick one or many
          </div>
          <div className="flex flex-row pt-6">
            {rewardsData.map((reward: any, index: number) => 
              <div className="pr-5" key={index}>
                <Card
                  variant="reward"
                  onClick={() => onClickReward(reward.type)}
                  title={reward.title}
                  isSelected={selectedRewards[reward.type] === true}
                  isAvailable={reward.isAvailable}
                  icon={<Image variant={reward.icon} highResolution />}
                />
              </div>
            )}
          </div>
        </div> */}
        <div>
          <div className="text-neutral-darkest text-xl font-normal mt-7">
            Perks included in your package
          </div>
          <div className="flex flex-row flex-wrap pt-6 w-full">
            {perksData.filter(item => item.includedInPackage === true).map((perk: any, index: number) => 
              <div className="pr-5 pb-5" key={index}>
                <Card
                  variant="perk"
                  onClick={() => onClickPerk(perk.type)}
                  title={perk.title}
                  text={perk.description}
                  icon={<Icon variant={perk.icon} size={24} />}
                  isSelected={selectedPerks[perk.type] === true}
                  isAvailable={perk.isAvailable}
                  includedInPackage={perk.includedInPackage}
                  isBeta={perk.isBeta}
                />
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="text-neutral-darkest text-xl font-normal mt-7">
            Perks “À la Carte”
          </div>
          <div className="flex flex-row flex-wrap pt-6 pb-16 w-full">
            {perksData.filter(item => item.includedInPackage === false).map((perk: any, index: number) => 
              <div className="pr-5 pb-5" key={index}>
                <Card
                  variant="perk"
                  onClick={() => onClickPerk(perk.type)}
                  title={perk.title}
                  text={perk.description}
                  icon={<Icon variant={perk.icon} size={24} />}
                  isSelected={selectedPerks[perk.type] === true}
                  isAvailable={perk.isAvailable}
                  includedInPackage={perk.includedInPackage}
                />
              </div>
            )}
          </div>          
        </div>
        <div className="z-10">
          <ReactModal
              isOpen={isInfoModalOpen}
              onRequestClose={closeInfoModal}
              style={{ content: {...globalStyles.generalModal.content, width: 480}, overlay: globalStyles.generalModal.overlay }}
              shouldCloseOnOverlayClick={true}
              ariaHideApp={false}
            >
              <ItemDetails 
                closeInfoModal={closeInfoModal}
                closeAndAccept={closeAndAccept}
                itemData={selectedItemType}
                isSelectedItemEnabled={isSelectedItemEnabled}
                modalType={modalType}
              />
            </ReactModal>
          </div>           
      </div>
    </Layout>
  );
};
