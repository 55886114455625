export enum CoinmilesAccountTypes {
  email = "username",
  google = "google",
  facebook = "facebook",
  apple = "apple",
  none = ""
}
  
export type PartnerAddress = {
  street: string;
  city: string;
  country: string;
  province: string;
  postalCode: string;
};

export enum SubscriptionPlansTypes {
  basic = "basic",
  enterprise = "enterprise",
}

export interface CreditCardPaymentMethod {
  cardBrand: string,
  expiryMonth: number,
  expiryYear: number,
  lastFourDigits: string,
  paymentId: string
}

export interface Invoice {
  id: string,
  createdDate: number,
  amount: number,
  downloadUrl: string,
  planType?: string
}

export interface GiftCardActivationSettings {
  isVariableValue: boolean,
	maxActivationAmount: number,
	minActivationAmount: number,
  forcedMaxActivationAmount: number,
  forcedMinActivationAmount: number
}

export interface GiftCardAmountSelectorItem {
  value: string;
  text: string;
}

export interface GiftCardOffer {
  id: string,
	offerId: string,
	brandName: string,
	networkName: string,
	activationSettings: GiftCardActivationSettings,
	presetAmounts: [number]
  images: [Image]
}

interface AirdropTransactionExternalData {
  brandName: string,
  offerId: string,
}

interface AirdropTransactionData {
  externalData: AirdropTransactionExternalData,
  billingInformation?: BillingInformation
}

interface BillingInformation {
  partner_receipt_url?: string,
  user_receipt_url?: string
}

interface AirdropTransactionExternalRef {
  type: string,
  data: AirdropTransactionData,

}

export interface PartnerAirdropTransaction {
  userId: string,
  externalRef: AirdropTransactionExternalRef,
  fullName?: string,
}

export interface IofferBoost {
  isBoostingAllOffers?: boolean,
  boostAmount?: number,
  boostLimitPerUser?: number,
}

interface Image {
	imageUrl: string,
	imageType?: string,
	imageSize?: string
}

export interface BillingDetails {
  invoices: [Invoice] | null,
  creditCards: [CreditCardPaymentMethod] | null,
  planAmount: number | null
}

export enum PagePaths {
  home = "home",
  organization = "profile",
  billing = "billing",
  perks = "perks",
  team = "team",
  airdrop = "airdrop",
  celebrations = "celebrations",
  cashbackRewards = "cashback-rewards"
}

export enum AirdropPagePaths {
  selectMembers = "select-members",
  selectOffers = "select-offers",
  selectAmount = "select-amount",
  confirm = "confirm"
}

// TODO: change to this version

// export enum RewardTypes {
//   BTC = "BTC",
//   CAD = "CAD",
//   USD = "USD",
//   AMZ = "AMZ"
// }

export enum DashboardWidgets {
  teamMembers = "teamMembers",
  activeMembers = "activeMembers",
  sales = "sales",
  totalRewards = "totalRewards",
  airdrops = "airdrops",
  perksPartners = "perksPartners",
  popularBrands = "popularBrands"
}

export interface BrandStats {
  brandName: string,
  numSales: number
}

export interface PartnerStats {
  date?: string,
  numTeamMembers?: number,
  numActiveMembers?: number,
  totalRewardFiatValue?: number,
  numPerkPartners?: number,
  numAirdrops?: number,
  popularBrands?: BrandStats[]
}

export interface PartnerStatsAll {
  current: PartnerStats,
  previous?: PartnerStats,
}

export enum RewardTypes {
  reward_btc = "reward_btc",
  reward_cad = "reward_cad",
  reward_amazon = "reward_amazon"
}

export enum PerkTypes {
    perk_allCashback = "perk_allCashback",
    perk_onlineCashback = "perk_onlineCashback",
    perk_giftCardCashback = "perk_giftCardCashback",
    perk_travelCashback = "perk_travelCashback",
    perk_airdrop = "perk_airdrop",
    perk_celebrations = "perk_celebrations",
    perk_prepaidCard = "perk_prepaidCard",
    perk_onTheGo = "perk_onTheGo",
    perk_roundUp = "perk_roundUp",
    perk_slack = "perk_slack",
    perk_24_7 = "perk_24_7",
    perk_bitcoinPayroll = "perk_bitcoinPayroll",
    perk_customerRewards = "perk_customerRewards"
}
