import { useNavigate } from "react-router-dom";

import { SentPageTemplateProps } from "./template";

export const useSentPage = (): SentPageTemplateProps => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => navigate("/signin");
  const handleSendEmailClick = () => {
    window.open("mailto:affiliate@coinmiles.io", "_blank");
  };

  return {
    handleBackButtonClick,
    handleSendEmailClick,
  };
};
