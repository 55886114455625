import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import VoidCheque1x from "./void-cheque-1x.png";
import VoidCheque2x from "./void-cheque-2x.png";

export const VoidChequeImage = ({
  width,
  height,
  highResolution,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <div style={{ height, width }}>
      <ImageTemplate
        {...props}
        imgSrc={highResolution ? VoidCheque2x : VoidCheque1x}
      />
    </div>
  );
};
