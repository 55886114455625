import { ColumnDef } from "@tanstack/react-table";

import { MoreHorizontal, ArrowUpDown } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Button,
} from "@shadcn";
import { useState } from "react";

export type PremiumUser = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  userId: string;
  employmentStartDate: Date;
  birthday: Date;
};

export interface TeamColumnsProps {
  rowIndex: number | null;
  setRowIndex: (index: number) => void;
  rowData: PremiumUser | null;
  setRowData: (premiumUser: PremiumUser) => void;
  isDeletingEmployeeAttempted: boolean;
  setIsDeletingEmployeeAttempted: (isDeleting: boolean) => void;
  isEditingEmployeeAttempted: boolean;
  setIsEditingEmployeeAttempted: (isEditing: boolean) => void;
  onClickeResendInvitation: (rowIndex: number) => void;
  onClickEdit: (rowIndex: number) => void;
  onClickDelete: (rowIndex: number) => void;
}

export const TeamColumns = ({
  rowIndex,
  setRowIndex,
  rowData,
  setRowData,
  isDeletingEmployeeAttempted,
  setIsDeletingEmployeeAttempted,
  isEditingEmployeeAttempted,
  setIsEditingEmployeeAttempted,
  onClickeResendInvitation,
  onClickEdit,
  onClickDelete,
}: TeamColumnsProps) => {
  const teamColumns: ColumnDef<PremiumUser>[] = [
    {
      accessorKey: "firstName",
      header: "First Name",
    },
    {
      accessorKey: "lastName",
      header: "Last Name",
    },
    {
      accessorKey: "emailAddress",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Email
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
    },
    {
      accessorKey: "employmentStartDate",
      header: "Start Date",
      cell: ({ row }) => {
        const value: Date = row.getValue("employmentStartDate");
        console.log(value)
        const stringValue = value ? value.toString().split("T")[0] : "";
        //const stringValue = value.toISOString();
        return(
          <div>
            {stringValue}
          </div>
        )
        
      }
    },
    {
      accessorKey: "birthday",
      header: "Birthday",
      cell: ({ row }) => {
        const value: Date = row.getValue("birthday");
        console.log(value)
        const stringValue = value ? value.toString().split("T")[0] : "";
        //const stringValue = value.toISOString();
        return(
          <div>
            {stringValue}
          </div>
        )
        
      }
    },
    {
      accessorKey: "userId",
      header: "Status",
      cell: ({ row }) => {
        const value = row.getValue("userId");
        const status = value === "" ? "• In Progress" : "• Onboarded";
        return (
          <div
            style={{
              color: value === "" ? "orange" : "green",
              fontWeight: "bold",
            }}
          >
            {status}
          </div>
        );
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-4 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-36">
              <DropdownMenuLabel>Actions</DropdownMenuLabel>
              {row.original.userId !== "" ? null : (
                <DropdownMenuItem onClick={() => onClickeResendInvitation(row.index)}>
                  Resend Invitation
                </DropdownMenuItem>
              )}
              {/* <DropdownMenuSeparator /> */}
              <DropdownMenuItem onClick={() => onClickEdit(row.index)}>
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem
                style={{ color: "red" }}
                onClick={() => onClickDelete(row.index)}
              >
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];

  return {
    teamColumns,
    rowData,
    rowIndex,
    isEditingEmployeeAttempted,
    isDeletingEmployeeAttempted,
    setIsEditingEmployeeAttempted,
    setIsDeletingEmployeeAttempted,
  };
};
