import { ImageVariantType } from "@atoms";
import { PagePaths } from "../../../../types";

export const todos: {
  variant: ImageVariantType;
  title: string;
  description: string;
  navigationString: string;
}[] = [
  {
    variant: "newCampaign",
    title: "Send an Airdrop to a Team Member!",
    description: "",
    navigationString: PagePaths.airdrop + "/select-members",
  },
  {
    variant: "paymentInfo",
    title: "View previous Airdrops",
    description: "",
    navigationString: PagePaths.airdrop + "/history",
  },
];
