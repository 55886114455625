import * as React from "react"

import { cn } from "@utils/design-utils"

const Blog = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "rounded-xl bg-card text-card-foreground",
      className
    )}
    {...props}
  />
))
Blog.displayName = "Blog"

const BlogImage = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("", className)}
    {...props}
  />
))
BlogImage.displayName = "BlogImage"

const BlogHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col space-y-1.5 px-2 pt-3 pb-2", className)}
    {...props}
  />
))
BlogHeader.displayName = "BlogHeader"

const BlogTag = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn("flex flex-col p-2 h-6 justify-center font-medium text-xs leading-5 text-neutral-darkest rounded-md bg-neutral-lightest", className)}
    {...props}
  />
))
BlogTag.displayName = "BlogTag"

const BlogDate = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn("font-medium text-xs leading-5 text-neutral-darkest p-2", className)}
    {...props}
  />
))
BlogDate.displayName = "BlogDate"

const BlogTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn("font-medium text-md leading-5 text-neutral-darkest pb-2", className)}
    {...props}
  />
))
BlogTitle.displayName = "BlogTitle"

const BlogDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("font-regular text-sm text-neutral-dark leading-5", className)}
    {...props}
  />
))
BlogDescription.displayName = "BlogDescription"

const BlogContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("p-2 pt-0 pb-3", className)} {...props} />
))
BlogContent.displayName = "BlogContent"

const BlogFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center p-6 pt-0", className)}
    {...props}
  />
))
BlogFooter.displayName = "BlogFooter"

export { Blog, BlogImage, BlogTag, BlogDate, BlogHeader, BlogFooter, BlogTitle, BlogDescription, BlogContent }
