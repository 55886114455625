/**
 * ! Only use this for the tailwind config.
 * Since tailwind config follows the CommonJS standard,
 * we are using 'module.exports' syntax with JS.
 */

module.exports = {
  primary: {
    lightest: "#FFFADE",
    light: "#FFEE96",
    medium: "#FFE457",
    dark: "#DDAF5D",
    darkest: "#63450F",
  },
  secondary: {
    lightest: "#FF3451",
    light: "#FF3451",
    medium: "#FF3333",
    dark: "#DD2030",
    darkest: "#FF3451",
  },
  neutral: {
    lightest: "#F5F4F2",
    light: "#ECEBE7",
    medium: "#DDD7CE",
    mediumDark: "#818186",
    dark: "#686559",
    darkest: "#201A15",
  },
  successful: {
    lightest: "#D1F1E6",
    light: "#7ACCAF",
    medium: "#48B38E",
    dark: "#3A8C6F",
    darkest: "#2D5342",
  },
  attention: {
    lightest: "#FFE3D9",
    light: "#FFB499",
    medium: "#F27C52",
    dark: "#BF5D3A",
    darkest: "#66321F",
  },
  black: "#000000",
  white: "#FFFFFF",
  red: "#FF3333",
  gray: {
    100: "#E6E6E9",
    500: "#808290",
    900: "#010621",
  },
  taupe: {
    600: "#5C5151",
  },
  accent: {
    background: "210 40% 96.1%",
    foreground: "222.2 47.4% 11.2%",
  },
};
