import { FontSizeType, FontWeightType, SizeType } from "./types";

export type ColorMixinType = {
  element: "background" | "text";
  color: string;
  usedIn: string;
  brightness: number | undefined;
};

export const colorMixin = ({
  element,
  color,
  usedIn,
  brightness,
}: ColorMixinType): string => {
  const colorsWithBrightness = ["gray", "red", "blue"];
  const context = usedIn.replace(/([A-Z])/g, " $1").trim();
  const stylingFor = element === "background" ? "bg" : "text";

  if (colorsWithBrightness.includes(color) && !brightness) {
    throw new Error(
      `[${context}] Brightness has to be specified with the color ${color}`,
    );
  }
  if (!color) {
    throw new Error(`[${context}] Color has to be specified`);
  }
  if (colorsWithBrightness.includes(color)) {
    return `${stylingFor}-${color}-${brightness}`;
  }

  return `${stylingFor}-${color}`;
};

/**
 * Underline
 */
export type UnderlineMixinType = {
  underline: boolean | undefined;
};

export const underlineMixin = ({ underline }: UnderlineMixinType): string => {
  return underline ? "underline" : "";
};

/**
 * Font Size
 */
export type FontSizeMixinType = {
  fontSize: FontSizeType;
};

export const fontSizeMixin = ({ fontSize }: FontSizeMixinType): string => {
  return `text-${fontSize}`;
};

/**
 * Font Weight
 */
export type FontWeightMixinType = {
  fontWeight: FontWeightType;
};

export const fontWeightMixin = ({
  fontWeight,
}: FontWeightMixinType): string => {
  return `text-${fontWeight}`;
};

/**
 * Width
 */
export type WidthMixinType = {
  table: { [x in SizeType]: string };
  size: SizeType | undefined;
  stretch: boolean | undefined;
  autosize: boolean | undefined;
  centered: boolean;
};

export const widthMixin = ({
  table,
  size,
  stretch,
  autosize,
  centered,
}: WidthMixinType): string => {
  if (stretch) {
    return `w-full ${centered ? "flex justify-center" : ""}`;
  }
  if (size) {
    return table[size];
  }
  if (autosize) {
    return `flex justify-center px-8`;
  }
  throw new Error(
    "Must provide either size, stretch or autosize for the Primary Button component",
  );
};
