import { PerkTypes } from "../../../types";

export type PartnerPerk = {
  type: PerkTypes;
  title: string;
  fullTitle: string;
  subtitle: string;
  description: string;
  icon: string;
  isAvailable: boolean;
  includedInPackage: boolean;
  isBeta?: boolean;
}

export const perksData = [
  {
    type: "perk_allCashback",
    title: "Cashback Rewards",
    fullTitle: "Cashback Rewards",
    subtitle: "",
    description: "Earn when you shop at 1,000+ brands",
    icon: "rocket",
    isAvailable: true,
    includedInPackage: true,
  },
  {
    type: "perk_airdrop",
    title: "Airdrops",
    fullTitle: "Airdrops",
    subtitle: "",
    description: "Airdrop digital gift cards to your team - and more",
    icon: "airdrop",
    isAvailable: false,
    includedInPackage: true,
    isBeta: true,
  },
  /*
  Merged into one perks box, if we find use cases to split them, then re-integrate these perks boxes
   {
    type: "perk_onlineCashback",
    title: "Online Cashback",
    fullTitle: "Online Cashback",
    subtitle: "",
    description: "Earn when you shop online at 1,000+ brands",
    icon: "rocket",
    isAvailable: true,
    includedInPackage: true
  },
  {
    type: "perk_giftCardCashback",
    title: "Gift Card Cashback",
    fullTitle: "Gift Card Cashback",
    subtitle: "",
    description: "Earn rewards with digital gift cards",
    icon: "gift",
    isAvailable: true,
    includedInPackage: true
  }, */
  // {
  //   type: "perk_travelCashback",
  //   title: "Travel Cashback",
  //   fullTitle: "Travel Cashback",
  //   subtitle: "",
  //   description: "Up to 40% back on your next hotel",
  //   icon: "airplane",
  //   isAvailable: true,
  //   includedInPackage: false
  // },
  {
    type: "perk_celebrations",
    title: "Celebrations",
    fullTitle: "Celebrations",
    subtitle: "",
    description: "Automate celebrations (birthday, holidays, etc.)",
    icon: "cake",
    isAvailable: false,
    includedInPackage: true
  },
  //
  // currently these are "A la Carte" ...
  //
  {
    type: "perk_onTheGo",
    title: "Perks On The Go",
    fullTitle: "Perks On The Go",
    subtitle: "",
    description: "Automate reimbursement of selected expenses",
    icon: "onTheGo",
    isAvailable: false,
    includedInPackage: false
  },  
  {
    type: "perk_slack",
    title: "Recognition",
    fullTitle: "Recognition",
    subtitle: "",
    description: "Let your team reward each other for epic wins!",
    icon: "heartOutline",
    isAvailable: false,
    includedInPackage: false
  }, 
  {
    type: "perk_24_7",
    title: "24/7 Pay",
    fullTitle: "24/7 Pay",
    subtitle: "",
    description: "Access your pay when you need it most",
    icon: "clock",
    isAvailable: false,
    includedInPackage: false
  }, 
  {
    type: "perk_customerRewards",
    title: "Customer Rewards",
    fullTitle: "Customer Rewards",
    subtitle: "",
    description: "Send gift cards to your customers",
    icon: "lightning",
    isAvailable: false,
    includedInPackage: false
  },
  // {
  //   type: "perk_roundUp",
  //   title: "Round Up",
  //   fullTitle: "Round Up",
  //   subtitle: "",
  //   description: "Round up your team’s expenses",
  //   icon: "roundUp",
  //   isAvailable: false,
  //   includedInPackage: false
  // },
  // {
  //   type: "perk_prepaidCard",
  //   title: "Payment Cards",
  //   fullTitle: "Payment Cards",
  //   subtitle: "",
  //   description: "Earn more everyday, on everything",
  //   icon: "paymentCard",
  //   isAvailable: false,
  //   includedInPackage: false
  // },
  // {
  //   type: "perk_bitcoinPayroll",
  //   title: "Bitcoin Payroll",
  //   fullTitle: "Bitcoin Payroll",
  //   subtitle: "",
  //   description: "Pay or rewards your team in Bitcoin",
  //   icon: "bitcoinPayroll",
  //   isAvailable: false,
  //   includedInPackage: false
  // }, 
      
];