import { PagePaths } from "../../../../types";

export const menuItems = [
  {
    label: "Home",
    to: PagePaths.home,
    icon: { variant: "home" as const },
  },
  {
    label: "Organization",
    to: PagePaths.organization,
    icon: { variant: "organization" as const },
  },
  {
    label: "Subscription",
    to: PagePaths.billing,
    icon: { variant: "dollarSign" as const },
  },
  {
    label: "My Perks",
    to: PagePaths.perks,
    icon: { variant: "rocket" as const },
  },
  {
    label: "Team",
    to: PagePaths.team,
    icon: { variant: "users" as const },
  },
];
