import { useUser } from "@auth";
import { AirdropHistoryPageTemplateProps } from "./template";
import { useGetAllGiftCardOffers, useGetPartnerAirdropTransactions } from "@queries";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../auth/auth-context";


export const useAirdropHistoryPage = (): AirdropHistoryPageTemplateProps => {
    const navigate = useNavigate();
    const partner = useUser();
    const { getPartnerAirdropTransactions } = useGetPartnerAirdropTransactions();
    const { giftCardOffers, partnerAirdropTransactions } = useContext(AuthContext);


    useEffect(() => {
        getPartnerAirdropTransactions();
    }, [])

    const navigateToPage = (index: number, to: string) => {
        navigate(to);
      }

    return {
        premiumUsers: partner?.premiumSettings?.[0]?.users.filter(item => item.userId !== "") || [],
        giftCardOffers,
        navigateToPage,
        partnerAirdropTransactions: partnerAirdropTransactions ?? []
    }
}