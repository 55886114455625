import { Button, Icon, Image, ImageVariantType, IconVariantType } from "@atoms";
import { rewardsData, PartnerReward } from "./rewards-data";
import { perksData, PartnerPerk } from "./perks-data";

export enum InfoDetailsModalType {
  Rewards = "Rewards",
  Perks = "Perks"
}

export type ItemDetailsTemplateProps = {
  closeInfoModal: () => void;
  closeAndAccept: () => void;
  itemData: any;
  modalType: InfoDetailsModalType;
  isSelectedItemEnabled: boolean;
};

// Information modal to display either Rewards or Perks on the Perks page
export const ItemDetails = ({
  closeInfoModal,
  closeAndAccept,
  itemData,
  modalType,
  isSelectedItemEnabled
}:ItemDetailsTemplateProps): JSX.Element => {

  const data: any = modalType === InfoDetailsModalType.Rewards 
    ? rewardsData.find(item => item.type === itemData) as PartnerReward
    : perksData.find(item => item.type === itemData) as PartnerPerk;

  if (!data) closeInfoModal();

  let buttonText = "";
  const includeActivateButton = !data?.isAvailable;

  if (modalType === InfoDetailsModalType.Rewards ) { // Rewards
    if (data?.isAvailable) {
      // note: temporarily disable this so that Bitcoin is always active 
      // buttonText = `${isSelectedItemEnabled ? "Deactivate" : "Activate"} ${data?.title}`;
      // buttonText = "Join waitlist";
      buttonText = "Already included";
    } else {
      buttonText = "Join waitlist";
    }
  } else { // Perks
    if (data?.includedInPackage && data?.isAvailable) {
      buttonText = "Already included";
    } else if (data?.isAvailable) {
      buttonText = `${isSelectedItemEnabled ? "Deactivate" : "Activate"} ${data?.title}`;
    } else {
      buttonText = "Join waitlist";
    }
  }

  return (
    <div className="min-w-min p-4 pb-9">
      <Button
        onClick={closeInfoModal}
        variant="close"
      /> 
      <div className="flex flex-col h-full items-center content-center">
        {modalType === InfoDetailsModalType.Rewards ? 
          <div className={`w-12 h-12 flex flex-row`}>
            <Image variant={data?.icon as ImageVariantType} />
          </div>
        :
          <div className={`w-14 h-14 bg-neutral-lightest rounded-full flex flex-row justify-center items-center`}>
            <Icon variant={data?.icon as any} width={32} height={32} size={32} strokeWidth={1} />
          </div>
        }
        <div className="flex flex-col w-full">
          <div className="font-medium text-neutral-darkest pt-2 text-3xl text-center">{data?.fullTitle}</div>
          {/* <div className="font-normal text-neutral-dark text-lg pt-5 text-center">{data?.subtitle}</div> */}
          <div className="font-normal text-neutral-dark text-lg pt-5 text-center">{data?.description}</div>

          {!isSelectedItemEnabled &&
            <div className="font-medium text-neutral-dark text-sm text-center bg-neutral-lightest rounded-md p-3 mt-6">{"Get early access by joining our waitlist!"}</div>
          }
          {(modalType === InfoDetailsModalType.Rewards && data?.isAvailable) ||
           (modalType === InfoDetailsModalType.Perks && data?.isAvailable && data?.includedInPackage) ? 
            <div className="font-medium text-successful-darkest text-sm text-center bg-successful-lightest rounded-md w-full p-3 mt-6">{"Feature Active"}</div>
            : null
          }
        </div>
      </div>
      {includeActivateButton &&
        <div className="flex flex-row justify-center pt-8 px-16">
          <Button
            onClick={closeAndAccept}
            variant="primary"
            label={buttonText}
            autosize
            color="primary-medium"
            textWeight="semibold"
          /> 
        </div>
      }
    </div>
  );
};