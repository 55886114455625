import { Layout } from "@layouts";
import { Card, Item } from "@molecules";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "@auth";
import { Image, ImageVariantType } from "@atoms";

export type CelebrationsHolidayPageTemplateProps = {
  premiumUsers: any[];
  navigateToPage: (index: number, to: string) => void;
};

export const CelebrationsHolidayPageTemplate = ({
  premiumUsers,
  navigateToPage,
}: CelebrationsHolidayPageTemplateProps): JSX.Element => {
  const { partner } = useContext(AuthContext);

  return (
    <Layout
      variant="dashboard"
      header="Celebrations"
      subheader={
        <div className="flex flex-row items-start justify-between max-w-5xl">
          <div className="text-neutral-dark text-base font-normal mt-2">
            Set up and manage automated celebrations here
          </div>
          <div>
            <Item variant="beta" />
          </div>
        </div>
      }
    >
      <div className="p-1 rounded-md max-w-5xl">
        <div className="my-4">
          <h2 className="text-2xl">
            {"Holidays Celebrations 🎄"}
          </h2>
        </div>
      </div>
    </Layout>
  );
};