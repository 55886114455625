import { ImageVariantType } from "@atoms";
import { PagePaths } from "../../../../types";

export const todos: {
  variant: ImageVariantType;
  title: string;
  description: string;
  navigationString: string;
}[] = [
  {
    variant: "businessInfo",
    title: "Birthdays",
    description: "",
    navigationString: PagePaths.celebrations + "/birthdays",
  },
  {
    variant: "paymentInfo",
    title: "Holidays",
    description: "",
    navigationString: PagePaths.celebrations + "/holidays",
  },
  {
    variant: "perksProgram",
    title: "Seniority",
    description: "",
    navigationString: PagePaths.celebrations + "/seniority",
  },  
  {
    variant: "newCampaign",
    title: "Custom",
    description: "",
    navigationString: PagePaths.celebrations + "/custom",
  },
];