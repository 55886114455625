import { useState, useContext, useEffect } from "react";
import { useFormik, FormikErrors } from "formik";
import ReactModal from "react-modal";

import { Button, Input } from "@atoms";
import {
  useRemoveUserToPremiumPartner,
  useEditUserFromPremiumPartner,
} from "@mutations";
import { styles as globalStyles } from "../../../../styles/styles";
import { AuthContext } from "@auth";

export type EmployeeItemTemplateProps = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  coinmilesAccount: string;
  employmentStartDate: string;
  birthday: string;
  index: number;
};

export type EmployeeFormValuesEdit = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  coinmilesAccount: string;
};

/* Implemented a separate type and interface to not cause overlap bw formik and normal code, read online it can cause breaks TBC */
export interface EmployeeEditProps {
  firstName: string;
  lastName: string;
  emailAddress: string;
  coinmilesAccount: string;
}

// **** Note: is this component still being used? I think we moved this to the Team template.ts file (edit feature in a modal rather than inline)

export const EmployeeItemTemplate = ({
  firstName,
  lastName,
  emailAddress,
  coinmilesAccount,
  employmentStartDate,
  birthday,
  index,
}: EmployeeItemTemplateProps): JSX.Element => {
  const { isLoadingData, setIsLoadingData } = useContext(AuthContext);

  const [isDeletingEmployeeAttempted, setIsDeletingEmployeeAttempted] =
    useState<boolean>(false);
  const [isEditingEmployeeAttempted, setIsEditingEmployeeAttempted] =
    useState<boolean>(false);
  const removeUser = useRemoveUserToPremiumPartner();
  const editUser = useEditUserFromPremiumPartner();

  function closeModal() {
    setIsDeletingEmployeeAttempted(false);
  }

  function closeEditModal() {
    setIsEditingEmployeeAttempted(false);
  }

  useEffect(() => {
    if (!isLoadingData) closeEditModal();
  }, [isLoadingData]);

  const validate = (
    values: EmployeeFormValuesEdit,
  ): FormikErrors<EmployeeFormValuesEdit> => {
    const errors: FormikErrors<EmployeeFormValuesEdit> = {};
    /* if (!values.emailAddress) {
      errors.emailAddress = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)
    ) {
      errors.emailAddress = "Invalid email address";
    } */
    return errors;
  };

  const { values, errors, handleChange, handleSubmit, setFieldError } =
    useFormik<EmployeeFormValuesEdit>({
      initialValues: {
        firstName: firstName,
        lastName: lastName,
        emailAddress: "",
        coinmilesAccount: "",
      },
      validate,
      validateOnChange: false,
      onSubmit: async (values: EmployeeEditProps) => {
        setIsLoadingData(true);
        try {
          console.log("Attempted editing");
          editUser(values.firstName, values.lastName, index);
          //return; // Attempt to edit the email specific user
        } catch (error: any) {
          console.log(error);
          // use setFieldErrors here or nuke that stuff
          // Check here if email already exists ?
        }
      },
    });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e);

  useEffect(() => {
    if (!isLoadingData) closeModal();
  }, [isLoadingData]);

  const onConfirmUserDelete = () => {
    /* Delete email from list of users */
    setIsLoadingData(true);
    removeUser(emailAddress);
    // closeModal();
  };

  const onRemoveTeamMemberClicked = () => {
    /* Currently pressing on delete will display or hide the option, can change that later on */
    isDeletingEmployeeAttempted
      ? setIsDeletingEmployeeAttempted(false)
      : setIsDeletingEmployeeAttempted(true);
  };

  const onAttemptEmployeeEdit = () => {
    isEditingEmployeeAttempted
      ? setIsEditingEmployeeAttempted(false)
      : setIsEditingEmployeeAttempted(true);
  };

  const getUserInitials = (firstName: string, lastName: string): string =>
    firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();

  return (
    <div
      className="mb-2 justify-start border border-neutral-medium w-full flex flex-row justify-between"
      style={{ borderRadius: 10, padding: 3 }}
    >
      <div className="flex flex-row items-center p-3">
        <div className="flex w-10 h-10 p-3 mr-5 text-base text-neutral-dark text-sm font-semibold bg-successful-lightest rounded-3xl justify-center items-center">
          {getUserInitials(firstName, lastName)}
        </div>
        <div>
          <div /* className={globalStyles.tableRow.firstName} */>
            {firstName + ` ` + lastName}
          </div>
          <div>{emailAddress}</div>
          <div /* className={globalStyles.tableRow.buttons} */>
            <button
              style={{ textDecoration: "underline", marginRight: 6 }}
              onClick={onAttemptEmployeeEdit}
            >
              Edit
            </button>
            <button
              // className={"text-attention-dark"}
              style={{
                color: "red",
                textDecoration: "underline",
                marginLeft: 6,
              }}
              onClick={onRemoveTeamMemberClicked}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
      <div className="flex items-center pr-3">
        <div
          className={`justify-end mr-2 font-semibold text-${coinmilesAccount === "Onboarded" ? "successful-medium" : "attention-medium"}`}
        >
          {`• ` + coinmilesAccount}
        </div>
      </div>

      {isEditingEmployeeAttempted ? (
        <ReactModal
          isOpen={isEditingEmployeeAttempted}
          onRequestClose={closeEditModal}
          style={globalStyles.generalModal}
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <div className="font-semibold text-2xl flex flex-row justify-center py-2">
            Edit a Team Member
          </div>
          <form className="p-2" onSubmit={handleSubmit}>
            <div className="mb-3">
              <Input
                name="firstName"
                variant="text"
                onChange={handleInputChange}
                placeholder="First name"
                value={values.firstName}
                errorMessage={errors.firstName}
              />
            </div>
            <div className="mb-3">
              <Input
                name="lastName"
                variant="text"
                onChange={handleInputChange}
                placeholder="Last name"
                value={values.lastName}
                errorMessage={errors.lastName}
              />
            </div>
            {/* <div className="mb-3">
              <Input
                name="emailAddress"
                variant="text"
                onChange={handleInputChange}
                placeholder="Email address"
                value={emailAddress}
                errorMessage={errors.emailAddress}
              />
            </div> */}
            <div className="flex flex-row justify-center">
              <div className="w-64">
                <Button
                  onClick={isLoadingData ? () => {} : handleSubmit}
                  variant="primary"
                  label="Update Team Member"
                  stretch
                  color="primary-medium"
                  textWeight={"semibold"}
                  loading={isLoadingData}
                />
              </div>
            </div>
          </form>
        </ReactModal>
      ) : null}
      {/* <div className={globalStyles.tableRow.employmentStartDate}>{employmentStartDate}</div>
      <div className={globalStyles.tableRow.birthday}>{birthday}</div> */}

      {isDeletingEmployeeAttempted ? (
        <ReactModal
          isOpen={isDeletingEmployeeAttempted}
          onRequestClose={closeModal}
          style={globalStyles.generalModal}
          shouldCloseOnOverlayClick={true}
          ariaHideApp={false}
        >
          <div className="font-semibold text-2xl flex flex-row justify-center py-4">
            Remove User
          </div>
          <div className="mb-5 flex flex-row justify-center">
            {`Remove ${firstName} ${lastName} from premium?`}
          </div>
          <div className="flex flex-row justify-center">
            <div className="w-64">
              <Button
                onClick={isLoadingData ? () => {} : onConfirmUserDelete}
                variant="primary"
                label="Confirm"
                stretch
                textWeight={"semibold"}
                color="primary-medium"
                loading={isLoadingData}
              />
            </div>
          </div>
        </ReactModal>
      ) : null}
    </div>
  );
};
