import { LinkButton, LinkButtonProps } from "./button-link";
import { PartnersButton, PartnersButtonProps } from "./button-partners";
import { PrimaryButton, PrimaryButtonProps } from "./button-primary";
import { SecondaryButton, SecondaryButtonProps } from "./button-secondary";
import { RadioButton, RadioButtonProps } from "./button-radio";
import { CloseButton, CloseButtonProps } from "./button-close";

export type ButtonProps =
  | ({ variant: "primary" } & PrimaryButtonProps)
  | ({ variant: "secondary" } & SecondaryButtonProps)
  | ({ variant: "link" } & LinkButtonProps)
  | ({ variant: "partners" } & PartnersButtonProps)
  | ({ variant: "radio" } & RadioButtonProps)
  | ({ variant: "close" } & CloseButtonProps);

export const Button = (props: ButtonProps): JSX.Element => {
  const { variant } = props;
  switch (props.variant) {
    case "primary":
      return <PrimaryButton {...props} />;
    case "secondary":
      return <SecondaryButton {...props} />;  
    case "link":
      return <LinkButton {...props} />;
    case "partners":
      return <PartnersButton {...props} />;
    case "radio":
      return <RadioButton {...props} />;    
    case "close":
      return <CloseButton {...props} />;     
    default:
      throw new Error(`Invalid Button variant [${variant}] provided`);
  }
};
