import { Layout } from "@layouts";

export const CardLinkedOffersPageTemplate = (): JSX.Element => {
  return (
    <Layout
      variant="dashboard"
      header="Card-Linked Offer"
      subheader={
        <h5 className="text-primary-dark text-lg font-normal">
          Give Bitcoin rewards when a Coinmiles users pay you using a linked
          credit card.
        </h5>
      }
    >
      <div></div>
    </Layout>
  );
};
