import { Icon } from "@atoms";
import { useCurrencyUtils, useDateUtils } from "../../../../utils";
import dayjs from "dayjs";
import "dayjs/locale/en-ca";

export type InvoiceListTemplateProps = {
  title: string;
  onClick: (() => void) | undefined;
  buttonText?: string;
  hasAddButton?: boolean;
  data: any;
  icon?: JSX.Element;
  iconSubtitle?: string;
};

export const InvoiceListTemplate = ({
  onClick,
  icon,
  iconSubtitle,
  data
}: InvoiceListTemplateProps): JSX.Element => {
  const { convertToCurrencyFormat } = useCurrencyUtils();
  const { formatDate } = useDateUtils();

  return (
    <div className="w-full">
      {data === null ?
        <div>
         {icon}
         {"Loading..."}
        </div>
        :
        data?.length === 0 ?
          <div>
            {icon}
            {iconSubtitle}
          </div>
        :
        <div className={"w-full py-1"}>
          <div className={`flex flex-row w-full items-center justify-start pl-2 border-b border-neutral-light`}>
            <div className={"w-1/4 p-1 pr-4 font-semibold"}>{`Date`}</div>
            <div className={"w-1/4 p-1 pr-4 font-semibold"}>{`Amount`}</div>
            <div className={"w-1/4 p-1 pr-4 text-successful-medium"}></div>
          </div>
          {data?.map((card:any) => {
            return (
              <div key={card.id} className={`flex flex-row items-center justify-start pl-2 border-b border-neutral-light ${card.isSelected && "bg-green-100"}`}>
                <div className={"w-1/4 p-1 pr-4"}>{`${formatDate(card.createdDate)}`}</div>
                <div className={"w-1/4 p-1 pr-4"}>{`${convertToCurrencyFormat(card.amount)}`}</div>
                {card.downloadUrl === "" ?
                  <div className={"w-1/4 py-2 pr-4 text-attention-dark"}></div>
                   :
                  <a href={card.downloadUrl} target='_blank'><div className={"w-1/4 py-2 pr-4 text-successful-medium"}>Download</div></a>
                }
              </div>
            )
          })}
        </div>
      }
    </div>
  );
};
