import { AuthOptions } from "@aws-amplify/auth/lib-esm/types";

import { Env } from "./env";

const config: AuthOptions = {
  authenticationFlowType: "USER_PASSWORD_AUTH",
  identityPoolId: Env.COGNITO_IDENTITY_POOL_ID,
  region: Env.REGION,
  userPoolId: Env.COGNITO_USER_POOL_ID,
  userPoolWebClientId: Env.COGNITO_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: Env.COGNITO_OAUTH_DOMAIN,
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: Env.SIGNIN_URL,
    redirectSignOut: Env.SIGNOUT_URL,
    responseType: "code",
  },
};

export { config as amplifyConfig };