import ReactModal from 'react-modal';

import { Button, Input } from "@atoms";
import { Layout } from "@layouts";
import { styles as globalStyles } from "../../../styles/styles";
import { ImageUploader } from "@molecules";
import { provinceOptions, countryOptions } from "./location-data";

export type ProfilePageTemplateProps = {
  partnerData: any;
  onEditAddressClicked: () => void;
  onEditDescriptionClicked: () => void;
  onEditCompanyClicked: () => void;
  handleInputChangeCompany: any;
  handleInputChangeDescription: any
  handleInputChangeAddress: any;
  handleInputChangeCountry: any;
  handleInputChangeProvince: any;
  handleSubmitCompany: any;
  handleSubmitDescription: any;
  handleSubmitAddress: any;
  valuesCompany: any;
  valuesDescription: any;
  valuesAddress: any;
  province: string;
  country: string;
  errorsCompany: any;
  errorsDescription: any;
  errorsAddress: any;
  modalChangeCompany: boolean;
  modalChangeDescription: boolean;
  modalChangeAddress: boolean;
  closeModal: () => void;
  isLoading: boolean;
}

export const ProfilePageTemplate = ({
  partnerData,
  onEditAddressClicked,
  onEditDescriptionClicked,
  onEditCompanyClicked,
  handleInputChangeCompany,
  handleInputChangeDescription,
  handleInputChangeAddress,
  handleInputChangeCountry,
  handleInputChangeProvince,
  handleSubmitCompany,
  handleSubmitDescription,
  handleSubmitAddress,
  valuesCompany,
  valuesDescription,
  valuesAddress,
  province,
  country,
  errorsCompany,
  errorsDescription,
  errorsAddress,
  modalChangeCompany,
  modalChangeDescription,
  modalChangeAddress,
  closeModal,
  isLoading
}: ProfilePageTemplateProps): JSX.Element => {

  const { company } = valuesCompany;
  const { company: companyError } = errorsCompany;

  const { description } = valuesDescription;
  const { description: descriptionError } = errorsDescription;

  // const { street, city, country, postalCode } = valuesAddress;
  const { street, city, postalCode } = valuesAddress;
  const { street: streetError, city: cityError, country: countryError, province: provinceError, postalCode: postalCodeError  } = errorsAddress;

  const isAddressEmpty = partnerData?.address === null || partnerData?.address === undefined;
  const { address } = partnerData;

  const stateProvinceOptions: any = country !== "CA" ? provinceOptions.US : provinceOptions.CA;
  
  return (
    <Layout
      variant="dashboard"
      header="Organization"
      subheader={
        <div className="text-neutral-dark text-base font-normal mt-2">
          Complete or update your organization settings here
        </div>}
    >
      <div>
        <div className="flex flex-col justify-between max-w-full py-1 mr-4">
          <div className="text-neutral-darkest text-xl font-normal mt-7 mb-4">
            Company Information
          </div>
          <div className="flex flex-row space-between justify-start border border-neutral-light rounded-2xl">
            <div className="flex flex-col py-8 px-10">
              <div className="">
                <ImageUploader />
              </div>
            </div>
            <div className="flex flex-col px-6 w-full">
              <div className="w-full py-8">
                <div className="flex flex-row justify-between">
                  <div>
                    <div className="text-black text-md pb-2 font-medium">
                      Company name
                    </div>
                    <div className="text-black text-sm pb-10">
                      {partnerData?.organization}
                    </div>
                  </div>
                  <div className="min-w-max">
                    <div>
                      <Button
                        onClick={onEditCompanyClicked}
                        variant="primary"
                        label="Edit company"
                        autosize
                        color="neutral-lightest"
                        hoverColor="neutral-light"
                        textColor="neutral-darkest"
                        //textSize="sm"
                        textWeight="semibold"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <div>
                    <div className="text-black text-md pb-2 font-medium">
                      Description
                    </div>
                    <div className="text-black text-sm pb-10 pr-3">
                      {partnerData?.description}
                    </div>
                  </div>
                  <div className="min-w-max">
                    <div>
                      <Button
                        onClick={onEditDescriptionClicked}
                        variant="primary"
                        label="Edit description"
                        autosize
                        color="neutral-lightest"
                        hoverColor="neutral-light"
                        textColor="neutral-darkest"
                        //textSize="sm"
                        textWeight="semibold"
                      />
                    </div>
                  </div>     
                </div>
                <div className="flex flex-row justify-between">
                  <div className="w-2/3">
                    <div className="text-black text-md pb-2 font-medium">
                      Primary company address
                    </div>
                    {isAddressEmpty ?
                      <div className="">
                        <div className="text-attention-darkest mt-2 p-3 pl-6 rounded-xl bg-attention-lightest">
                        🚨 Please add your address, then proceed to the Subscription screen.
                        </div>
                      </div>
                      :
                      <div className="flex flex-col w-full">
                        <div className="flex flex-row w-full space-between">
                          <div className="w-full font-normal text-sm">
                            <div>{address?.street}</div>
                            <div>{`${address?.city} ${address?.province} ${address?.postalCode}`}</div>
                            <div>{address?.country}</div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <div className="min-w-max">
                    <div>
                      <Button
                        onClick={onEditAddressClicked}
                        variant="primary"
                        label={`${isAddressEmpty ? "Add" : "Edit"} address`}
                        autosize
                        color="neutral-lightest"
                        hoverColor="neutral-light"
                        textColor="neutral-darkest"
                        //textSize="sm"
                        textWeight="semibold"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Change company name */}
          <ReactModal
            isOpen={modalChangeCompany}
            onRequestClose={closeModal}
            style={globalStyles.generalModal}
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}
            contentLabel="Company Modal"
          >
            <div className="font-medium text-neutral-darkest text-xl pt-4 pb-8">Name of company</div>
            <form className="pb-6" onSubmit={handleSubmitCompany}>
              <div className="mb-3">
                <Input
                  name="company"
                  variant="text"
                  onChange={handleInputChangeCompany}
                  placeholder="Company name"
                  value={company}
                  errorMessage={companyError}
                />
              </div>
              <div className="flex flex-row place-content-end pt-4">
                <div className="w-40">   
                  <Button
                    type="submit"
                    variant="primary"
                    label="Save changes"
                    stretch
                    color="primary-medium"
                    textWeight="semibold"
                    loading={isLoading}
                  /> 
                </div>                
              </div>    
            </form>
          </ReactModal>

          {/* Change company description */}
          <ReactModal
            isOpen={modalChangeDescription}
            onRequestClose={closeModal}
            style={globalStyles.generalModal}
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}
            contentLabel="Description Modal"
          >
            <div className="font-medium text-neutral-darkest text-xl pt-4 pb-8">Description of company</div>
            <form className="pb-6" onSubmit={handleSubmitDescription}>
              <div className="mb-3">
                <Input
                  name="description"
                  variant="textArea"
                  onChange={handleInputChangeDescription}
                  placeholder="Description"
                  value={description}
                  errorMessage={descriptionError}
                  height={128}
                />
              </div>
              <div className="flex flex-row place-content-end pt-4">
                <div className="w-40">  
                  <Button
                    type="submit"
                    variant="primary"
                    label="Save changes"
                    stretch
                    color="primary-medium"
                    textWeight="semibold"
                    loading={isLoading}
                  /> 
                </div>                
              </div>    
            </form>
          </ReactModal>

          {/* Change company address */}
          <ReactModal
            isOpen={modalChangeAddress}
            onRequestClose={closeModal}
            style={globalStyles.generalModal}
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}
            contentLabel="Address Modal"
          >
            <div className="font-medium text-neutral-darkest text-xl pt-4">Primary company address</div>
            <div className="font-normal text-neutral-dark text-base pt-1 pb-8">Complete all the fields below</div>
            <form className="pb-6" onSubmit={handleSubmitAddress}>
              <div className="mb-3">
                <Input
                  name="street"
                  variant="text"
                  onChange={handleInputChangeAddress}
                  placeholder="Street"
                  value={street}
                  errorMessage={streetError}
                />
              </div>
              <div className="mb-3">
                <Input
                  name="city"
                  variant="text"
                  onChange={handleInputChangeAddress}
                  placeholder="City"
                  value={city}
                  errorMessage={cityError}
                />
              </div>                  
              <div className="mb-3">
                <Input
                  type="provinceDropdown"
                  name="province"
                  variant="dropdown"
                  options={stateProvinceOptions}
                  onChange={handleInputChangeProvince}
                  placeholder={address?.province ? stateProvinceOptions.find((item: any) => item.value === province) : null}
                  value={province}
                  errorMessage={provinceError}
                />
              </div>
              {/* <div className="mb-3">
                <Input
                  type="countryDropdown"
                  name="country"
                  variant="dropdown"
                  options={countryOptions}
                  onChange={handleInputChangeCountry}
                  placeholder={address?.country ? countryOptions.find(item => item.value === country) : countryOptions[0]}
                  value={country}
                  errorMessage={countryError}
                />
              </div> */}
              <div className="mb-3">
                <Input
                  name="country"
                  variant="text"
                  onChange={()=>{}}
                  placeholder="Country"
                  value={country}
                  errorMessage={countryError}
                />
              </div> 
              <div className="mb-3">
                <Input
                  name="postalCode"
                  variant="text"
                  onChange={handleInputChangeAddress}
                  placeholder={country === "CA" ? "Postal Code" : "Zip Code"}
                  value={postalCode}
                  errorMessage={postalCodeError}
                />
              </div>               
              <div className="flex flex-row place-content-end pt-4">
                <div className="w-40">   
                  <Button
                    type="submit"
                    variant="primary"
                    label="Save changes"
                    stretch
                    color="primary-medium"
                    textWeight="semibold"
                    loading={isLoading}
                  /> 
                </div>                
              </div>    
            </form>
          </ReactModal>
        </div>
        {/* <div className="text-neutral-darkest text-lg font-normal pt-6">
          Rewards Offered
        </div>  
        <div>
          <div className="flex flex-row">
            <div className="absolute">
              <div className="bg-neutral-lightest rounded-md w-40 h-28 text-center flex items-center justify-center my-4 mr-4">
                <Image variant={"bitcoinLogo"} width={68} height={68} />
                <div className="absolute top-3 right-3">
                  <Image variant={"checkmark"} width={32} height={32} />
                </div>
              </div>
            </div>
          </div>  
        </div>   */}
      </div>
    </Layout>
  );
};
