import { Button as SButton } from "@shadcn";

export type BarBottomNavTemplateProps = {
  onReturnPressed: () => void;
  onContinuePressed: (() => void) | null;
  isContinueDisabled: boolean;
  altContinueText?: string | null;
};

export const BarBottomNavTemplate = ({
  onReturnPressed,
  onContinuePressed,
  isContinueDisabled,
  altContinueText = null
}: BarBottomNavTemplateProps): JSX.Element => {
  return (
    <div className="absolute bottom-0 w-full pr-12">
      <div className="flex items-center w-full bg-white justify-between max-w-full mt-4 sticky bottom-0 pt-6 pb-8 border-t border-neutral-light">
        <SButton
          variant="outline"
          className="hover:bg-gray-100 w-28"
          onClick={onReturnPressed}
        >
          Go back
        </SButton>
        {onContinuePressed !== null &&
          <SButton
            onClick={onContinuePressed}
            disabled={isContinueDisabled}
            variant="default"
            className="disabled disabled:bg-gray-100 disabled:cursor-not-allowed w-56"
          >
            {altContinueText ? altContinueText : "Continue"}
          </SButton>
        }
      </div>
    </div>
  );
};
