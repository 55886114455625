import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, FormikErrors } from "formik";

import { SignInInput, SocialProvider, useSignIn, useSocialSignIn } from "@auth";
import { FormValues, SignInPageTemplateProps } from "./template";

export const useSignInPage = (): SignInPageTemplateProps => {
  const signIn = useSignIn();
  const socialSignIn = useSocialSignIn();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const validate = (
    values: FormValues,
  ): FormikErrors<FormValues> | undefined => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.emailAddress) {
      errors.emailAddress = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)
    ) {
      errors.emailAddress = "Invalid email address";
    }
    if (!values.password) {
      errors.password = "Password is required";
    } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(values.password)) {
      errors.password =
        "Password must contain one upper case character, one lower case character and one number";
    }

    return errors;
  };
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldError,
  } = useFormik({
    initialValues: {
      emailAddress: "",
      password: "",
    },
    validate,
    validateOnChange: false,
    onSubmit: async (values: SignInInput) => {
      try {
        setLoading(true);
        const result = await signIn(values);
        // if the signIn returns a request for a password reset (for a new user created via Retool) then the signIn method returns the cognito return message
        if (result === "NEW_PASSWORD_REQUIRED") {
          navigate("/signin/force-reset-password");
        }
      } catch (error: any) {
        setLoading(false);
        if (error.code === "UserNotConfirmedException") {
          setFieldError(
            "emailAddress",
            "You account not yet confirmed, check your email for the confirmation message",
          );
        } else if (
          error.code === "NotAuthorizedException" &&
          error.message === "User is disabled."
        ) {
          navigate("/signin/pending");
        } else if (error.code === "NotAuthorizedException") {
          setFieldError("emailAddress", "Invalid email address or password");
        } else {
          setFieldError("emailAddress", "Something went wrong");
        }
      }
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e);

  const handleLoginWithGoogle = async () => {
    await socialSignIn(SocialProvider.Google);
  };

  const handleSignUpRedirect = () => navigate("/signup");

  const handleForgotPasswordRedirect = () => {
    navigate("/signin/forgot-password");
  };

  return {
    values,
    errors,
    loading,
    handleForgotPasswordRedirect,
    handleInputChange,
    handleLoginWithGoogle,
    handleSignUpRedirect,
    handleSubmit,
  };
};
