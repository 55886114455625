import { useContext } from "react";
import { useUser } from "@auth";
import { AuthContext } from "@auth";
import { PagePaths } from "../types";

export const usePartnerUtils = (): {
  isSectionIncomplete: (pagePath: string) => boolean;
} => {
  const partner = useUser();
  const { isPerksPageVisited } = useContext(AuthContext);

  // determine if a page has been completed/visited
  const isSectionIncomplete = (pagePath: string): boolean => {
    const isAddressSubmitted = !(partner?.address === null || partner?.address === undefined);
    const isCardAdded = true; //creditCards?.length > 0;
    const isTeamMemberAdded = partner && partner.premiumSettings && partner?.premiumSettings[0]?.users?.length > 0;

    return false; // temp 

    switch(pagePath) {
      case PagePaths.home:
        return false;
      case PagePaths.organization:
        return !isAddressSubmitted;
      case PagePaths.billing:
        return !isCardAdded;
      case PagePaths.perks:
        return false;
      case PagePaths.team:
        return !isTeamMemberAdded;
      default:
        return false;                   
    }
  }

  return {
    isSectionIncomplete,
  };
};