import { ReactElement } from "react";
import { Button, Icon } from "@atoms";

export type CardListBaseTemplateProps = {
  title: string;
  onClick: (() => void) | undefined;
  buttonText?: string;
  hasTransactionFee?: boolean;
  hasAddButton?: boolean;
  data: any;
  children: ReactElement;
  icon?: JSX.Element;
  iconSubtitle?: string;
};

export const CardListBaseTemplate = ({
  title,
  onClick,
  buttonText = "",
  hasTransactionFee = false,
  hasAddButton = false,
  data,
  children
}: CardListBaseTemplateProps): JSX.Element => {
  return (
    <div
      className="mb-4"
      style={{
       width: 600,
      }}
    >
      <div
        className="mb-2"
        style={{
          display: "flex",
          flexDirection: "row",
          height: 60,
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "50%",
            float: "left",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="text-neutral-darkest text-xl font-normal">{title}</div>
          {hasTransactionFee ? (
            <div
              className="ml-2 px-2 rounded-full text-neutral-darkest"
              style={{
                fontSize: 12,
                paddingTop: 3,
                backgroundColor: "#eee",
                justifyContent: "center",
              }}
            >
              3% fee
            </div>
          ) : null}
        </div>
        <div
          style={{
            justifyContent: "flex-end",
            display: "flex",
            width: "50%",
          }}
        >
          { hasAddButton && data !== null ? 
            <Button
              onClick={onClick}
              variant="primary"
              label={buttonText}
              autosize
              textWeight={"semibold"}
              color={onClick !== undefined && data?.length === 0 ? "primary-medium" : "neutral-lightest"}
              textColor={onClick !== undefined  && data?.length === 0 ? "neutral-darkest" : "neutral-darkest"}
              hoverColor={onClick !== undefined  && data?.length === 0 ? "primary-dark" : "neutral-light"}
            />
          : null}
        </div>
      </div>
      <div
        className="border border-neutral-light p-3"
        style={{
          height: "100%",
          width: 600,
          borderRadius: 8,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </div>
    </div>
  );
};
