import { ColumnDef } from "@tanstack/react-table";

import { GiftCardOffer } from "../../../types";
import { Checkbox } from "@shadcn";

export const giftCardOfferColumns: ColumnDef<GiftCardOffer>[] = [
  {
    id: "select",
    cell: ({ table, row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {table.toggleAllRowsSelected(false); row.toggleSelected(!!value)}}
        aria-label="Select row"
        style={{borderRadius: 100}}
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "brandName",
    header: "Brand",
    cell: ({row}) => {
      return (
        <div className="flex flex-row justify-start items-center">
          <img
              src={row.original?.images?.[0]?.imageUrl}
              className="rounded-lg h-16 w-24 mr-2 border border-neutral-light"
            />
            {row.original.brandName}
        </div>
      )
    }
  },
  /* {
    accessorKey: "activationSettings.minActivationAmount",
    header: "Minimum Amount",
    cell: ({row}) => {
        const minAmount = row.original.activationSettings.minActivationAmount;
        const forcedMinAmount = row.original.activationSettings.forcedMinActivationAmount;
        const minValue = (forcedMinAmount !== null && forcedMinAmount >= minAmount) ? forcedMinAmount : minAmount;
        return <div>{minValue}</div>

    }
  },
  {
    accessorKey: "activationSettings.maxActivationAmount",
    header: "Maximum Amount",
    cell: ({row}) => {
        const maxAmount = row.original.activationSettings.maxActivationAmount;
        const forcedMaxAmount = row.original.activationSettings.forcedMaxActivationAmount;
        const maxValue = forcedMaxAmount && forcedMaxAmount >= maxAmount ? forcedMaxAmount : maxAmount;
        return <div>{maxValue}</div>

    }
  }, */
]
