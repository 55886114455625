import { OnboardCard, OnboardCardProps } from "./card-onboard";
import { RewardCard, RewardCardProps } from "./card-reward";
import { PerkCard, PerkCardProps } from "./card-perk";
import { PaymentMethodCard, PaymentMethodCardProps } from "./card-payment-method";
import { SubscriptionCard, SubscriptionCardProps } from "./card-subscriptions";
import { InvoiceList, InvoiceListProps } from "./card-invoice-list";
import { UserInfoCard, UserInfoCardProps } from "./card-user-info";
import { DashboardCard, DashboardCardProps } from "./card-dashboard";
import { BlogCard, BlogCardProps } from "./card-blog";
import { CardListBase } from "./card-list-base";

export type CardProps = 
| ({ variant: "onboard" } & OnboardCardProps) 
| ({ variant: "reward" } & RewardCardProps) 
| ({ variant: "subscription" } & SubscriptionCardProps) 
| ({ variant: "perk" } & PerkCardProps) 
| ({ variant: "paymentMethod" } & PaymentMethodCardProps) 
| ({ variant: "invoices" } & InvoiceListProps)
| ({ variant: "userInfo" } & UserInfoCardProps)
| ({ variant: "dashboard" } & DashboardCardProps)
| ({ variant: "blog" } & BlogCardProps);

export const Card = (props: CardProps): JSX.Element => {
  switch (props.variant) {
    case "onboard":
      return <OnboardCard {...props} />;
    case "reward":
      return <RewardCard {...props} />;
    case "perk":
      return <PerkCard {...props} />;
    case "subscription":
      return <SubscriptionCard {...props} />;   
    case "userInfo":
      return <UserInfoCard {...props} />;  
    case "dashboard":
      return <DashboardCard {...props} />;  
    case "blog":
      return <BlogCard {...props} />;            
    case "paymentMethod":
      return (
        <CardListBase {...props}>
          <PaymentMethodCard {...props} />
        </CardListBase>
      );  
    case "invoices":
      return (
        <CardListBase {...props}>
          <InvoiceList {...props} />
        </CardListBase>
      );   
    default:
      throw new Error(`[CARD ERROR] Invalid variant provided`);
  }
};
