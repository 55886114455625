import { useContext } from "react";
import { useNavigate } from "react-router";
import { gql, useMutation } from "@apollo/client";
import {
  addPartnerAddress as addPartnerAddressGraphQL,
  addUserToPremiumPartner as addUserToPremiumPartnerGraphQL,
  editUserFromPremiumPartner as editUserFromPremiumPartnerGrapQL,
  removeUserFromPremiumPartner as removeUserFromPremiumPartnerGraphQL,
  resendEmailToUserFromPremiumPartner as resendEmailToUserFromPremiumPartnerGraphQL,
  saveImageToS3 as saveImageToS3GraphQL,
  updatePartner as updatePartnerGraphQL,
  airdropGiftCard as airdropGiftCardGraphQL,
  updatePartnerBoostedRewards as updatePartnerBoostedRewardsGraphQL,
} from "./mutations";
import { useGetClientSecret } from "@queries";
import { useUser } from "@auth";
import { AuthContext } from "../auth/auth-context";
import {
  Partner,
  PartnerUpdate,
  PremiumUser,
  PremiumGiftCardAirdropRequest,
  CurrencyTypes,
  IofferBoost,
} from "@api";
import { useSyncPartner } from "@auth";

export function useUpdatePartner(): (
  update: PartnerUpdate,
  username: string,
  signUp?: boolean,
) => Promise<void> {
  const syncPartner = useSyncPartner();
  const query = gql(updatePartnerGraphQL);
  const [updatePartnerMutation] = useMutation<{
    updatePartner: Partner;
  }>(query, {
    onCompleted: ({ updatePartner: partner }) => {
      console.log("Completed partner update!");
      syncPartner(partner);
    },
  });

  const updatePartner = async function (
    partnerUpdate: PartnerUpdate,
    username: string,
    signUp = false,
  ) {
    const { phoneNumber } = partnerUpdate;
    if (phoneNumber) {
      const phoneNumberFormatted = `+${phoneNumber.replace(/\D+/g, "")}`;
      partnerUpdate.phoneNumber = phoneNumberFormatted;
    }
    await updatePartnerMutation({
      variables: { partnerUpdate, username, signUp },
    });
  };

  return updatePartner;
}

// const mockReturnUser = {
//   emailAddress: "matt@coinmiles.io",
//   firstName: "Matt",
//   lastName: "Mazur",
//   brandIds: null,
//   didLogin: true,
//   description: "This company is great.",
//   organization: "Matt's Swords",
//   phoneNumber: "7786684135",
//   website: "www.mattsswords.com",
//   address: {
//     street: "123 Main St.",
//     city: "Duncan",
//     province: "BC",
//     country: "CA",
//     postalCode: "V6P 5B3"
//   },
//   premiumSettings: [{
//     users: [
//     {
//       firstName: "Joe",
//       lastName: "Blow",
//       emailAddress: "joe@stuff.com",
//     },
//     {
//       firstName: "Tom",
//       lastName: "Smith",
//       emailAddress: "tom@foo.ca",
//     },
//     ],
//     percentage: 50,
//     availableSeats: 15
//   }]
// };

export function useUpdateAddress(): (
  street: string,
  city: string,
  province: string,
  country: string,
  postalCode: string,
) => Promise<void> {
  const query = gql(addPartnerAddressGraphQL);
  const { getClientSecret } = useGetClientSecret();
  const { clientSecret } = useContext(AuthContext);
  const syncPartner = useSyncPartner();

  const [updatePartner] = useMutation<{
    street: string;
    city: string;
    province: string;
    country: string;
    postalCode: string;
  }>(query, {
    fetchPolicy: "network-only",
    onCompleted: (data: any) => {
      console.log("partner successfully updated");
      syncPartner(data.addPartnerAddress);
      getClientSecret();
    },
  });

  const addPartnerAddress = async function (
    street: string,
    city: string,
    province: string,
    country: string,
    postalCode: string,
  ) {
    await updatePartner({
      variables: {
        street,
        city,
        province,
        country,
        postalCode,
      },
    });
  };
  return addPartnerAddress;
}

export function useAddUserToPremiumPartner(): (
  firstName: string,
  lastName: string,
  userEmail: string,
  paymentId: string,
  employmentStartDate?: string,
  birthday?: string,
) => Promise<void> {
  const { updatePartnerData, setIsLoadingData } = useContext(AuthContext);
  const query = gql(addUserToPremiumPartnerGraphQL);
  const [addUserToPremiumPartnerMutation] = useMutation<{
    partner: Partner;
  }>(query, {
    onCompleted: (result) => {
      console.log("Added a new user!");
      const newPartner = (result as any).addUserToPremiumPartner;
      console.log(newPartner);
      updatePartnerData(newPartner);
      setIsLoadingData(false);
    },
    onError: (error) => {
      setIsLoadingData(false);
      console.log("ERROR: could not add new user: ", error);
    },
  });

  const addUser = async function (
    firstName: string,
    lastName: string,
    userEmail: string,
    paymentId: string,
    employmentStartDate?: string,
    birthday?: string,
  ) {
    console.log(
      "about to call addUserToPremiumPartnerMutation: ",
      firstName,
      lastName,
      userEmail,
      employmentStartDate,
      birthday,
    );
    addUserToPremiumPartnerMutation({
      variables: {
        firstName,
        lastName,
        userEmail,
        paymentId,
        employmentStartDate,
        birthday,
      },
    });
  };

  return addUser;
}

export function useEditUserFromPremiumPartner(): (
  firstName: string,
  lastName: string,
  index: number,
  employmentStartDate?: string,
  birthday?: string,
) => Promise<void> {
  const { updatePartnerData, setIsLoadingData } = useContext(AuthContext);
  const query = gql(editUserFromPremiumPartnerGrapQL);
  const [editUserFromPremiumPartnerMutation] = useMutation<{
    partner: Partner;
  }>(query, {
    onCompleted: (result) => {
      console.log("Edited a user!");
      const newPartner = (result as any).editUserFromPremiumPartner;
      console.log(newPartner);
      updatePartnerData(newPartner);
      setIsLoadingData(false);
    },
    onError: (error) => {
      setIsLoadingData(false);
      console.log("ERROR: could not edit user: ", error);
    },
  });

  const editUser = async function (
    firstName: string,
    lastName: string,
    index: number,
    employmentStartDate?: string,
    birthday?: string,
  ) {
    editUserFromPremiumPartnerMutation({
      variables: { firstName, lastName, index, employmentStartDate, birthday },
    });
  };

  return editUser;
}

export function useRemoveUserToPremiumPartner(): (
  userEmail: string,
) => Promise<void> {
  const { updatePartnerData, setIsLoadingData } = useContext(AuthContext);
  const query = gql(removeUserFromPremiumPartnerGraphQL);
  const [removeUserFromPremiumPartnerMutation] = useMutation<{
    partner: Partner;
  }>(query, {
    onCompleted: (result) => {
      console.log("Removeed a user!");
      const newPartner = (result as any).removeUserFromPremiumPartner;
      console.log(newPartner);
      updatePartnerData(newPartner);
      setIsLoadingData(false);
    },
    onError: (error) => {
      setIsLoadingData(false);
      console.log("ERROR: could not remove user: ", error);
    },
  });

  const removeUser = async function (userEmail: string) {
    removeUserFromPremiumPartnerMutation({ variables: { userEmail } });
  };

  return removeUser;
}

export function useResendEmailToUserFromPremiumPartner(): (
  userResendIndex: number,
) => Promise<void> {
  const { updatePartnerData, setIsLoadingData } = useContext(AuthContext);
  const query = gql(resendEmailToUserFromPremiumPartnerGraphQL);
  const [resendEmailToUserFromPremiumPartnerMutation] = useMutation<{
    partner: Partner;
  }>(query, {
    onCompleted: (result) => {
      console.log("Resent invite to a user!");
      // The next three lines look optional, can remove if you feel like it. Would need testing though.
      // Also mutation & mutation hook are connected
      const newPartner = (result as any).resendEmailToUserFromPremiumPartner;
      console.log(newPartner);
      updatePartnerData(newPartner);
      setIsLoadingData(false);
    },
    onError: (error) => {
      setIsLoadingData(false);
      console.log("ERROR: could not resend invite to user: ", error);
    },
  });

  const resendInviteToUser = async function (userResendIndex: number) {
    resendEmailToUserFromPremiumPartnerMutation({
      variables: { userResendIndex },
    });
  };

  return resendInviteToUser;
}

export function useSaveImageToS3(): (
  filename: string,
  bucket: string,
  data: Buffer,
) => Promise<void> {
  const { setIsLoadingData } = useContext(AuthContext);
  const query = gql(saveImageToS3GraphQL);
  const [saveImageToS3Mutation] = useMutation<{
    savedFileUrl: string;
  }>(query, {
    onCompleted: (result) => {
      console.log("Saved image to S3: ", result);
      // const newPartner = (result as any).removeUserFromPremiumPartner;
      // updatePartnerData(newPartner);
      setIsLoadingData(false);
    },
    onError: (error) => {
      setIsLoadingData(false);
      console.log("ERROR: could not save image to S3: ", error);
    },
  });

  const saveImageToS3 = async function (
    filename: string,
    bucket: string,
    data: Buffer,
  ) {
    saveImageToS3Mutation({ variables: { filename, bucket, data } });
  };

  return saveImageToS3;
}

export function useAirdropGiftCard(): (
  airdropGiftCardRequest: PremiumGiftCardAirdropRequest,
  paymentId: string,
) => Promise<void> {
  const navigate = useNavigate();
  const { setIsLoadingData } = useContext(AuthContext);
  const query = gql(airdropGiftCardGraphQL);
  const [airdropGiftCardMutation] = useMutation<{
    airdropGiftCardRequest: PremiumGiftCardAirdropRequest;
    paymentId: string;
  }>(query, {
    onCompleted: (result) => {
      console.log("Completed Airdrop!");
      setIsLoadingData(false);
      const to = "/dashboard/airdrop/complete";
      navigate(to);
    },
    onError: (error) => {
      console.log("ERROR: could not Airdrop Gift-Card: ", error);
      setIsLoadingData(false);
    },
  });

  const airdropGiftCard = async function (
    airdropGiftCardRequest: PremiumGiftCardAirdropRequest,
    paymentId: string,
  ) {
    airdropGiftCardMutation({
      variables: { airdropGiftCardRequest, paymentId },
    });
  };

  return airdropGiftCard;
}

export function useUpdatePartnerBoostedRewards(): (
  boostedRewardsData: IofferBoost,
) => Promise<void> {
  const { updatePartnerData, setIsLoadingData } = useContext(AuthContext);
  const query = gql(updatePartnerBoostedRewardsGraphQL);
  const [updatePartnerBoostedRewardsMutation] = useMutation<{
    boostedRewardsData: IofferBoost;
  }>(query, {
    onCompleted: (result) => {
      console.log("Completed boosted rewards update!");
      const newPartner = (result as any).updatePartnerBoostedRewards;
      console.log(newPartner);
      updatePartnerData(newPartner);
      setIsLoadingData(false);
    },
    onError: (error) => {
      console.log("ERROR: could not update partner's data: ", error);
      setIsLoadingData(false);
    },
  });

  const updatePartnerBoostedRewards = async function (
    boostedRewardsData: IofferBoost,
  ) {
    updatePartnerBoostedRewardsMutation({
      variables: { boostedRewardsData },
    });
  };

  return updatePartnerBoostedRewards;
}
