import { ColumnDef } from "@tanstack/react-table";

import { MoreHorizontal, ArrowUpDown } from "lucide-react";

import { Button, Checkbox } from "@shadcn";
import { PartnerAirdropTransaction } from "../../../types";

export const airdropHistoryColumns: ColumnDef<PartnerAirdropTransaction>[] = [
  {
    accessorKey: "fullName",
    header: "Full Name",
  },
  {
    accessorKey: "externalRef.data.externalData.brandName",
    header: "Brand",
  },
  {
    accessorKey: "externalRef.data.totalSaleAmount",
    header: "Total Sale Amount",
  },
  {
    accessorKey: "externalRef.data.billingInformation.partner_receipt_url",
    header: "Billing",
    cell: ({ row }) => {
      const value =
        row.original?.externalRef.data?.billingInformation?.partner_receipt_url;
      return (<div>{value ? <div><a className="underline text-neutral-mediumDark hover:text-neutral-dark" href={value} target="_blank">Receipt</a></div> : <div>No receipt</div>}</div>);
    },
  },
];
