import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@shadcn";

import { GiftCardAmountSelectorItem } from "../../../types";
import { useState } from "react";

export interface SelectorProps {
  selectorItems: any[] | null;
}

export const Selector = ({ selectorItems }: SelectorProps) => {
    const [selectorValue, setSelectorValue] = useState("");
  const SelectorTemplate = () => {
    return (
      <Select disabled={!selectorItems} value={selectorValue} onValueChange={(value) => setSelectorValue(value)}>
        <SelectTrigger className="w-[180px]">
          <SelectValue placeholder="Amount" />
        </SelectTrigger>
        <SelectContent>
          {selectorItems?.map((item: GiftCardAmountSelectorItem) => {
            return <SelectItem value={item.value}>{item.text}</SelectItem>;
          })}
          {/* <SelectItem value="light">Light</SelectItem>
          <SelectItem value="dark">Dark</SelectItem>
          <SelectItem value="system">System</SelectItem> */}
        </SelectContent>
      </Select>
    );
  };
  return {
    SelectorTemplate,
    selectorValue,
  }
};
