import { useBlogSectionTemplate } from "./logic";
import { BlogSectionTemplate } from "./template";
import { PagePaths } from "../../../types";

interface BlogProps {
  page: string;
}

export const BlogSection = (props: BlogProps): JSX.Element => {
  const logic = useBlogSectionTemplate({page: props.page});
  return <BlogSectionTemplate {...logic} />;
};
