import { ProfilePageTemplate } from "./template";
import { useProfilePage } from "./logic";

export const ProfilePage = () : JSX.Element => {
    const props = useProfilePage();

    return (
        <ProfilePageTemplate {...props}/>
    )
}
