import {
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useState, useContext, useEffect } from "react";

import { AuthContext } from "@auth";
import { useGetPartnerPaymentMethods, useGetPartnerInvoices, useGetBillingDetails } from "@queries";
import { Partner } from "@api";

export const useBillingPage = (): any => {
    const {partner} = useContext(AuthContext);
    const stripe = useStripe();
    const elements = useElements();
    const { getPartnerPaymentMethods } = useGetPartnerPaymentMethods();
    const { clientSecret, billingDetails } = useContext(AuthContext);
    const { getPartnerInvoices } = useGetPartnerInvoices();
    const { getBillingDetails } = useGetBillingDetails();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [succeeded, setSucceeded] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);
    const [selectedCardIndex, setSelectedCardIndex] = useState<number>(0);
    const [error, setError] = useState<any>(null);

    const isAddressSubmitted = (partner as any)?.address !== null && (partner as any)?.address !== undefined && (partner as Partner)?.address !== null;

    useEffect(() => {
      console.log("----");
      console.log(JSON.stringify(billingDetails, null, 4));

      // load the list of linked payment methods
      if (billingDetails.creditCards === null) getPartnerPaymentMethods();
      // load invoices
      if (billingDetails.invoices === null) getPartnerInvoices();
      // load all billing details (payment methods, invoices, plan amount)
      if (billingDetails.planAmount === null) getBillingDetails();
    }, []);

    function closeModal() {
      setError(null);
      setProcessing(false);
      setSucceeded(false);
      setDisabled(false);
      setModalIsOpen(false);
    }

    useEffect(() => {
      if (succeeded) {
        setTimeout(() => {
          closeModal();
          getPartnerPaymentMethods();
        }, 1500);
      }
    }, [succeeded]);

    function onPressAddCard() {
      setModalIsOpen(true);
    }

    function onPressDownloadInvoice() {
    }

    const handleSubmit = async (event: any) => {
      event.preventDefault();
      setProcessing(true);
  
      if (!stripe || !elements) {
        return;
      }

      // Trigger form validation and wallet collection
      const {error: submitError} = await elements.submit();
      if (submitError) {
        console.log("Error linking card: ", submitError);
        return;
      }

      const payload = await stripe?.confirmSetup({ 
        elements,
        clientSecret,
        redirect: 'if_required',
        confirmParams: {return_url: window.location.href},
      });

      if (payload?.error) {
        setError(`Payment failed ${payload?.error?.message}`);
        setProcessing(false);
      } else {
        setError(null);
        setProcessing(false);
        setSucceeded(true);
      }
    };

    const handleCardInputChange = async (event: any) => {
      // Listen for changes in the CardElement
      // and display any errors as the customer types their card details
      setDisabled(event.empty);
      setError(event.error ? event.error.message : "");
    };

    const handleUpgradeClick = () => {
    };

    const handleSendEmailClick = () => {
      const subject = "Inquiry about Partners Enterprise";
      const body = "Hi. I'd like more information about the Coinmiles Partners Enterprise plan!";
      window.open(`mailto:affiliate@coinmiles.io?subject=${subject}&body=${body}`, "_blank");
    };

    return { 
      partner,
      modalIsOpen,
      closeModal,
      selectedCardIndex,
      onPressAddCard,
      onPressDownloadInvoice,
      cardDataList: billingDetails?.creditCards,
      invoices: billingDetails?.invoices,
      handleSubmit,
      handleCardInputChange,
      succeeded,
      processing,
      disabled,
      error,
      isAddressSubmitted,
      handleUpgradeClick,
      handleSendEmailClick,
      planAmount: billingDetails?.planAmount
    }
};
