import { Layout } from "@layouts";

export const OnlineAffiliatesPageTemplate = (): JSX.Element => {
  return (
    <Layout
      variant="dashboard"
      header="Online Affiliate"
      subheader={
        <h5 className="text-primary-dark text-lg font-normal">
          Figma design not completed...
        </h5>
      }
    >
      <div>online affiliates page </div>
    </Layout>
  );
};
