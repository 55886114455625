import { BrowserRouter, Route, Routes } from "react-router-dom";

import { useUser } from "@auth";
import { SignUpRouter } from "@pages/sign-up/router";
import { SignInRouter } from "@pages/sign-in/router";
import { DashboardRouter } from "@pages/dashboard/router";

const Router = (): JSX.Element => {
  const partner = useUser();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={partner ? <DashboardRouter /> : <SignInRouter />}
        />
        <Route
          path="dashboard/*"
          element={partner ? <DashboardRouter /> : <SignInRouter />}
        />
        <Route
          path="signin/*"
          element={partner ? <DashboardRouter /> : <SignInRouter />}
        />
        <Route
          path="signup/*"
          element={partner ? <DashboardRouter /> : <SignUpRouter />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
