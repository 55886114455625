import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import LogoWhite1x from "./logo-white-1x.png";
import LogoWhite2x from "./logo-white-2x.png";

export const LogoWhiteImage = ({
  height = 30,
  width = 137,
  highResolution = true,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <div style={{ height, width }}>
      <ImageTemplate
        {...props}
        imgSrc={highResolution ? LogoWhite2x : LogoWhite1x}
      />
    </div>
  );
};
