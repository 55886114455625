import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, FormikErrors } from "formik";

import { useSignOut, useSignUp } from "@auth";
import { FormValues, WelcomePageTemplateProps } from "./template";
import { AuthContext } from "../../../auth/auth-context";

export const useWelcomePage = (): WelcomePageTemplateProps => {
  const navigate = useNavigate();
  const signUp = useSignUp();
  const signOut = useSignOut();
  const { newPartnerInfo } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);

  const validate = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.description) {
      errors.description = "Description is required";
    }
    if (!values.organization) {
      errors.organization = "Organization is required";
    }
    if (!values.website) {
      errors.website = "Website URL is required";
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    }
    else if (values.phoneNumber && values.phoneNumber.length !== 10) {
      if (values.phoneNumber && values.phoneNumber.length === 11 && values.phoneNumber.charAt(0) !== "1") {
        errors.phoneNumber = "We currently only support phone numbers from the U.S. and Canada";
      } else {
        errors.phoneNumber = "Phone number uses a 10-digit format like: XXX-XXX-XXXX";
      }
    }

    return errors;
  };

  // regex to remove all non-numeric digits then add a "+" to the front, then it looks like Cognito will magically add the country code, so turns the "+" into a "+1"
  const formatPhoneNumber = (value: string): string => {
    return `+${value.replace(/\D+/g, "")}`;
  };

  const { values, errors, handleChange, handleSubmit, setFieldError } =
    useFormik<FormValues>({
      initialValues: {
        description: "",
        organization: "",
        phoneNumber: "",
        website: "",
      },
      validate,
      validateOnChange: false,
      onSubmit: async (values: FormValues) => {
        setLoading(true);
        const extraInfo = {
          customDescription: values.description,
          customOrganization: values.organization,
          customPhoneNumber: formatPhoneNumber(values.phoneNumber),
          customWebsite: values.website,
        };

        try {
          console.log({ ...newPartnerInfo, ...extraInfo });

          const email = await signUp({ ...newPartnerInfo, ...extraInfo });
          navigate("/signup/confirm", { state: { email } });
        } catch (error: any) {
          setLoading(false);
          if (error.code === "UsernameExistsException") {
            setFieldError("emailAddress", "Invalid email address");
          } else if (error.code === "InvalidParameterException") {
            setFieldError(
              "phoneNumber",
              "Please enter a phone number with a valid format, it will usually begin with +1",
            );
          } else {
            setFieldError("emailAddress", "Something went wrong");
          }
        }
        // Note: old code to update partner info
        // try {
        //   const { username } = await Auth.currentAuthenticatedUser();

        //   console.log(
        //     `Updating partner ${username} with values: ${JSON.stringify(
        //       values,
        //     )}...`,
        //   );

        //   updatePartner(values, username, true);
        //   navigate("/dashboard/home");
        // } catch (error) {
        //   setLoading(false);
        // }
      },
    });

  const handleBackButtonClick = async () => {
    await signOut();
    navigate("/signin");
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e);

  return {
    values,
    errors: {
      organization: errors.organization,
      website: errors.website,
      phoneNumber: errors.phoneNumber,
      description: errors.description,
    },
    loading,
    handleBackButtonClick,
    handleInputChange,
    handleSubmit,
  };
};
