import { BackgroundImage } from "./image-background";
import { HornsImage } from "./image-horns";
import { LogoColourImage } from "./image-logo-colour";
import { LogoWhiteImage } from "./image-logo-white";
import { WavingHandImage } from "./image-waving-hand";
import { VoidChequeImage } from "./image-void-cheque";
import { ChequeLayoutImage } from "./image-cheque-layout";
import { BusinessInfoImage } from "./image-business-info";
import { NewCampaignImage } from "./image-new-campaign";
import { PaymentInfoImage } from "./image-payment-info";
import { PerksProgramImage } from "./image-perks-program";
import { BitcoinLogoImage } from "./image-bitcoin-logo";
import { CheckmarkImage } from "./image-checkmark";
import { BackgroundPhoneImage } from "./image-background-phone";
import { RightArrowImage } from "./image-right-arrow";
import { FlagCanadaImage } from "./image-flag-canada";
import { AmazonLogoImage } from "./image-amazon-logo";
import { BlogImage } from "./image-blog";

type HighResolutionProps = {
  highResolution?: boolean;
};

type DynamicImageProps = { 
  imageType?: string 
};

type RectangularIconProps = {
  width?: number | string;
  height?: number | string;
} & HighResolutionProps & DynamicImageProps;


export type ImageVariantType =
  | "horns"
  | "background"
  | "backgroundPhone"
  | "logoColour"
  | "logoWhite"
  | "wavingHand"
  | "voidCheque"
  | "chequeLayout"
  | "businessInfo"
  | "paymentInfo"
  | "perksProgram"
  | "checkmark"
  | "bitcoinLogo"
  | "newCampaign"
  | "rightArrow"
  | "flagCanada"
  | "blog"
  | "amazonLogo";

export type ImageProps =
  | ({ variant: "horns" } & RectangularIconProps)
  | ({ variant: "background" } & RectangularIconProps)
  | ({ variant: "backgroundPhone" } & RectangularIconProps)
  | ({ variant: "logoColour" } & RectangularIconProps)
  | ({ variant: "logoWhite" } & RectangularIconProps)
  | ({ variant: "wavingHand" } & RectangularIconProps)
  | ({ variant: "voidCheque" } & RectangularIconProps)
  | ({ variant: "chequeLayout" } & RectangularIconProps)
  | ({ variant: "businessInfo" } & RectangularIconProps)
  | ({ variant: "paymentInfo" } & RectangularIconProps)
  | ({ variant: "perksProgram" } & RectangularIconProps)
  | ({ variant: "checkmark" } & RectangularIconProps)
  | ({ variant: "bitcoinLogo" } & RectangularIconProps)
  | ({ variant: "newCampaign" } & RectangularIconProps)
  | ({ variant: "rightArrow" } & RectangularIconProps)
  | ({ variant: "flagCanada" } & RectangularIconProps)
  | ({ variant: "blog" } & RectangularIconProps)
  | ({ variant: "amazonLogo" } & RectangularIconProps);

export const Image = (props: ImageProps): JSX.Element => {
  const { variant } = props;
  switch (variant) {
    case "horns":
      return <HornsImage {...props} />;
    case "background":
      return <BackgroundImage {...props} />;
    case "backgroundPhone":
      return <BackgroundPhoneImage {...props} />;
    case "blog":
      return <BlogImage {...props} />;
    case "logoColour":
      return <LogoColourImage {...props} />;
    case "logoWhite":
      return <LogoWhiteImage {...props} />;
    case "wavingHand":
      return <WavingHandImage {...props} />;
    case "voidCheque":
      return <VoidChequeImage {...props} />;
    case "chequeLayout":
      return <ChequeLayoutImage {...props} />;
    case "businessInfo":
      return <BusinessInfoImage {...props} />;
    case "paymentInfo":
      return <PaymentInfoImage {...props} />;
    case "perksProgram":
      return <PerksProgramImage {...props} />;      
    case "bitcoinLogo":
      return <BitcoinLogoImage {...props} />;
    case "checkmark":
      return <CheckmarkImage {...props} />;  
    case "newCampaign":
      return <NewCampaignImage {...props} />;
    case "rightArrow":
      return <RightArrowImage {...props} />;
    case "flagCanada":
      return <FlagCanadaImage {...props} />;
    case "amazonLogo":
      return <AmazonLogoImage {...props} />;         
    default:
      throw new Error(`Invalid Image variant [${variant}] provided`);
  }
};
