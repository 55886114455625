import { useState, useContext } from "react";
import { useFormik, FormikErrors } from "formik";
import { useSignUp, SignUpBasicInput, SocialProvider, useSocialSignIn } from "@auth";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../auth/auth-context";

import { FormValues, SignUpPageTemplateProps } from "./template";

export const useSignUpPage = (): SignUpPageTemplateProps => {
  const navigate = useNavigate();
  const signUp = useSignUp();
  const socialSignIn = useSocialSignIn();
  const { setNewPartnerInfo } = useContext(AuthContext);

  const [loading, setLoading] = useState<boolean>(false);

  const validate = (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.firstName) {
      errors.firstName = "First name is required";
    }
    if (!values.lastName) {
      errors.lastName = "Last name is required";
    }
    if (!values.emailAddress) {
      errors.emailAddress = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)
    ) {
      errors.emailAddress = "Invalid email address";
    }
    if (!values.password) {
      errors.password = "Password is required";
    } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(values.password)) {
      errors.password =
        "Password must contain one upper case character, one lower case character and one number";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Password confirmation is required";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Password confirmation must match password";
    }
    return errors;
  };

  const { values, errors, handleChange, handleSubmit, setFieldError } =
    useFormik<FormValues>({
      initialValues: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        password: "",
        confirmPassword: "",
      },
      validate,
      validateOnChange: false,
      onSubmit: async (values: SignUpBasicInput) => {
        setNewPartnerInfo(values);
        navigate("/signup/welcome");
      },
    });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleChange(e);
  const handleSignUpWithGoogle = () => socialSignIn(SocialProvider.Google);

  const handleSignInRedirect = () => navigate("/signin");

  return {
    values,
    errors: {
      firstName: errors.firstName,
      lastName: errors.lastName,
      emailAddress: errors.emailAddress,
      password: errors.password,
      confirmPassword: errors.confirmPassword,
    },
    loading,
    handleInputChange,
    handleSignUpWithGoogle,
    handleSignInRedirect,
    handleSubmit,
  };
};
