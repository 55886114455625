import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import Background1x from "./business-info-1x.png";
import Background2x from "./business-info-2x.png";

export const BusinessInfoImage = ({
  height,
  width,
  highResolution,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <div style={{ height, width }}>
      <ImageTemplate
        {...props}
        imgSrc={highResolution ? Background2x : Background1x}
      />
    </div>
  );
};
