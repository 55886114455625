import { Item } from "@molecules";
import { Input } from "@atoms";
import { Layout } from "@layouts";
import { Bar } from "@organisms";
import { Button as SButton, Progress, Input as SInput } from "@shadcn";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { GiftCardOffer } from "../../../../types";
import { useState } from "react";
import { maxHeaderSize } from "http";

export type AirdropSelectAmountPageTemplateProps = {
  offerSelected: GiftCardOffer;
  progress: number;
  onReturnPressed: () => void;
  onContinuePressed: (data: any, giftMessage: string) => void;
};

export const AirdropSelectAmountPageTemplate = ({
  offerSelected,
  progress,
  onReturnPressed,
  onContinuePressed,
}: AirdropSelectAmountPageTemplateProps): JSX.Element => {
  const [selectedAmount, setSelectedAmount] = useState<number | null>(null);
  const [typedAmount, setTypedAmount] = useState<number | null>(null);
  const [giftMessage, setGiftMessage] = useState<string>("");

  const modifyGiftMessage = (message: string):void => {
    if (message.length < 100) {
      setGiftMessage(message);
    }
  }

  const minAmount =
    offerSelected.activationSettings.forcedMinActivationAmount ??
    offerSelected.activationSettings.minActivationAmount;
  const maxAmount =
    offerSelected.activationSettings.forcedMaxActivationAmount ??
    offerSelected.activationSettings.maxActivationAmount;
  const presetAmounts = offerSelected.presetAmounts.filter(
    (item) => item !== 0,
  );
  const isCustomisable = offerSelected.presetAmounts.includes(0);

  return (
    <Layout
      variant="dashboard"
      header="Airdrops"
      subheader={
        <div className="flex flex-row items-start justify-between max-w-5xl">
          <div className="text-neutral-dark text-base font-normal mt-2">
            Send Gift Cards to your employees
          </div>
        </div>
      }
    >
      <div className="px-1 mr-4 rounded-md max-w-5xl h-52">
        {/* <div className="flex items-center justify-center w-5xl">
          <Progress value={progress} className="w-1/2" />
        </div> */}
        <div className="mt-4 mb-3">
          <h2 className="text-xl font-normal">
            3. Which amount?
          </h2>
        </div>
        <div className={`flex flex-row flex-wrap items-start justify-start max-w-5xl rounded-md pb-8`}>
          <div className="rounded-md my-2 ml-2 mr-5 min-w-max border">
            <img
              src={offerSelected?.images?.[0]?.imageUrl}
              className="rounded-md h-48 w-80"
            />
          </div>
          <div className="flex flex-col justify-start mr-8 my-2 mb-16 pb-2 rounded-md">
            {presetAmounts.length !== 0 ? (
              <div>
                <h3 className="font-semibold flex justify-start ml-2">
                  Pre-set amounts
                </h3>
                <div className="flex flex-row justify-start mt-2">
                  {presetAmounts.map((item) => {
                    return (
                      <div
                        key={item}
                        className={`w-12 mx-2 bg-white  ${
                          selectedAmount === item
                            ? `bg-primary-medium`
                            : `bg-gray-100`
                        } rounded-md flex justify-center items-center`}
                      >
                        <SButton
                          variant="ghost"
                          className="w-full rounded-md"
                          onClick={() => {
                            setSelectedAmount(item);
                            setTypedAmount(0);
                          }}
                        >{`$${item}`}</SButton>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            {/* {presetAmounts.length > 0 && isCustomisable ? (
              <div className="text-black my-2 font-semibold flex justify-center">
                - OR -
              </div>
            ) : null} */}
            {isCustomisable ? (
              <div className="ml-2 mt-4 mb-2">
                <div className="font-semibold flex justify-start">
                  Enter a custom amount
                </div>
                <div className="flex items-center justify-start mt-2">
                  <SInput
                    type="number"
                    lang="en-US"
                    min={
                      offerSelected.activationSettings
                        .forcedMinActivationAmount ??
                      offerSelected.activationSettings.minActivationAmount
                    }
                    max={
                      offerSelected.activationSettings
                        .forcedMaxActivationAmount ??
                      offerSelected.activationSettings.maxActivationAmount
                    }
                    placeholder="Enter an Amount"
                    step="0.01"
                    value={typedAmount || undefined}
                    onChange={(event) => {
                      setTypedAmount(Number(event?.target.value));
                      setSelectedAmount(null);
                    }}
                    className="h-10 w-40 bg-gray-100 rounded-md"
                  />
                </div>
              </div>
            ) : null}
            <div className="ml-2 mt-2 font-semibold w-68">
              <div>
                Add a short message
              </div>
              <div className="flex items-center justify-start mt-2 w-full">
                <Input
                  name="giftmessage"
                  variant="textArea"
                  onChange={(event) => {
                    modifyGiftMessage(event?.target.value)
                  }}                  
                  placeholder="100 characters maximum"
                  value={giftMessage}
                  errorMessage={""}
                  height={75}
                />
              </div>
            </div>
          </div>
        </div>
        <Bar
          variant="bottom"
          onReturnPressed={onReturnPressed}
          onContinuePressed={() => {
            selectedAmount
              ? onContinuePressed(selectedAmount, giftMessage)
              : onContinuePressed(typedAmount, giftMessage);
          }}
          isContinueDisabled={!selectedAmount &&
            !(
              typedAmount &&
              typedAmount >= minAmount &&
              typedAmount <= maxAmount
            )}
        />
      </div>
    </Layout>
  );
};
