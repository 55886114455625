import { Layout } from "@layouts";
import { Bar } from "@organisms";
import { Input as SInput, Progress } from "@shadcn";
import { useEffect, useState, useContext } from "react";
import { GiftCardOffer } from "../../../../types";
import { PremiumGiftCardAirdropRequest, Partner } from "@api";
import { AuthContext } from "@auth";

export type AirdropSelectOfferPageTemplateProps = {
  premiumUsers: any[];
  giftCardOffers: GiftCardOffer[] | null;
  progress: number;
  onContinuePressed: (data: any) => void;
  onReturnPressed: () => void;
};

export type RowRenderingProps = {
  item: any;
  index: any;
};

export const AirdropSelectOfferPageTemplate = ({
  premiumUsers,
  giftCardOffers,
  progress,
  onContinuePressed,
  onReturnPressed,
}: AirdropSelectOfferPageTemplateProps): JSX.Element => {
  const { partner } = useContext(AuthContext);
  
  const [selectedGiftCardOffer, setSelectedGiftCardOffer] = useState<GiftCardOffer | null>(null);
  const [filteredGiftCardOffers, setFilteredGiftCardOffers] = useState<GiftCardOffer[] | null>(giftCardOffers);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    console.log(searchTerm)
    setFilteredGiftCardOffers(giftCardOffers?.filter((item) => item.brandName.toLowerCase().includes(searchTerm.toLowerCase())) ?? []);
    console.log(filteredGiftCardOffers);
  }, [searchTerm]);
  useEffect(() => {
    setFilteredGiftCardOffers(giftCardOffers)
  }, [giftCardOffers])
  
  /* const airdropGiftCardTrigger = () => {
    const airdropGiftCardRequest: PremiumGiftCardAirdropRequest = {premiumPartnerId: partner?. || "", giftCardOfferObjectId: giftCardOffers?.[Number(Object.keys(rowSelection))].id || "", amount: Number(selectorValue), network: giftCardOffers?.[Number(Object.keys(rowSelection))]?.networkName };
  } */

  function renderListItem(offer: GiftCardOffer): React.ReactElement {
    return (
      <div className="flex flex-col justify-start truncate m-4 transition transform scale-100 ease-in-out delay-0 duration-200 hover:scale-105">
        <button className="truncate" onClick={() => selectedGiftCardOffer === offer ? setSelectedGiftCardOffer(null) : setSelectedGiftCardOffer(offer)}>
          <img
            src={offer?.images?.[0]?.imageUrl}
            className={`h-32 w-56  ${selectedGiftCardOffer === offer ? " border-4 border-primary-medium" : "border border-neutral-light"} rounded-lg `}
          />
        </button>
        <div className="w-56 truncate pt-1">
        {offer?.brandName}
        </div>
      </div>
    );
  }

  const renderItem = ({ item, index }: RowRenderingProps) => {
    if (!item) return null;

    return (
      <div className="flex flex-row text-clip overflow-hidden">
        {renderListItem(item)}
      </div>
    );
  };

    // if (index % 4 === 0) {
    //   // render a pair of 2 cards
    //   return renderRow(
    //     filteredGiftCardOffers ? filteredGiftCardOffers?.[index] : null,
    //     filteredGiftCardOffers && index + 1 < filteredGiftCardOffers?.length
    //       ? filteredGiftCardOffers?.[index + 1]
    //       : null,
    //       filteredGiftCardOffers && index + 2 < filteredGiftCardOffers?.length
    //       ? filteredGiftCardOffers?.[index + 2]
    //       : null,
    //       filteredGiftCardOffers && index + 3 < filteredGiftCardOffers?.length
    //       ? filteredGiftCardOffers?.[index + 3]
    //       : null,
    //   );
    // } else {
    //   return null;
    // }


    // function renderRow(
  //   offer1: GiftCardOffer | null,
  //   offer2: GiftCardOffer | null,
  //   offer3: GiftCardOffer | null,
  //   offer4: GiftCardOffer | null,
  // ): React.ReactElement {
  //   return (
  //     <div className="flex flex-row text-clip overflow-hidden">
  //       {offer1 && renderListItem(offer1)}
  //       {offer2 && renderListItem(offer2)}
  //       {offer3 && renderListItem(offer3)}
  //       {offer4 && renderListItem(offer4)}
  //     </div>
  //   );
  // }

  return (
    <Layout
      variant="dashboard"
      header="Airdrops"
      subheader={
        <div className="flex flex-row items-start justify-between max-w-5xl">
          <div className="text-neutral-dark text-base font-normal mt-2">
            Send Gift Cards to your employees
          </div>
        </div>
      }
    >
      <div className=" px-1 mr-4 rounded-md">
        {/* <div className="flex items-center justify-center"> 
          <Progress value={progress} className="w-1/2" />
        </div> */}
        <div className="mt-4">
          <h2 className="text-xl font-normal">
            2. Which reward do you want to Airdrop?
          </h2>
        </div>
        <div className="flex items-center py-4">
          <SInput
            placeholder="Search by offer name"
            value={
              searchTerm ??
              ""
            }
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            className="max-w-sm"
          />
        </div>
        {giftCardOffers?.length ? (
          <div className=" flex flex-row flex-wrap h-full pb-16">
            {filteredGiftCardOffers?.map((item, index) => renderItem({ item, index }))}
          </div>
        ) : (
          <div>Gift Cards Offers are currently loading...</div>
        )}
        <Bar
          variant="bottom"
          onReturnPressed={onReturnPressed}
          onContinuePressed={() =>
            onContinuePressed(
              selectedGiftCardOffer,
            )}
          isContinueDisabled={selectedGiftCardOffer === null}
        />
      </div>
    </Layout>
  );
};
