import dayjs from "dayjs";
import "dayjs/locale/en-ca";

export const useDateUtils = (): {
  formatDate: (value: number) => string;
} => {

  const formatDate = (timestamp: number): string => {
    const date = new Date(timestamp * 1000);
    const formattedDate = dayjs(date).format("DD-MM-YYYY");
    return formattedDate;
  }

  return {
    formatDate
  };
};