import { Layout } from "@layouts";
import { AirdropHistTable, Bar, useGiftCardOffersTable } from "@organisms";
import {
  Card,
  Item,
  Selector,
  airdropHistoryColumns,
  giftCardOfferColumns,
} from "@molecules";
import { useEffect, useState, useContext } from "react";
import { Button as SButton } from "@shadcn";
import {
  GiftCardAmountSelectorItem,
  GiftCardOffer,
  PartnerAirdropTransaction,
} from "../../../../types";
import { PremiumGiftCardAirdropRequest, CurrencyTypes, Partner } from "@api";
import { AuthContext } from "@auth";
import { Image, ImageVariantType } from "@atoms";

export type AirdropHistoryPageTemplateProps = {
  premiumUsers: any[];
  giftCardOffers: GiftCardOffer[] | null;
  navigateToPage: (index: number, to: string) => void;
  partnerAirdropTransactions: PartnerAirdropTransaction[];
};

export const AirdropHistoryPageTemplate = ({
  premiumUsers,
  giftCardOffers,
  navigateToPage,
  partnerAirdropTransactions,
}: AirdropHistoryPageTemplateProps): JSX.Element => {
  const { partner } = useContext(AuthContext);
  const [giftCardOfferAmounts, setGiftCardOfferAmounts] =
    useState<Array<GiftCardAmountSelectorItem> | null>(null);
  const { ActualTable, rowSelection } = useGiftCardOffersTable({
    columns: giftCardOfferColumns,
    data: giftCardOffers,
    searchable: true,
  });
  const { SelectorTemplate, selectorValue } = Selector({
    selectorItems: giftCardOfferAmounts,
  });

  const { AirdropHistoryTable } = AirdropHistTable({
    columns: airdropHistoryColumns,
    data: partnerAirdropTransactions
      ? partnerAirdropTransactions.map((item) => {
          const premiumUser = premiumUsers.find(
            (item2) => item2.userId === item.userId,
          );
          const fullName = premiumUser.firstName + " " + premiumUser.lastName;
          return { ...item, fullName };
        })
      : [],
    searchable: false,
  });

  useEffect(() => {
    const keys = Object.keys(rowSelection);
    if (keys.length > 0) {
      const presetAmounts = giftCardOffers?.[Number(keys)].presetAmounts.filter(
        (item) => item !== 0,
      );
      const giftCardOfferAmounts: GiftCardAmountSelectorItem[] | undefined =
        presetAmounts?.map((item): GiftCardAmountSelectorItem => {
          return {
            value: item.toString(),
            text: `${item.toString()}`,
          };
        });
      giftCardOfferAmounts === undefined
        ? setGiftCardOfferAmounts(null)
        : setGiftCardOfferAmounts(giftCardOfferAmounts);
    } else if (keys.length === 0) {
      setGiftCardOfferAmounts(null);
    }
  }, [rowSelection]);

  useEffect(() => {
    setGiftCardOfferAmounts(null);
  }, []);

  const onReturnPressed = () => {
    const to = "/dashboard/airdrop/main";
    navigateToPage(3, to);
  };

  /* const airdropGiftCardTrigger = () => {
    const airdropGiftCardRequest: PremiumGiftCardAirdropRequest = {premiumPartnerId: partner?. || "", giftCardOfferObjectId: giftCardOffers?.[Number(Object.keys(rowSelection))].id || "", amount: Number(selectorValue), network: giftCardOffers?.[Number(Object.keys(rowSelection))]?.networkName };
  } */

  return (
    <Layout
      variant="dashboard"
      header="Airdrop History"
      subheader={
        <div className="flex flex-row justify-between max-w-5xl">
          <div className="text-neutral-dark text-base font-normal mt-2">
            View all your previously sent Airdrops
          </div>
        </div>
      }
    >
      <div className="flex flex-col h-full">
        {/* <div className="flex items-center justify-center">
          <Progress value={progress} className="w-1/2" />
        </div> */}
        <div className="mt-4">
          <h2 className="text-2xl font-semibold">{"Your History"}</h2>
        </div>
        {partnerAirdropTransactions.length ? (
          <div className="p-1 mr-4 rounded-md max-w-5xl">
            <AirdropHistoryTable />
          </div>
        ) : (
          <div>You have not sent any Airdrops to your employees yet.</div>
        )}
        <Bar
          variant="bottom"
          onReturnPressed={onReturnPressed}
          onContinuePressed={null}
          isContinueDisabled={true}
        />
      </div>
    </Layout>
  );
};
