import { useState } from "react";
import { useDropdownInputTemplate } from "./template-logic";
import Select, { StylesConfig } from 'react-select';

export type DropdownInputTemplateProps = {
  type: string;
  placeholder: any;
  value: string;
  onChange: (e: any) => void;
  name: string;
  errorMessage: string | undefined;
  options: any[];
};

export const DropdownInputTemplate = ({
  type,
  placeholder,
  value,
  onChange,
  name,
  errorMessage,
  options
}: DropdownInputTemplateProps): JSX.Element => {
  const { isActive, toggleFocus, toggleBlur, inputRef } = useDropdownInputTemplate(
    value,
  );
  const [selectedOption, setSelectedOption] = useState<any>(null);

  const colourStyles: StylesConfig<any> = {
    control: (styles) => ({ ...styles, backgroundColor: "#fff" }), // white
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected
          ? "#FFE457" // primary-medium
          : isFocused
          ? "#ECEBE7" // neutral-light
          : "#fff", // white
        color: isSelected ? "#201A15" : "#201A15", // neutral-darkest, neutral-darkest
        cursor: 'default',
        ':active': {
          ...styles[':active'],
          backgroundColor: isSelected
              ? "#FFE457" // primary-medium
              : "#fff", // white
        },
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };

  return (
    <div>
      <Select
        defaultValue={placeholder}
        onChange={onChange}
        options={options}
        styles={colourStyles}
        // key={value ? `unique_select_key__${type}_${value ? value : "empty"}` : null} // this is used to force a screen refresh when the value changes, to support programmatically changing the value
      />
      {errorMessage ? (
        <div className="text-left text-xs text-orange-600 ml-2 mt-1">
          {errorMessage}
        </div>
      ) : null}
    </div>
  );
};
