export const useCurrencyUtils = (): {
  convertToCurrencyFormat: (value: number) => string;
} => {

  const convertToCurrencyFormat = (value: number): string => {
    if (value === 0) return "$0.00";
    return `$${(value / 100).toFixed(2)}`
  }

  return {
    convertToCurrencyFormat
  };
};