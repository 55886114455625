import { SubscriptionPlansTypes } from "../../../types";

export const subscriptionPlanData = {
  [SubscriptionPlansTypes.basic] : {
    title: "Basic",
    subtitle: "CAD $5 / Month / Employee + “À La Carte” (optional)",
    points: [
      "Online Rewards",
      "Gift Card Rewards",
      "Travel Rewards",
      "Celebrations",
      "Airdrops"
    ]
  },
  [SubscriptionPlansTypes.enterprise] : {
    title: "Enterprise",
    subtitle: "CAD $25 / Month / Employee + “À La Carte” (optional)",
    points: [
      "Online Rewards",
      "Gift Card Rewards",
      "Travel Rewards",
      "Celebrations",
      "Airdrops"
    ]
  }
}