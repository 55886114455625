
import { useContext } from "react";
import { Button, Image } from "@atoms";
import { AuthContext } from "@auth";

export type UserInfoCardTemplateProps = {
  onClick: any;
};

export const UserInfoCardTemplate = ({
  onClick,
}: UserInfoCardTemplateProps): JSX.Element => {

  const { partner } = useContext(AuthContext);

  const getUserInitials = (firstName: string, lastName: string): string =>
  firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();

  let userInitials = "";
  if (partner) {
    userInitials = getUserInitials(partner?.firstName, partner?.lastName);
  }

  return (
    <div className="flex flex-row w-full h-auto max-w-max items-center justify-start">       
      <div className="flex w-10 h-10 p-3 mr-3 text-base text-neutral-dark text-sm font-semibold bg-neutral-medium rounded-3xl justify-center items-center">
        {userInitials}
      </div>
      <div className="flex flex-col">
        <div className="font-semibold text-sm leading-5 text-neutral-darkest">{`${partner?.firstName} ${partner?.lastName}`}</div>
        <div className="font-normal text-xs text-neutral-dark">{partner?.emailAddress}</div>
      </div>
    </div>
  );
};
