import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import Arrow1x from "./arrow-right-1x.png";
import Arrow2x from "./arrow-right-2x.png";

export const RightArrowImage = ({
  height = 14,
  width = 14,
  highResolution = false,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <div style={{ height, width }}>
      <ImageTemplate {...props} imgSrc={highResolution ? Arrow2x : Arrow1x} />
    </div>
  );
};
