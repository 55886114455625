import { useUser } from "@auth";
import { CelebrationsCustomPageTemplateProps } from "./template";
import { useNavigate } from "react-router-dom";

export const useCelebrationsCustomPage = (): CelebrationsCustomPageTemplateProps => {
    const navigate = useNavigate();
    const partner = useUser();
    

    const navigateToPage = (index: number, to: string) => {
        navigate(to);
      }

    return {
        premiumUsers: partner?.premiumSettings?.[0]?.users.filter(item => item.userId !== "") || [],
        navigateToPage,
    }
}