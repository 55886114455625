import { SidebarItem, SidebarItemProps } from "./item-sidebar";
import { EmployeeItem, EmployeeItemProps } from "./item-employee";
import { BetaItem } from "./item-beta";

export type ItemProps = | ({ variant: "sidebar" } & SidebarItemProps) | ({variant: "employee"} & EmployeeItemProps) | ({variant: "beta"});

export const Item = (props: ItemProps): JSX.Element => {
  switch (props.variant) {
    case "sidebar":
      return <SidebarItem {...props} />;
    case "employee":
      return <EmployeeItem {...props} />;
    case "beta":
      return <BetaItem />;
    default:
      throw new Error(`[ITEM ERROR] Invalid variant provided`);
  }
};
