import { Layout } from "@layouts";
import { Bar, DataTable, useGiftCardOffersTable } from "@organisms";
import {
  Card,
  Item,
  Selector,
  airdropColumns,
  giftCardOfferColumns,
} from "@molecules";
import { useEffect, useState, useContext } from "react";
import { GiftCardAmountSelectorItem, GiftCardOffer } from "../../../../types";
import { PremiumGiftCardAirdropRequest, CurrencyTypes, Partner } from "@api";
import { AuthContext } from "@auth";
import { todos } from "./card-data";
import { Image, ImageVariantType } from "@atoms";
import { Button as SButton } from "@shadcn";

export type AirdropPageTemplateProps = {
  premiumUsers: any[];
  giftCardOffers: GiftCardOffer[] | null;
  navigateToPage: (index: number, to: string) => void;
  onReturnPressed: () => void;
};

export const AirdropPageTemplate = ({
  premiumUsers,
  giftCardOffers,
  navigateToPage,
  onReturnPressed
}: AirdropPageTemplateProps): JSX.Element => {
  const { partner } = useContext(AuthContext);
  const [giftCardOfferAmounts, setGiftCardOfferAmounts] =
    useState<Array<GiftCardAmountSelectorItem> | null>(null);
  const { ActualTable, rowSelection } = useGiftCardOffersTable({
    columns: giftCardOfferColumns,
    data: giftCardOffers,
    searchable: true,
  });
  const { SelectorTemplate, selectorValue } = Selector({
    selectorItems: giftCardOfferAmounts,
  });
  const { TeamTable } = DataTable({columns: airdropColumns, data: premiumUsers, searchable: true})


  useEffect(() => {
    const keys = Object.keys(rowSelection);
    if (keys.length > 0) {
      const presetAmounts = giftCardOffers?.[Number(keys)].presetAmounts.filter(
        (item) => item !== 0,
      );
      const giftCardOfferAmounts: GiftCardAmountSelectorItem[] | undefined =
        presetAmounts?.map((item): GiftCardAmountSelectorItem => {
          return {
            value: item.toString(),
            text: `${item.toString()}`,
          };
        });
      giftCardOfferAmounts === undefined
        ? setGiftCardOfferAmounts(null)
        : setGiftCardOfferAmounts(giftCardOfferAmounts);
    } else if (keys.length === 0) {
      setGiftCardOfferAmounts(null);
    }
  }, [rowSelection]);

  useEffect(() => {
    setGiftCardOfferAmounts(null);
  }, []);

  /* const airdropGiftCardTrigger = () => {
    const airdropGiftCardRequest: PremiumGiftCardAirdropRequest = {premiumPartnerId: partner?. || "", giftCardOfferObjectId: giftCardOffers?.[Number(Object.keys(rowSelection))].id || "", amount: Number(selectorValue), network: giftCardOffers?.[Number(Object.keys(rowSelection))]?.networkName };
  } */

  return (
    <Layout
      variant="dashboard"
      header="Airdrops"
      subheader={
        <div className="flex flex-row justify-between max-w-5xl">
          <div className="text-neutral-dark text-base font-normal mt-2">
            Send Gift Cards to your employees
          </div>
        </div>
      }
    >
      <div className="flex flex-col h-full">
        <div className=" flex flex-col pb-8">
          <div className="flex flex-row py-1 my-4 rounded-md max-w-5xl">
            {todos.map(({ variant, title, description, navigationString }, index) => (
              <div key={index} className="mr-5 mb-5 max-w-min">
                <Card
                  onClick={() => navigateToPage(index + 1, "/dashboard/" + navigationString)}
                  variant="onboard"
                  icon={<Image variant={variant} width={204} height={152} />}
                  title={title}
                  description={description}
                  index={index}
                  isSectionIncomplete={true}
                />
              </div>
            ))}
          </div>
        </div>
        <Bar
          variant="bottom"
          onReturnPressed={onReturnPressed}
          onContinuePressed={null}
          isContinueDisabled={true}
        />
      </div>
    </Layout>
  );
};
