import { Layout } from "@layouts";
import { Item, airdropConfirmationColumns } from "@molecules";
import { DataTable } from "@organisms";
import { Button as SButton, Progress } from "@shadcn";
import { CurrencyTypes, PremiumGiftCardAirdropRequest } from "@api";
import { useNavigate } from "react-router";

export type AirdropCompletePageTemplateProps = {
  progress: number;
  airdropGiftCard: (
    airdropGiftCardRequest: PremiumGiftCardAirdropRequest,
    paymentId: string,
  ) => Promise<void>;
};

export const AirdropCompletePageTemplate = ({
  progress,
  airdropGiftCard,
}: AirdropCompletePageTemplateProps) => {
  const navigate = useNavigate();

  const onReturnToAirdropPage = () => {
    const to = "/dashboard/airdrop/select-members";
    navigate(to);
  }

  const onReturnToHomePage = () => {
    const to = "/dashboard/home";
    navigate(to);
  }

  return (
    <Layout
      variant="dashboard"
      header="Airdrops"
      subheader={
        <div className="flex flex-row items-start justify-between max-w-5xl">
          <div className="text-neutral-dark text-base font-normal mt-2">
            Send Gift Cards to your employees
          </div>
        </div>
      }
    >
      <div className="p-1 mr-4 rounded-md max-w-3xl">
        <h2 className="text-xl font-normal pt-3 pb-3">
          Congratulations, your Airdrop gift card has just been sent!
        </h2>
        <div className="flex flex-row items-center justify-start mt-4 max-w-3xl">
          <SButton
            variant="default"
            onClick={onReturnToAirdropPage}
          >
            Send another Airdrop
          </SButton>
          <div className="w-2"></div>
          <SButton
            variant="outline"
            onClick={onReturnToHomePage}
            className="ml-2 border border-black shadow shadow-none"
          >
            Return Home
          </SButton>
        </div>
      </div>
    </Layout>
  );
};
