import { Amplify } from "@aws-amplify/core";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { AuthProvider } from "@auth";
//import awsconfig from "@aws";

import { ApolloProvider, UserPoolClient } from "./apollo-config";
import { amplifyConfig } from "@config";
import { Env } from "@config";
import reportWebVitals from "./reportWebVitals";
import Router from "./router";
import "./index.css";
import { Auth } from "aws-amplify";

Amplify.configure({
  ...amplifyConfig,
  aws_user_files_s3_bucket_region: Env.REGION, // (required) - Amazon S3 bucket region
  aws_user_files_s3_bucket: Env.S3_IMAGE_BUCKET // (required) - Amazon S3 bucket URI
});
Auth.configure(amplifyConfig);

ReactDOM.render(
  <StrictMode>
    <ApolloProvider client={UserPoolClient}>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </ApolloProvider>
  </StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
