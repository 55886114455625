import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import Horns1x from "./horns-and-stars-1x.png";
import Horns2x from "./horns-and-stars-2x.png";

export const HornsImage = ({
  height = 116,
  width = 176,
  highResolution = true,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <div style={{ height, width }}>
      <ImageTemplate {...props} imgSrc={highResolution ? Horns2x : Horns1x} />
    </div>
  );
};
