import { Icon } from "@atoms";

export type PartnersButtonTemplateProps = {
  imgSrc: string;
  onClick: () => void;
};

export const PartnersButtonTemplate = ({
  imgSrc,
  onClick,
}: PartnersButtonTemplateProps): JSX.Element => {
  return (
    <button onClick={onClick} className="flex items-center">
      <div
        className="rounded-full w-8 h-8 mr-2"
        style={{
          backgroundImage: `url('${imgSrc}')`,
        }}
      />
      <Icon variant="chevronDown" width={8} height={4} />
    </button>
  );
};
