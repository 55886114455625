import { Layout } from "@layouts";
import { Bar, DataTable } from "@organisms";
import {
  airdropColumns,
} from "@molecules";
import { Button as SButton, Progress } from "@shadcn";
import { useEffect, useState, useContext } from "react";
import { GiftCardAmountSelectorItem, GiftCardOffer } from "../../../../types";
import { PremiumGiftCardAirdropRequest, Partner, PremiumUser } from "@api";
import { AuthContext } from "@auth";

export type AirdropSelectMembersPageTemplateProps = {
  premiumUsers: any[];
  giftCardOffers: GiftCardOffer[] | null;
  progress: number;
  onContinuePressed: (data: PremiumUser) => void;
  onReturnPressed: () => void;
};

export const AirdropSelectMembersPageTemplate = ({
  premiumUsers,
  onContinuePressed,
  onReturnPressed,
}: AirdropSelectMembersPageTemplateProps): JSX.Element => {
  const { partner } = useContext(AuthContext);
  const [giftCardOfferAmounts, setGiftCardOfferAmounts] =
    useState<Array<GiftCardAmountSelectorItem> | null>(null);
  
  const { TeamTable, rowSelection } = DataTable({
    columns: airdropColumns,
    data: premiumUsers,
    searchable: true,
  });

  useEffect(() => {
    const keys = Object.keys(rowSelection);
    if (keys.length > 0) {
      console.log("User selected");
    } else if (keys.length === 0) {
      console.log("User not selected");
    }
  }, [rowSelection]);

  /* const airdropGiftCardTrigger = () => {
    const airdropGiftCardRequest: PremiumGiftCardAirdropRequest = {premiumPartnerId: partner?. || "", giftCardOfferObjectId: giftCardOffers?.[Number(Object.keys(rowSelection))].id || "", amount: Number(selectorValue), network: giftCardOffers?.[Number(Object.keys(rowSelection))]?.networkName };
  } */

  return (
    <Layout
      variant="dashboard"
      header="Airdrops"
      subheader={
        <div className="flex flex-row items-start justify-between max-w-5xl">
          <div className="text-neutral-dark text-base font-normal mt-2">
            Send Gift Cards to your employees
          </div>
        </div>
      }
    >
      <div className="mr-4 rounded-md max-w-5xl">
        {/* <div className="flex items-center justify-center">
          <Progress value={progress} className="w-1/2" />
        </div> */}
        <div className="mt-4">
          <h2 className="text-xl font-normal">
            1. Who are you sending it to?
          </h2>
        </div>

        {/* Below we'll need an if-else statement to either display employees or display smth to say no employees are added */}
        {premiumUsers.length ? (
          <div>
            <TeamTable />
          </div>
        ) : (
          <div>You currently have no onboarded team members.</div>
        )}
        <Bar
          variant="bottom"
          onReturnPressed={onReturnPressed}
          onContinuePressed={() => onContinuePressed(premiumUsers[Number(Object.keys(rowSelection))])}
          isContinueDisabled={Object.keys(rowSelection).length === 0}
        />
      </div>
    </Layout>
  );
};
