import { ColumnDef } from "@tanstack/react-table";

import { MoreHorizontal, ArrowUpDown } from "lucide-react";

import { Button, Checkbox } from "@shadcn";

type PremiumUser = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  userId: string;
  employmentStartDate: Date;
  birthday: Date;
};

export const airdropColumns: ColumnDef<PremiumUser>[] = [
  {
    id: "select",
    cell: ({ table, row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {table.toggleAllRowsSelected(false); row.toggleSelected(!!value)}}
        aria-label="Select row"
        style={{borderRadius: 100}}
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "firstName",
    header: "First Name",
  },
  {
    accessorKey: "lastName",
    header: "Last Name",
  },
  {
    accessorKey: "emailAddress",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Email
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: "userId",
    header: "Status",
    cell: ({ row }) => {
      const value = row.getValue("userId");
      const status = value === "" ? "• In Progress" : "• Onboarded";
      return (
        <div
          style={{
            color: value === "" ? "orange" : "green",
            fontWeight: "bold",
          }}
        >
          {status}
        </div>
      );
    },
  },
];
