import { useUser } from "@auth";
import { AirdropSelectMembersPageTemplateProps } from "./template";
import { useGetAllGiftCardOffers } from "@queries";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../auth/auth-context";
import { useNavigate } from "react-router-dom";
import { PremiumUser } from "@api";

export const useAirdropSelectMembersPage =
  (): AirdropSelectMembersPageTemplateProps => {
    const partner = useUser();
    const { getAllGiftCardOffers } = useGetAllGiftCardOffers();
    const { giftCardOffers } = useContext(AuthContext);
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
      getAllGiftCardOffers();
    }, []);

    useEffect(() => {
      const timer = setTimeout(() => setProgress(25), 1000);
      return () => clearTimeout(timer);
    }, []);

    const navigateTo = (to: string, data: PremiumUser) => {
        navigate(to, {state: {selectedUser: data}});
    }

    const onContinuePressed = (data: PremiumUser) => {
        const to = "/dashboard/airdrop/select-offers";
        navigateTo(to, data);
    };

    const onReturnPressed = () => {
      const to = "/dashboard/airdrop/main";
      navigate(to)
    }

    return {
      premiumUsers:
        partner?.premiumSettings?.[0]?.users.filter(
          (item) => item.userId !== "",
        ) || [],
      giftCardOffers,
      progress,
      onContinuePressed,
      onReturnPressed,
    };
  };
