import { ImageTemplate } from "../template";
import { ImageProps } from "..";
import Checkmark1x from "./checkmark_green-1x.png";
import Checkmark2x from "./checkmark_green-2x.png";

export const CheckmarkImage = ({
  width,
  height,
  highResolution,
  ...props
}: ImageProps): JSX.Element => {
  return (
    <div style={{ height, width }}>
      <ImageTemplate
        {...props}
        imgSrc={highResolution ? Checkmark2x : Checkmark1x}
      />
    </div>
  );
};
