import { TextChipTemplate, TextChipTemplateProps } from "./template";

export enum ChipTypes {
  activated = "Active",
  comingSoon = "Coming soon",
  planBasic = "Basic",
  planEnterprise = "Enterprise",
  beta = "Beta",
  popular = "Popular"
}

export const TextChip = (props:  TextChipTemplateProps): JSX.Element => {
  return < TextChipTemplate {...props} />;
};
